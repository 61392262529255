import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { OrganisationStep } from './OrganisationStep'
import { BranchStep } from './BranchStep'
import { DataContext } from '../../context/DataContext'
import { DialogSurface } from '../common/modal/Modal'
import { api, skipStartModal } from '../../helpers/apiHelper'
import { InvoicingStep } from './InvoicingStep'
import { UserStep } from './UserStep'
import { useForm } from 'react-hook-form'
import { Organisation, UserAccount } from '../../api/schemas/schema'
import { useMsal } from '@azure/msal-react'
import { t } from 'i18next'
import { handleMessage } from '../../helpers/stateHelper'

const useStyles = makeStyles({
   dialogSurface: {
      backgroundSize: '100% 80px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `linear-gradient(${tokens.colorBrandBackground} 0 0, ${tokens.colorBrandBackground} 10% 100%)`,
      ...shorthands.borderRadius(tokens.borderRadiusLarge),
   },
   dialogTitle: {
      color: '#FFDF9E',
      textAlign: 'center',
   },
   dialogContent: {
      ...shorthands.margin('50px', 0, '20px', 0),
   },
})

type StartUpWizardProps = {
   open: boolean
}

export const StartUpWizard = ({ open }: StartUpWizardProps): JSX.Element => {
   const classes = useStyles()
   const [activeStep, setActiveStep] = useState<number>(1)
   const dataContext = useContext(DataContext)
   const { userAccount, branch, accessToken } = dataContext.state
   const setRootState = dataContext.setRootState
   const organisationForm = useForm<Organisation>()
   const { accounts } = useMsal()

   useEffect(() => {
      if (userAccount && userAccount.organisationId) {
         setActiveStep(3)
         if (branch) {
            setActiveStep(4)
         }
      }
   }, [branch, userAccount])

   const next = () => {
      setActiveStep((prev) => prev + 1)
   }
   const prev = () => {
      setActiveStep((prev) => prev - 1)
   }

   const onOrganisationSubmit = async (data: Organisation) => {
      try {
         data.modifiedBy = accounts[0].name

         let organisation: Organisation
         if (userAccount.organisationId) {
            organisation = (await api(accessToken).updateOrganisation(data)).data
         } else {
            data.country = 'Sverige'
            data.createdBy = accounts[0].name
            organisation = (await api(accessToken).addOrganisation(data)).data
         }

         userAccount.organisationId = organisation.id
         const updatedAccount: UserAccount = (await api(accessToken).updateUserAccount(userAccount)).data
         setRootState((prev) => ({
            ...prev,
            userAccount: updatedAccount,
            organisation,
         }))

         next()
      } catch (error: any) {
         handleMessage(setRootState, 'error', t('SomethingWentWrong'), error.message)
      }
   }

   return (
      <Dialog open={open}>
         <DialogSurface className={classes.dialogSurface}>
            {activeStep === 1 && (
               <OrganisationStep
                  organisationForm={organisationForm}
                  dialogContentClass={classes.dialogContent}
                  dialogTitleClass={classes.dialogTitle}
                  nextStep={next}
               />
            )}
            {activeStep === 2 && (
               <InvoicingStep
                  onOrganisationSubmit={onOrganisationSubmit}
                  organisationForm={organisationForm}
                  dialogContentClass={classes.dialogContent}
                  dialogTitleClass={classes.dialogTitle}
                  prevStep={prev}
               />
            )}
            {activeStep === 3 && (
               <BranchStep dialogContentClass={classes.dialogContent} dialogTitleClass={classes.dialogTitle} nextStep={next} prevStep={prev} />
            )}
            {activeStep === 4 && <UserStep dialogContentClass={classes.dialogContent} dialogTitleClass={classes.dialogTitle} nextStep={next} prevStep={prev} />}
            {process.env.REACT_APP_ENV === 'dev' ? <Button onClick={() => skipStartModal(dataContext)}>Skippa startmodal</Button> : null}
         </DialogSurface>
      </Dialog>
   )
}
