import React from 'react'
import { Divider, makeStyles, tokens } from '@fluentui/react-components'
import { Title } from '../common/text/Title'
const useStyles = makeStyles({
   flex: {
      display: 'flex',
      gap: tokens.spacingHorizontalL,
      alignItems: 'center',
   },
   header: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
         '& section': {
            display: 'flex',
            justifyContent: 'space-between',
            '& h3': {
               margin: 0,
            },
         },
      },
   },
})
type PhoneHeaderProps = {
   title: string
   action?: JSX.Element
}
export const PhoneHeader = ({ title, action }: PhoneHeaderProps) => {
   const classes = useStyles()
   return (
      <header className={classes.header}>
         <section>
            <Title as="h3">{title}</Title>
            <span className={classes.flex}>{action ?? null}</span>
         </section>
         <Divider appearance="brand" />
      </header>
   )
}
