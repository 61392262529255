import React, { useEffect, useState } from 'react'
import { RiskAnalysis, RiskAnalysisStatus, SWOT } from '../../../api/schemas/schema'
import { TypeFilter } from './TypeFilter'
import { Button, makeStyles, Switch, tokens } from '@fluentui/react-components'
import { ArrowCircleDownUp20Regular, LockClosed24Regular } from '@fluentui/react-icons'
import { t } from '../../../i18n/i18n'
import { IRiskAnalysisState } from '../../../interfaces/IRiskAnalysisState'
import { ModalAnalyseEvaluate } from './ModalAnalyseEvaluate'
import { PhoneHeader } from '../PhoneHeader'
import { ModalDetermineRiskAnalysis } from '../ModalDetermineRiskAnalysis'
import { ChangeOrderModal } from './ChangeOrderModal'
import { AnalyseEvaluateList } from './AnalyseEvaluateList'

const useStyles = makeStyles({
   flexRight: {
      justifyContent: 'flex-end',
      '& > *': {
         marginRight: tokens.spacingHorizontalXS,
         marginLeft: tokens.spacingHorizontalXS,
      },
   },
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
})

type AnalyseEvaluateProps = {
   analysis: RiskAnalysis
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>
}

export const AnalyseEvaluate = ({ analysis, setRiskAnalysisState }: AnalyseEvaluateProps) => {
   const [modals, setModals] = useState({
      determineModal: false,
      changeOrderModal: false,
      evaluateModal: false,
   })
   const [graphView, setGraphView] = useState<boolean>(false)
   const [selectedSwot, setSelectedSwot] = useState<SWOT>(null)
   const [disabled, setDisabled] = useState<boolean>(false)
   const classes = useStyles()

   useEffect(() => {
      if (analysis.swoTs.length < 1 || analysis.status === RiskAnalysisStatus.Determined) setDisabled(true)
   }, [analysis])

   const toggleModal = (modalName: string, isOpen: boolean) => {
      setModals({
         determineModal: false,
         changeOrderModal: false,
         evaluateModal: false,
         [modalName]: isOpen,
      })
   }

   const title = t('AnalyseEvaluate')
   return (
      <>
         <PhoneHeader title={title} action={null} />

         <TypeFilter
            title={title}
            disabled={graphView}
            action={
               <div className={classes.flexRight}>
                  <Button disabled={disabled} onClick={() => toggleModal('determineModal', true)} appearance="transparent" icon={<LockClosed24Regular />}>
                     {t('CompleteSWOTAnalysis')}
                  </Button>
                  <Button
                     onClick={() => {
                        toggleModal('changeOrderModal', true)
                     }}
                     appearance="outline"
                     icon={<ArrowCircleDownUp20Regular />}
                     disabled={disabled || graphView}
                  >
                     {t('ChangeOrder')}
                  </Button>
               </div>
            }
            optionalButton={
               <Switch
                  label={t('Graph')}
                  onChange={() => {
                     setGraphView(!graphView)
                  }}
                  className={classes.hideInPhone}
               />
            }
         />

         <AnalyseEvaluateList
            analysis={analysis}
            setRiskAnalysisState={setRiskAnalysisState}
            disabled={disabled}
            graphView={graphView}
            toggleModal={toggleModal}
            setSelectedSwot={setSelectedSwot}
         />

         {selectedSwot && (
            <ModalAnalyseEvaluate
               selectedSwot={selectedSwot}
               analysis={analysis}
               disabled={disabled}
               open={modals.evaluateModal}
               close={() => toggleModal('evaluateModal', false)}
               setRiskAnalysisState={setRiskAnalysisState}
            />
         )}
         <ModalDetermineRiskAnalysis
            analysis={analysis}
            setRiskAnalysisState={setRiskAnalysisState}
            open={modals.determineModal}
            close={() => toggleModal('determineModal', false)}
         />
         <ChangeOrderModal
            open={modals.changeOrderModal}
            close={() => {
               toggleModal('changeOrderModal', false)
            }}
            analysis={analysis}
            setRiskAnalysisState={setRiskAnalysisState}
            items={analysis.swoTs.sort((a, b) => a.type - b.type)}
         />
      </>
   )
}
