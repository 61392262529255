import * as XLSX from 'xlsx'
import { t } from '../i18n/i18n'
import { ActionPlan, DemandAnalysis, RiskAnalysis, SWOTType } from '../api/schemas/schema'
import { findQuestionParentToActionPlan } from './actionPlanHelper'
import { actionPlanStatusString, enumValuesToTranslate } from './enumHelper'
import { formatToSwedishCrowns } from './stringHelper'
import dayjs from 'dayjs'
import { findSWOTParentToActionPlan } from './swotHelper'
import { getFullName } from './accountHelper'

const stripHtmlTags = (input: string) => {
   const div = document.createElement('div')
   div.innerHTML = input
   return div.innerText || div.textContent || ''
}
export const exportDemandActionPlansToExcel = (analysis: DemandAnalysis, filteredActionPlans: ActionPlan[]) => {
   const { questions, name } = analysis
   const rows: any[] = []

   filteredActionPlans.forEach((actionPlan) => {
      const question = findQuestionParentToActionPlan(questions, actionPlan)
      rows.push({
         Nr: actionPlan.index,
         Fråga: stripHtmlTags(question.text),
         'Förslag till åtgärd': question.proposedAction ?? '',
         'Beslut av åtgärd': actionPlan.actionNeeded ? actionPlan.decidedAction : 'Inga åtgärder ska vidtas',
         Beslutskommentar: actionPlan.decidedActionComment ?? '',
         Ansvarig: actionPlan.responsible ? getFullName(actionPlan.responsible) : '',
         Prioritet: actionPlan.priority ?? '',
         Efterlevnadsnivå: question.answer.text,
         'Klart senast': actionPlan.deadline ? dayjs(actionPlan.deadline).format('YYYY-MM-DD') : '',
         'Uppskattad kostnad': question.estimatedCost || question.estimatedCost === 0 ? `${formatToSwedishCrowns(question.estimatedCost)} kr` : '',
         Process: question.processes[0],
         Beslutsstatus: actionPlanStatusString(actionPlan.status),
      })
   })
   exportToExcel(rows, t('ActionPlan'), `${t('ActionPlan')}_${name}`)
}

export const exportSWOTActionPlansToExcel = (analysis: RiskAnalysis, actionPlans: ActionPlan[]) => {
   const { swoTs, name } = analysis
   const rows: any[] = []

   actionPlans.forEach((actionPlan) => {
      const swot = findSWOTParentToActionPlan(swoTs, actionPlan)
      rows.push({
         Nr: actionPlan.index,
         'SWOT Typ': enumValuesToTranslate(SWOTType, swot.type),
         Rubrik: swot.title,
         Beskrivning: swot.description,
         'Förslag till åtgärd': swot.proposedAction ?? '',
         'Beslut av åtgärd': actionPlan.actionNeeded ? actionPlan.decidedAction : 'Inga åtgärder ska vidtas',
         Beslutskommentar: actionPlan.decidedActionComment ?? '',
         Ansvarig: actionPlan.responsible ? getFullName(actionPlan.responsible) : '',
         Prioritet: actionPlan.priority ?? '',
         'Klart senast': actionPlan.deadline ? dayjs(actionPlan.deadline).format('YYYY-MM-DD') : '',
         'Uppskattad kostnad': swot.estimatedCost || swot.estimatedCost === 0 ? `${formatToSwedishCrowns(swot.estimatedCost)} kr ` : '',
         Beslutsstatus: actionPlanStatusString(actionPlan.status),
      })
   })
   exportToExcel(rows, t('RiskManagementPlan'), `${t('RiskManagementPlan')}_${name}`)
}
export const exportToExcel = (rows: any[], sheetName: string, fileName: string) => {
   const workbook = XLSX.utils.book_new()
   const ws1 = XLSX.utils.json_to_sheet(rows)
   XLSX.utils.book_append_sheet(workbook, ws1, sheetName)
   XLSX.writeFile(workbook, `${fileName}_${dayjs().format('YYYYMMDD_HH:mm')}.xlsx`)
}
