import React, { useContext, useEffect, useState } from 'react'
import { Layout } from './Layout'
import { t } from '../i18n/i18n'
import { Overview } from '../components/common/overview/Overview'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RiskManagementTabs, getRiskManagementColumns } from '../helpers/riskManagementHelper'
import { ShieldAdd24Regular } from '@fluentui/react-icons'
import { Button, Tab, makeStyles, tokens } from '@fluentui/react-components'
import { RiskAnalysisStatus } from '../api/schemas/schema'
import { api } from '../helpers/apiHelper'
import { ModalRiskAnalysis } from '../components/riskAnalyses/ModalRiskAnalysis'
import { IRiskManagementState } from '../interfaces/IRiskManagementState'
import { Loader } from '../components/common/spinner/Loader'
import { Navigation } from '../components/common/navigation/Navigation'
import { BaseNavLinks } from '../components/common/navigation/BaseNavLinks'
import { DataContext } from '../context/DataContext'
const useStyles = makeStyles({
   largeBtn: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   phoneBtn: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         width: `calc(100% - (${tokens.spacingHorizontalL} * 2))`,
         display: 'inline-flex',
         position: 'fixed',
         bottom: '60px',
      },
   },
   row: {
      '& >': {
         ':nth-child(1)': {
            width: '50%',
         },
         ':nth-child(2)': {
            width: '20%',
            minWidth: '150px',
         },
         ':nth-child(3)': {
            width: '20%',
            minWidth: '200px',
         },
         ':nth-child(4)': {
            width: '10%',
            minWidth: '150px',
         },
      },
   },
})
export const RiskManagement = () => {
   const dataContext = useContext(DataContext)
   const { accessToken, organisation } = dataContext.state
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')
   const classes = useStyles()
   const navigate = useNavigate()
   const [riskManagementState, setRiskManagementState] = useState<IRiskManagementState>({
      loading: true,
      filteredItems: [],
      allItems: [],
      open: false,
   })
   const { loading, filteredItems, allItems } = riskManagementState
   useEffect(() => {
      if (!selectedTab) {
         setSearchParams({ tab: RiskManagementTabs.Ongoing })
      }
      ;(async () => {
         const allRiskAnalyses = (await api(accessToken).getRiskAnalysesByOrganisationId(organisation.id)).data
         setRiskManagementState((prev) => ({ ...prev, loading: false, allItems: allRiskAnalyses }))
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchParams, dataContext.state.userAccount])
   useEffect(() => {
      if (selectedTab === RiskManagementTabs.Ongoing)
         setRiskManagementState((prev) => ({ ...prev, filteredItems: prev.allItems.filter((x) => x.status !== RiskAnalysisStatus.Completed) }))
      if (selectedTab === RiskManagementTabs.Concluded)
         setRiskManagementState((prev) => ({ ...prev, filteredItems: prev.allItems.filter((x) => x.status === RiskAnalysisStatus.Completed) }))
   }, [selectedTab, allItems.length])
   const getNewRiskAnalysisBtn = (showOnPhone: boolean) => {
      const onClick = () => setRiskManagementState((prev) => ({ ...prev, open: true }))
      if (showOnPhone)
         return (
            <Button className={classes.phoneBtn} icon={<ShieldAdd24Regular />} appearance="primary" onClick={onClick}>
               {t('NewRiskAnalysis')}
            </Button>
         )
      else
         return (
            <Button className={classes.largeBtn} appearance="primary" onClick={onClick}>
               {t('NewRiskAnalysis')}
            </Button>
         )
   }
   return (
      <Layout breadCrumbItems={[{ title: t('RiskManagement'), active: true, path: '/risk-management' }]}>
         <Navigation desktopNavLinks={<BaseNavLinks active="/risk-management" />} activeDesktopTab="/risk-management" />
         {loading ? (
            <Loader />
         ) : (
            <Overview
               tabs={
                  <>
                     <Tab value={RiskManagementTabs.Ongoing}>{t('Ongoing')}</Tab>
                     <Tab value={RiskManagementTabs.Concluded}>{t('Concluded')}</Tab>
                  </>
               }
               button={getNewRiskAnalysisBtn}
               loading={loading}
               columns={getRiskManagementColumns()}
               items={filteredItems}
               onRowClick={(item) => navigate(`/risk-management/${item.id}`)}
               keyValue="name"
               noItemsText={selectedTab === RiskManagementTabs.Ongoing ? t('TimeToCreateYourFirstRiskAnalysis') : t('NoConcludedRiskAnalyses')}
               title={selectedTab === RiskManagementTabs.Ongoing ? t('OngoingRiskAnalyses') : t('ConcludedRiskAnalyses')}
               rowClassName={classes.row}
            />
         )}
         <ModalRiskAnalysis setRiskManagementState={setRiskManagementState} riskManagementState={riskManagementState} />
      </Layout>
   )
}
