import React from 'react'
import { Input, InputOnChangeData, Textarea, TextareaOnChangeData } from '@fluentui/react-components'
import { Control, Controller } from 'react-hook-form'
import { t } from '../../../i18n/i18n'
import { Field } from './Field'

type TextInputProps = {
   label: string
   value: string
   onChange?: (ev: any, data: TextareaOnChangeData | InputOnChangeData) => void
   disabled?: boolean
   multiline?: boolean
   className?: string
   rows?: number
   required?: boolean
}

export const TextInput = ({ label, value, onChange, disabled, multiline, className, rows, required }: TextInputProps) => {
   return (
      <Field required={required ?? false} className={className ?? ''} label={label}>
         {multiline ? (
            <Textarea rows={rows} value={value ?? ''} onChange={(e, d) => onChange && onChange(e, d)} resize={'vertical'} disabled={disabled ?? false} />
         ) : (
            <Input value={value ?? ''} onChange={(e, d) => onChange && onChange(e, d)} disabled={disabled ?? false} />
         )}
      </Field>
   )
}

type ControlledTextInputProps = {
   name: any
   control: Control<any>
   label?: string
   disabled?: boolean
   multiline?: boolean
   required?: boolean
   rows?: number
   type?: 'number' | 'search' | 'time' | 'text' | 'email' | 'password' | 'tel' | 'url' | 'date' | 'datetime-local' | 'month' | 'week'
}
export const ControlledTextInput = ({ name, control, disabled, label, multiline, required, rows, type }: ControlledTextInputProps) => {
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false, pattern: type === 'number' ? /^[0-9]+$/ : undefined }}
         render={({ field: { value, onChange }, fieldState: { error, invalid }, formState: { disabled: formDisabled } }) => {
            return (
               <Field label={label} required={required ?? false} validationMessage={error ? error.message : null} validationState={error ? 'error' : null}>
                  {multiline ? (
                     <Textarea rows={rows} value={value ?? ''} onChange={onChange} resize={'vertical'} disabled={(disabled || formDisabled) ?? false} />
                  ) : (
                     <Input
                        type={type}
                        value={value && type === 'number' ? value : value ? value : ''}
                        onChange={onChange}
                        disabled={(disabled || formDisabled) ?? false}
                     />
                  )}
               </Field>
            )
         }}
      />
   )
}
