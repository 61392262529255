import React from 'react'
import { Card } from '../common/card/Card'
import { DataClassification, RiskAnalysis } from '../../api/schemas/schema'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { Text } from '../common/text/Text'
import dayjs from 'dayjs'
import { Persona, makeStyles, tokens } from '@fluentui/react-components'
import { enumValuesToTranslate, getRiskAnalysisTypeString } from '../../helpers/enumHelper'
import { t } from '../../i18n/i18n'
import { PhoneHeader } from './PhoneHeader'
import { getFullName } from '../../helpers/accountHelper'
type InformationProps = {
   analysis: RiskAnalysis
}
const useStyles = makeStyles({
   infoWrapper: {
      '& > p': {
         marginTop: '0.5em',
         marginBottom: '0.5em',
      },
      '& > :last-child': {
         marginBottom: '1.5em',
         '& > span:last-child': {
            fontSize: tokens.fontSizeBase400,
         },
      },
   },
})
export const Information = ({ analysis }: InformationProps) => {
   const classes = useStyles()

   const title = t('Information')
   return (
      <Card title={title} transparentInPhone>
         <PhoneHeader title={title} />
         <Grid>
            <GridItem size="4/12">
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('TypeOfRiskAnalysis')}</Text>
                  <Text>{getRiskAnalysisTypeString(analysis.type)}</Text>
               </section>
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('StartOfAnalysis')}</Text>
                  {analysis.startDate ? <Text>{dayjs(analysis.startDate).format('YYYY-MM-DD')}</Text> : <Text italic>{t('NoDateSet')}</Text>}
               </section>
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('EndOfAnalysis')}</Text>
                  {analysis.endDate ? <Text>{dayjs(analysis.endDate).format('YYYY-MM-DD')}</Text> : <Text italic>{t('NoDateSet')}</Text>}
               </section>
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('RiskOwner')}</Text>
                  <Persona textAlignment="center" name={getFullName(analysis.analysisOwner)} primaryText={getFullName(analysis.analysisOwner)} />
               </section>
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('AnalysisLeader')}</Text>
                  {analysis.analysisLeaderId ? (
                     <Persona textAlignment="center" name={getFullName(analysis.analysisLeader)} primaryText={getFullName(analysis.analysisLeader)} />
                  ) : (
                     <Text italic>{t('NoValueSet')}</Text>
                  )}
               </section>

               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('DataClassification')}</Text>
                  {analysis.dataClassification !== undefined ? (
                     <Text>{enumValuesToTranslate(DataClassification, analysis.dataClassification)}</Text>
                  ) : (
                     <Text italic>{t('NoValueSet')}</Text>
                  )}
               </section>
            </GridItem>

            <GridItem size="8/12">
               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('Purpose/Scope')}</Text>
                  <Text>{analysis.purposeScope}</Text>
               </section>

               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('Limitations')}</Text>
                  <Text>{analysis.limitation}</Text>
               </section>

               <section className={classes.infoWrapper}>
                  <Text weight="bold">{t('Comment')}</Text>
                  <Text>{analysis.comment}</Text>
               </section>
            </GridItem>
         </Grid>
      </Card>
   )
}
