import React from 'react'
import {
   BookExclamationMark24Filled,
   BookExclamationMark24Regular,
   ClipboardSearch24Regular,
   ClipboardSearch24Filled,
   TaskListSquareLtr24Regular,
   TaskListSquareLtr24Filled,
} from '@fluentui/react-icons'
import { Tab } from '@fluentui/react-components'
import { DemandAssessmentTabs } from '../../../helpers/demandAssessmentHelper'

type DemandAssessmentNavLinksProps = {
   id: string
   active: string
   completeDemandAnalysisTab: JSX.Element
}
export const DemandAssessmentNavLinks = ({ active, id, completeDemandAnalysisTab }: DemandAssessmentNavLinksProps) => {
   const baseLink = `/compliance/${id}`
   return (
      <>
         <Tab
            value={`${baseLink}?tab=${DemandAssessmentTabs.Info}`}
            icon={active === DemandAssessmentTabs.Info ? <BookExclamationMark24Filled /> : <BookExclamationMark24Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${DemandAssessmentTabs.DemandAnalysis}`}
            icon={active === DemandAssessmentTabs.DemandAnalysis ? <ClipboardSearch24Filled /> : <ClipboardSearch24Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${DemandAssessmentTabs.ActionPlan}`}
            icon={active === DemandAssessmentTabs.ActionPlan ? <TaskListSquareLtr24Filled /> : <TaskListSquareLtr24Regular />}
         />
         {completeDemandAnalysisTab}
      </>
   )
}
