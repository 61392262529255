/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { DemandAnalysis, DemandAnalysisStatus } from '../../api/schemas/schema'
import { useForm } from 'react-hook-form'
import { Modal } from '../common/modal/Modal'
import { getDefaultValues } from '../../helpers/demandAssessmentHelper'
import { IComplianceState } from '../../interfaces/IComplianceState'
import { DataContext } from '../../context/DataContext'
import { t } from '../../i18n/i18n'
import { DemandAssessmentForm } from './DemandAssessmentForm'
import { ModalLoadingDemandAssessment } from './ModalLoadingDemandAssessment'
import { handleMessage } from '../../helpers/stateHelper'
import { api } from '../../helpers/apiHelper'
import { ProcessOwnerForm } from './ProcessOwnerForm'

type DemandAssessmentFormProps = {
   setComplianceState: React.Dispatch<React.SetStateAction<IComplianceState>>
   complianceState: IComplianceState
}

export const ModalDemandAssessment = ({ setComplianceState, complianceState }: DemandAssessmentFormProps) => {
   const dataContext = useContext(DataContext)
   const { accessToken, userAccount } = dataContext.state
   const { setRootState } = dataContext
   const demandAssessmentForm = useForm<DemandAnalysis>({
      defaultValues: getDefaultValues(dataContext, complianceState),
   })
   const {
      reset,
      trigger,
      handleSubmit,
      formState: { isValid, isSubmitSuccessful, isSubmitted },
   } = demandAssessmentForm

   const { leadershipPerspectives, open, subjects } = complianceState
   const [error, setError] = useState<string>(null)
   const [includeExtendedLevel, setIncludeExtendedLevel] = useState<boolean>(false)
   const [activeStep, setActiveStep] = useState<number>(1)
   const nextStep = async () => {
      await trigger()
      if (isValid) setActiveStep(activeStep + 1)
   }
   const prevStep = () => setActiveStep(activeStep - 1)
   const onSubmit = async (data: DemandAnalysis) => {
      await trigger()
      if (isValid) {
         try {
            data.createdBy = userAccount
            data.modifiedBy = userAccount
            data.createdById = userAccount.id
            data.modifiedById = userAccount.id
            data.subjects = subjects.map((s) => s.text)
            data.leadershipPerspectives = leadershipPerspectives.map((lp) => lp.text)
            data.processOwners = data.processOwners.map((po) => ({ ...po, process: null }))
            if (includeExtendedLevel) {
               data.additionalModules = ['Utökad nivå']
            }

            setComplianceState((prev) => ({ ...prev, open: false }))
            const demandAnalysis: DemandAnalysis = (await api(accessToken).addDemandAnalysis(data)).data
            reset(demandAnalysis, { keepIsSubmitSuccessful: true, keepIsSubmitted: true })
            setIncludeExtendedLevel(false)
         } catch (error: any) {
            let errorMessage = t('SomethingWentWrong')
            if (error?.error?.message === 'No matching questions found.') {
               errorMessage = t('NoMatchingQuestonsFound')
            }

            setError(errorMessage ?? error.error.message)
            setActiveStep(1)
            setIncludeExtendedLevel(false)
            handleMessage(setRootState, 'error', errorMessage, error.error.message)
         }
      }
   }
   useEffect(() => {
      if (complianceState.demandAnalysisStatuses.length > 0) {
         reset(
            {
               status: DemandAnalysisStatus.Ongoing,
            },
            { keepDefaultValues: true, keepValues: true }
         )
      }
   }, [complianceState.demandAnalysisStatuses.length])

   useEffect(() => {
      if (isSubmitted) {
         if (!isSubmitSuccessful) {
            setComplianceState((prev) => ({ ...prev, open: true }))
         }
      }
   }, [isSubmitted])

   return (
      <>
         <ModalLoadingDemandAssessment
            demandAssessmentForm={demandAssessmentForm}
            setComplianceState={setComplianceState}
            complianceState={complianceState}
            error={error}
         />
         <Modal title={activeStep === 1 ? t('NewDemandAssessment') : t('ProcessOwnersForDemandAssessment')} modalType="modal" open={open}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
               {activeStep === 1 && (
                  <DemandAssessmentForm
                     demandAssessmentForm={demandAssessmentForm}
                     setComplianceState={setComplianceState}
                     complianceState={complianceState}
                     setIncludeExtendedLevel={setIncludeExtendedLevel}
                     includeExtendedLevel={includeExtendedLevel}
                     nextStep={nextStep}
                  />
               )}
               {activeStep === 2 && <ProcessOwnerForm demandAssessmentForm={demandAssessmentForm} prevStep={prevStep} processes={complianceState.processes} />}
            </form>
         </Modal>
      </>
   )
}
