import React, { useEffect, useState } from 'react'
import { mergeClasses, TabList, Tab, makeStyles, Button, SelectTabData } from '@fluentui/react-components'
import {
   InfoShield20Regular,
   SearchShield20Regular,
   Scales20Regular,
   TaskListSquareLtr20Regular,
   LockClosed24Regular,
   InfoShield20Filled,
   Scales20Filled,
   SearchShield20Filled,
   TaskListSquareLtr20Filled,
   LockClosed20Regular,
} from '@fluentui/react-icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { t } from '../../../i18n/i18n'
import { IRiskAnalysisState } from '../../../interfaces/IRiskAnalysisState'
import { Layout } from '../../../pages/Layout'
import { BaseNavLinks } from '../../common/navigation/BaseNavLinks'
import { Navigation } from '../../common/navigation/Navigation'
import { Identifying } from './Identifying'
import { Information } from '../Information'
import { SWOTNavLinks } from '../../common/navigation/SWOTNavLinks'
import { SWOTTabs } from '../../../helpers/swotHelper'
import { AnalyseEvaluate } from './AnalyseEvaluate'
import { ActionPlans } from './ActionPlans'
import { ActionPlanStatus } from '../../../api/schemas/schema'
import { ModalCompleteRiskAssessment } from '../ModalCompleteRiskAssessment'

const useStyles = makeStyles({
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
   },
   flexOnlyLarge: {
      '@media screen and (max-width: 1007px)': { display: 'none' },
   },
})

type SWOTProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>
   analysisState: IRiskAnalysisState
}

export const Swot = ({ setRiskAnalysisState, analysisState }: SWOTProps) => {
   const classes = useStyles()
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')
   const navigate = useNavigate()
   const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false)
   const setTab = (tab: string) => {
      setSearchParams((prev) => ({ ...prev, tab }))
   }
   const { item: analysis } = analysisState

   useEffect(() => {
      if (!selectedTab) {
         setTab('info')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const getSWOTTitle = (tab: string): string => {
      switch (tab) {
         case SWOTTabs.Info:
            return t('Information')
         case SWOTTabs.Identifying:
            return t('Identify')
         case SWOTTabs.AnalyseEvaluate:
            return t('AnalyseEvaluate')
         case SWOTTabs.ActionPlan:
            return t('RiskManagementPlan')
      }
   }
   const completeBtnDisabled = analysis.swoTs.some((s) => !s.determined) || analysis.actionPlans.some((ap) => ap.status === ActionPlanStatus.NotDecided)
   return (
      <Layout
         breadCrumbItems={[
            { path: '/risk-management', active: false, title: t('RiskManagement') },
            { path: `/risk-management/${analysis.id}`, active: true, title: analysis.name },
         ]}
      >
         <Navigation
            desktopNavLinks={<BaseNavLinks active="/risk-management" />}
            activeDesktopTab="/risk-management"
            activePhoneTab={`/risk-management/${analysis.id}?tab=${selectedTab}`}
            phoneNavLinks={
               <SWOTNavLinks
                  id={analysisState?.item?.id ?? ''}
                  active={selectedTab}
                  actionTab={<Tab value={'complete'} icon={<LockClosed24Regular />} disabled={completeBtnDisabled} />} // Skriv logik sen för att denna ska vara av/på enligt ticket
               />
            }
            customOnTabSelect={(e, data: SelectTabData) => {
               if (data.value === 'complete') setCompleteModalOpen(true)
               else navigate(data.value)
            }}
         />

         <header className={mergeClasses(classes.header, classes.flexOnlyLarge)}>
            <TabList selectedValue={selectedTab} onTabSelect={(e, data) => setTab(data.value as string)}>
               <Tab icon={selectedTab === SWOTTabs.Info ? <InfoShield20Filled /> : <InfoShield20Regular />} value={SWOTTabs.Info}>
                  {getSWOTTitle(SWOTTabs.Info)}
               </Tab>
               <Tab icon={selectedTab === SWOTTabs.Identifying ? <SearchShield20Filled /> : <SearchShield20Regular />} value={SWOTTabs.Identifying}>
                  {getSWOTTitle(SWOTTabs.Identifying)}
               </Tab>
               <Tab icon={selectedTab === SWOTTabs.AnalyseEvaluate ? <Scales20Filled /> : <Scales20Regular />} value={SWOTTabs.AnalyseEvaluate}>
                  {getSWOTTitle(SWOTTabs.AnalyseEvaluate)}
               </Tab>
               <Tab icon={selectedTab === SWOTTabs.ActionPlan ? <TaskListSquareLtr20Filled /> : <TaskListSquareLtr20Regular />} value={SWOTTabs.ActionPlan}>
                  {getSWOTTitle(SWOTTabs.ActionPlan)}
               </Tab>
            </TabList>
            <Button disabled={completeBtnDisabled} icon={<LockClosed20Regular />} onClick={() => setCompleteModalOpen(true)} appearance="transparent">
               {t('CompleteRiskAssessment')}
            </Button>
         </header>
         {selectedTab === SWOTTabs.Info && <Information analysis={analysis} />}
         {selectedTab === SWOTTabs.Identifying && <Identifying analysis={analysis} setRiskAnalysisState={setRiskAnalysisState} />}
         {selectedTab === SWOTTabs.AnalyseEvaluate && <AnalyseEvaluate analysis={analysis} setRiskAnalysisState={setRiskAnalysisState} />}
         {selectedTab === SWOTTabs.ActionPlan && <ActionPlans analysis={analysis} setRiskAnalysisState={setRiskAnalysisState} />}
         <ModalCompleteRiskAssessment
            open={completeModalOpen}
            close={() => setCompleteModalOpen(false)}
            riskAnalysisState={analysisState}
            setRiskAnalysisState={setRiskAnalysisState}
         />
      </Layout>
   )
}
