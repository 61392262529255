import { Button, Dropdown, makeStyles, tokens, Option } from '@fluentui/react-components'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from '../../../i18n/i18n'
import { enumValuesToOptions } from '../../../helpers/enumHelper'
import { SWOTType } from '../../../api/schemas/schema'
import { Card } from '../../common/card/Card'
import { IDropdownOption } from '../../../interfaces/IDropdownOptions'
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   showOnlyPhone: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'block',
      },
   },
   spacer: {
      display: 'flex',
      justifyContent: 'space-between'
   },

   btnFlex: {
      display: 'flex',
      gap: tokens.spacingHorizontalS,
   },
   active: {
      backgroundColor: tokens.colorNeutralBackground1Selected,
   },
})
type TypeFilterProps = {
   action: JSX.Element
   title: string
   optionalButton?: JSX.Element
   disabled?: boolean
}
export const TypeFilter = ({ action, title, optionalButton, disabled}: TypeFilterProps) => {
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedType = searchParams.get('type')
   const setType = (type: string) => {
      searchParams.set('type', type)
      setSearchParams(searchParams)
   }
   const options: IDropdownOption[] = [{ key: 'all', text: t('All') }, ...enumValuesToOptions(SWOTType)]
   const findSelectedOption = () => {
      return options.find((x) => x.key.toString() === selectedType)
   }
   useEffect(() => {
      if (searchParams.get('type') === null || !findSelectedOption()) {
         setType('all')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [window.location.search])
   const classes = useStyles()
   return (
      <>
         <Card className={classes.hideInPhone} title={title} action={action}>
            <div className={classes.spacer}>
               <span className={classes.btnFlex}>
                  {options.map((x) => {
                     return (
                        <Button className={selectedType === x.key.toString() ? classes.active : ''} onClick={() => setType(x.key.toString())} disabled={disabled}>
                           {x.text}
                        </Button>
                     )
                  })}
               </span>
               {optionalButton}
            </div>
         </Card>
         <Dropdown
            size="large"
            className={classes.showOnlyPhone}
            style={{ width: '100%' }}
            value={findSelectedOption() ? findSelectedOption().text : 'Alla'}
            selectedOptions={[selectedType]}
            onOptionSelect={(e, data) => {
               setType(data.optionValue)
            }}
         >
            {options.map((x) => (
               <Option key={x.key} value={x.key.toString()}>
                  {x.text}
               </Option>
            ))}
         </Dropdown>
      </>
   )
}
