import React, { useState } from 'react'
import { Button, makeStyles, Table, TableBody, TableHeader, TableHeaderCell, TableRow, tokens } from '@fluentui/react-components'
import { IGroup } from '../../../interfaces/IGroup'
import { IGroupedTableColumnDefintion } from '../../../interfaces/IGroupedTableColumnDefintion'
import { ChevronDown24Regular, ChevronRight24Regular } from '@fluentui/react-icons'
import { GroupedListRow } from './GroupedListRow'
import { SummaryRow } from './SummaryRow'
import { ISummaryField } from '../../../interfaces/ISummaryField'

type GroupedListProps<T extends {}> = {
   columns: IGroupedTableColumnDefintion<T>[]
   groups: IGroup<T>[]
   onRowClick: (item: T) => void
   summaryFields: ISummaryField[]
   renderPhoneRow: (item: T) => JSX.Element
   className?: string
}
const useStyles = makeStyles({
   chevronColWidth: {
      width: '70px',
      cursor: 'pointer',
      '@media screen and (max-width: 640px)': {
         width: '30px',
      },
   },
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   autoWidth: {
      '@media screen and (max-width: 640px)': {
         width: 'auto !important',
      },
   },
})
export const GroupedList = <T extends {}>({ columns, groups, onRowClick, summaryFields, className, renderPhoneRow }: GroupedListProps<T>) => {
   const [allExpanded, setAllExpanded] = useState<boolean>(false)
   const classes = useStyles()
   return (
      <div className={className ?? ''}>
         <SummaryRow summaryFields={summaryFields} />
         <Table>
            <TableHeader className={classes.hideInPhone}>
               <TableRow>
                  <TableHeaderCell className={classes.chevronColWidth}>
                     <Button
                        size="large"
                        aria-label="expand-all"
                        appearance="transparent"
                        icon={allExpanded ? <ChevronDown24Regular /> : <ChevronRight24Regular />}
                        onClick={() => setAllExpanded((prev) => !prev)}
                     />
                  </TableHeaderCell>
                  {columns.map((column) => (
                     <TableHeaderCell style={{ fontWeight: tokens.fontWeightBold, width: column.colWidth }} key={column.columnId}>
                        {column.renderHeaderCell()}
                     </TableHeaderCell>
                  ))}
               </TableRow>
            </TableHeader>
            <TableBody>
               {groups.map((group) => (
                  <GroupedListRow
                     key={group.label}
                     onRowClick={onRowClick}
                     group={group}
                     columns={columns}
                     allExpanded={allExpanded}
                     chevronCellClass={classes.chevronColWidth}
                     cellClassname={classes.autoWidth}
                     renderPhoneRow={renderPhoneRow}
                  />
               ))}
            </TableBody>
         </Table>
      </div>
   )
}
