import React, { useContext, useEffect, useRef, useState } from 'react'
import { t } from '../i18n/i18n'
import { RiskAnalysisType } from '../api/schemas/schema'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../helpers/apiHelper'
import { handleMessage } from '../helpers/stateHelper'
import { Loader } from '../components/common/spinner/Loader'
import { IRiskAnalysisState } from '../interfaces/IRiskAnalysisState'
import { DataContext } from '../context/DataContext'
import { Swot } from '../components/riskAnalyses/SWOT/SWOT'

export const SelectedRiskAnalysis = () => {
   const dataContext = useContext(DataContext)
   const { accessToken } = dataContext.state
   const navigate = useNavigate()
   const [riskAnalysisState, setRiskAnalysisState] = useState<IRiskAnalysisState>({
      loading: true,
      error: null,
      item: null,
   })
   const { item, loading } = riskAnalysisState
   const { id } = useParams()
   const prevId = useRef({})

   useEffect(() => {
      const fetchData = async () => {
         try {
            const riskAnalysis = (await api(accessToken).getRiskAnalysisById(id, dataContext.state.organisation.id)).data
            setRiskAnalysisState((prev) => ({ ...prev, loading: false, item: riskAnalysis }))
         } catch (error: any) {
            //   setAnalysisState((prev) => ({ ...prev, loading: false, error: { message: error.error.message, name: error.status } }))
            navigate('/risk-management')
            handleMessage(dataContext.setRootState, 'error', t('FetchFailed'), t('CouldNotFetchItem', { Item: t('RiskAnalysis').toLowerCase() }))
         }
      }
      if (prevId.current !== id) {
         prevId.current = id
         fetchData()
      }

      if (riskAnalysisState.item && riskAnalysisState.item.organisationId !== dataContext.state.organisation.id) {
         navigate('/risk-management')
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id, dataContext.state.userAccount])

   const getContent = (type: RiskAnalysisType): JSX.Element => {
      switch (type) {
         // case RiskAnalysisType.FRA:
         //    return <FRA analysis={item} setRiskAnalysisState={setRiskAnalysisState} />
         case RiskAnalysisType.SWOT:
            return <Swot analysisState={riskAnalysisState} setRiskAnalysisState={setRiskAnalysisState} />

         default:
            return <></>
      }
   }
   return <>{loading ? <Loader /> : getContent(item.type)}</>
}
