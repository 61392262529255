import React, { useContext, useEffect } from 'react'
import { DialogContent, DialogTitle } from '@fluentui/react-components'
import { UserAccount } from '../../api/schemas/schema'
import { useForm } from 'react-hook-form'
import { DataContext } from '../../context/DataContext'
import { handleMessage } from '../../helpers/stateHelper'
import { useMsal } from '@azure/msal-react'
import { GridItem } from '../common/grid/GridItem'
import { Grid } from '../common/grid/Grid'
import { api } from '../../helpers/apiHelper'
import { DialogActionButtons, DialogBody } from '../common/modal/Modal'
import { t } from '../../i18n/i18n'
import { Title } from '../common/text/Title'
import { ModalAccountInformationForm } from '../userAccount/ModalAccountInformationForm'

type BranchStepProps = {
   nextStep: () => void
   prevStep?: () => void
   dialogTitleClass: string
   dialogContentClass: string
}
export const UserStep = ({ nextStep, prevStep, dialogTitleClass, dialogContentClass }: BranchStepProps) => {
   const dataContext = useContext(DataContext)
   const { setRootState } = dataContext
   const { userAccount, accessToken } = dataContext.state
   const userAccountForm = useForm<UserAccount>()
   const { handleSubmit, reset } = userAccountForm
   const { accounts } = useMsal()

   useEffect(() => {
      if (userAccount) reset(userAccount)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userAccount])

   const onSubmit = async (data: UserAccount) => {
      try {
         data.modifiedBy = accounts[0].name

         let createdUserAccount: UserAccount
         if (data.id){
            createdUserAccount = (await api(accessToken).updateUserAccount(data)).data
         }
         else {
            data.createdBy = accounts[0].name
            createdUserAccount = (await api(accessToken).addUserAccount(data)).data
         }

         dataContext.setRootState((prev) => ({
            ...prev,
            userAccount: createdUserAccount,
         }))
      } catch (error: any) {
         handleMessage(setRootState, 'error', t('SomethingWentWrong'), error.message)
      }
   }

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <DialogBody>
            <DialogTitle action="" as="h3" className={dialogTitleClass}>
               {t('User')}
            </DialogTitle>
            <DialogContent className={dialogContentClass}>
               <Grid gap="L">
                  <GridItem size="12/12">
                     <Title as="h4">{t('CheckAndVerifyUserData')}</Title>
                  </GridItem>
                  <ModalAccountInformationForm userAccountForm={userAccountForm} />
               </Grid>
            </DialogContent>
            <DialogActionButtons primaryButtonText={t('Next')} secondaryButtonText={t('Back')} secondaryClick={prevStep} />
         </DialogBody>
      </form>
   )
}
