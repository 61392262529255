import React, { useEffect, useContext } from 'react'
import { ModalActions } from '../common/modal/Modal'
import { Button, Divider } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { UseFormReturn } from 'react-hook-form'
import { DemandAnalysis, Process } from '../../api/schemas/schema'
import { Text } from '../common/text/Text'
import { ProcessOwnerPicker } from './ProcessOwnerPicker'
import { DataContext } from '../../context/DataContext'

type ProcessOwnerFormProps = {
   demandAssessmentForm: UseFormReturn<DemandAnalysis, any, undefined>
   prevStep: () => void
   processes: Process[]
}
export const ProcessOwnerForm = ({ demandAssessmentForm, prevStep, processes }: ProcessOwnerFormProps) => {
   const {
      watch,
      setValue,
      formState: { isSubmitting },
   } = demandAssessmentForm
   const dataContext = useContext(DataContext)
   const { userAccount } = dataContext.state
   useEffect(() => {
      setValue(
         'processOwners',
         watch('processes').map((x) => {
            const processObj = processes.find((p) => p.text === x)
            return { process: processObj, processId: processObj.id, userAccount: userAccount, userAccountId: userAccount.id }
         })
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <Grid gap="L">
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="12/12">
            <Text>{t('ProcessOwnerForDemandAssessmentText')}</Text>
         </GridItem>
         <>
            {watch('processes')?.map((process) => (
               <GridItem key={process} size="12/12">
                  <ProcessOwnerPicker demandAssessmentForm={demandAssessmentForm} processes={processes} process={process} />
               </GridItem>
            ))}
         </>
         <GridItem size="12/12">
            <ModalActions>
               <Button onClick={prevStep} appearance="secondary">
                  {t('Back')}
               </Button>

               <Button type="submit" appearance="primary" disabled={isSubmitting}>
                  {t('CreateAssessment')}
               </Button>
            </ModalActions>
         </GridItem>
      </Grid>
   )
}
