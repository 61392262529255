import React from 'react'
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Divider, makeStyles, tokens, mergeClasses } from '@fluentui/react-components'
import { ModalActions } from '../../common/modal/Modal'
import { t } from '../../../i18n/i18n'
import { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../../context/DataContext'
import { DataClassification, DemandAnalysis, ProcessOwner } from '../../../api/schemas/schema'
import { Text } from '../../common/text/Text'
import { ControlledDatePicker } from '../../common/inputs/DatePicker'
import { useForm } from 'react-hook-form'
import { api } from '../../../helpers/apiHelper'
import { ProcessOwnerPicker } from '../../compliance/ProcessOwnerPicker'
import { ControlledAccountPicker } from '../../common/inputs/ControlledAccountPicker'
import { handleMessage } from '../../../helpers/stateHelper'
import { IAnalysisState } from '../../../interfaces/IAnalysisState'
import { enumValuesToTranslate } from '../../../helpers/enumHelper'

interface ModalEditInformationProps {
   analysis: DemandAnalysis
   isOpen: boolean
   closeModal: () => void
   setAnalysisState: React.Dispatch<React.SetStateAction<IAnalysisState>>
}

const useStyles = makeStyles({
   formWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalS,
   },
   textWrapper: {
      '> p:last-child': {
         marginTop: tokens.spacingVerticalXS,
      },
      '> p:first-child': {
         marginBottom: 0,
      },
   },
   radioToolTip: {
      top: '5px !important',
   },
   marginTop: {
      marginTop: tokens.spacingVerticalM,
   },
})

export const ModalEditInformation = ({ analysis, isOpen, closeModal, setAnalysisState }: ModalEditInformationProps) => {
   const dataContext = useContext(DataContext)
   const { accessToken, userAccount } = dataContext.state
   const { setRootState } = dataContext
   const classes = useStyles()
   const [processes, setProcesses] = useState([])
   const demandAssessmentForm = useForm<DemandAnalysis>({})
   const {
      control,
      reset,
      getValues,
      setValue,
      trigger,
      handleSubmit,
      watch,
      formState: { isValid },
   } = demandAssessmentForm

   useEffect(() => {
      try {
         const fetchProcesses = async () => {
            const response = (await api(accessToken).getAllProcesss()).data
            setProcesses(response)
         }
         fetchProcesses()
      } catch (error) {
         handleMessage(setRootState, 'error', t('SomethingWentWrong'), t('CouldNotFetchItem'))
      }
   }, [accessToken, setRootState])

   useEffect(() => {
      if (isOpen) {
         reset(analysis)
      }
   }, [isOpen, analysis, reset])

   const onSubmit = async (data: DemandAnalysis) => {
      trigger()
      if (isValid) {
         try {
            const updatedData = {
               ...data,
               modifiedById: userAccount.id,
            }

            const updatedDemandAnalysis: DemandAnalysis = (await api(accessToken).updateDemandAnalysis(updatedData)).data

            setAnalysisState((prevState) => ({
               ...prevState,
               item: updatedDemandAnalysis,
            }))

            reset(updatedDemandAnalysis, { keepIsSubmitSuccessful: true, keepIsSubmitted: true })
            closeModal()
         } catch (error: any) {
            let errorMessage = t('SomethingWentWrong')
            handleMessage(setRootState, 'error', errorMessage, t('CouldNotUpdateDemandAssessment'))
         }
      }
   }

   return (
      <>
         <Dialog open={analysis && isOpen} modalType={'modal'}>
            <DialogSurface>
               <DialogBody>
                  <DialogTitle>{t('EditDemandAssessment')}</DialogTitle>
                  <DialogContent>
                     <form onSubmit={handleSubmit(onSubmit)} className={classes.formWrapper}>
                        <Divider appearance="brand"></Divider>
                        <section>
                           <Text>{t('AssessmentName')}</Text>
                           <Text>{analysis.name}</Text>
                        </section>
                        <section className={classes.textWrapper}>
                           <ControlledDatePicker required control={control} name={'analysisStart'} label={t('StartOfAssessment')} />
                        </section>
                        <section className={classes.textWrapper}>
                           <ControlledDatePicker
                              required
                              control={control}
                              name={'analysisEnd'}
                              label={t('EndOfAssessment')}
                              getValues={getValues}
                              compareWith={'analysisStart'}
                           />
                        </section>
                        <section className={classes.textWrapper}>
                           <ControlledAccountPicker required label={t('Owner')} control={control} name={'analysisOwner'} watch={watch} setValue={setValue} />
                        </section>
                        <section className={classes.textWrapper}>
                           <Text>{t('BranchScope')}</Text>
                           <Text>{analysis.branch.name}</Text>
                        </section>
                        <section className={classes.textWrapper}>
                           <Text>{t('DataClassification')}</Text>
                           {analysis.dataClassification !== undefined ? (
                              <Text>{enumValuesToTranslate(DataClassification, analysis.dataClassification)}</Text>
                           ) : (
                              <Text italic>{t('NoValueSet')}</Text>
                           )}
                        </section>
                        <section className={classes.textWrapper}>
                           <Text> {t('ExtendedLevel')}</Text>
                           {analysis.additionalModules && analysis.additionalModules.length > 0 ? (
                              <Text>{t('Included')} </Text>
                           ) : (
                              <Text>{t('NotIncluded')} </Text>
                           )}
                        </section>
                        <section className={classes.textWrapper}>
                           <Text>{t('Comment')}</Text>
                           <Text>{analysis.comment}</Text>
                        </section>
                        <section className={mergeClasses(classes.textWrapper, classes.marginTop)}>
                           <Text>{t('ProcessOwner')}</Text>
                           {analysis.processOwners.map((processOwner: ProcessOwner, index) => (
                              <ProcessOwnerPicker
                                 key={index}
                                 demandAssessmentForm={demandAssessmentForm}
                                 processes={processes}
                                 process={processOwner.process.text}
                              ></ProcessOwnerPicker>
                           ))}
                        </section>
                        <ModalActions className={classes.marginTop}>
                           <Button onClick={closeModal}>{t('Cancel')}</Button>
                           <Button appearance="primary" type="submit">
                              {t('Save')}
                           </Button>
                        </ModalActions>
                     </form>
                  </DialogContent>
               </DialogBody>
            </DialogSurface>
         </Dialog>
      </>
   )
}
