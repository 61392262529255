import React, { useContext, useEffect } from 'react'
import { Modal, ModalActions } from '../../common/modal/Modal'
import { t } from '../../../i18n/i18n'
import { Button, Divider, Label, makeStyles, Text, tokens } from '@fluentui/react-components'
import { useForm } from 'react-hook-form'
import { RiskAnalysis, SWOT } from '../../../api/schemas/schema'
import { ControlledTextInput } from '../../common/inputs/TextInput'
import { GridItem } from '../../common/grid/GridItem'
import { Grid } from '../../common/grid/Grid'
import { api } from '../../../helpers/apiHelper'
import { DataContext } from '../../../context/DataContext'
import { handleMessage } from '../../../helpers/stateHelper'
import { IRiskAnalysisState } from '../../../interfaces/IRiskAnalysisState'
import { SwotTypeBadge } from './SwotTypeBadge'
import { ControlledCheckbox } from '../../common/inputs/ControlledCheckbox'
import { ControlledMoneyInput } from '../../common/inputs/ControlledMoneyInput'

const useStyles = makeStyles({
   column: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalXS,
   },
})

type ModalAnalyseEvaluateProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>
   analysis: RiskAnalysis
   open: boolean
   close: () => void
   selectedSwot: SWOT
   disabled?: boolean
}

export const ModalAnalyseEvaluate = ({ setRiskAnalysisState, analysis, open, close, selectedSwot, disabled }: ModalAnalyseEvaluateProps) => {
   const { control, reset, handleSubmit, watch, setValue } = useForm<SWOT>()
   const dataContext = useContext(DataContext)
   const setRootState = dataContext.setRootState
   const { accessToken, userAccount } = dataContext.state
   const classes = useStyles()

   useEffect(() => {
      if (selectedSwot) {
         reset(selectedSwot)
      }
   }, [selectedSwot, reset])

   const onSubmit = async (data: SWOT) => {
      try {
         data.modifiedBy = userAccount
         data.modifiedById = userAccount.id

         if (data.actionRequired === false) {
            data.proposedAction = null
            data.estimatedCost = null
         }

         const updatedRiskAnalysis = await api(accessToken).updateSwotForRiskAnalysis(analysis.id, data)
         setRiskAnalysisState((prev) => ({ ...prev, item: updatedRiskAnalysis.data }))
         close()
         reset()
         handleMessage(setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('SWOT') }))
      } catch (error) {
         handleMessage(setRootState, 'error', t('CouldNotUpdateItem', { Item: t('SWOT') }))
      }
   }

   return (
      <Modal modalType="modal" open={open} title={t('AnalyseEvaluate')}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
               <GridItem size="12/12">
                  <Divider appearance="brand" />
               </GridItem>
               <GridItem size="12/12">
                  <div className={classes.column}>
                     <Label weight="semibold">{t('Order')}</Label>
                     <Text>
                        {selectedSwot.order} {t('Of')} {analysis.swoTs.filter((s) => s.type === selectedSwot.type).length}
                     </Text>
                  </div>
               </GridItem>
               <GridItem size="12/12">
                  <div className={classes.column}>
                     <Label weight="semibold">{t('SWOT')}</Label>
                     <SwotTypeBadge type={selectedSwot.type} />
                  </div>
               </GridItem>
               <GridItem size="12/12">
                  <div className={classes.column}>
                     <Label weight="semibold">{t('Title')}</Label>
                     <Text>{selectedSwot.title}</Text>
                  </div>
               </GridItem>
               <GridItem size="12/12">
                  <div className={classes.column}>
                     <Label weight="semibold">{t('Description')}</Label>
                     <Text>{selectedSwot.description}</Text>
                  </div>
               </GridItem>
               <GridItem size="12/12">
                  <Divider appearance="brand" />
               </GridItem>
               <GridItem size="12/12">
               {watch('actionRequired') !== undefined && (
                  <ControlledCheckbox
                     onChange={(e, data) => {
                        setValue('actionRequired', Boolean(data.checked))
                        if (!data.checked) {
                           setValue('proposedAction', null)
                           setValue('estimatedCost', null)
                        }
                     }}
                     label={t('CreateProposedAction')}
                     control={control}
                     name={'actionRequired'}
                     checked={watch('actionRequired')} 
                     disabled={disabled}
                  />
                  )}
               </GridItem>
               {watch('actionRequired') && (
                  <>
                     <GridItem size="12/12">
                        <ControlledTextInput control={control} name={'proposedAction'} label={t('ProposedAction')} multiline required disabled={disabled} />
                     </GridItem>
                     <GridItem size="12/12">
                        <ControlledMoneyInput control={control} name={'estimatedCost'} label={t('EstimatedCost')} disabled={disabled} />
                     </GridItem>
                  </>
               )}
               <GridItem size="12/12">
                  <ControlledTextInput control={control} name={'comment'} label={t('Comment')} multiline disabled={disabled} />
               </GridItem>
               <GridItem size="12/12">
                  <ModalActions>
                     <Button
                        onClick={() => {
                           close()
                           reset()
                        }}
                     >
                        {disabled ? t('Close') : t('Cancel')}
                     </Button>
                     <Button type="submit" appearance="primary" disabled={disabled}>
                        {t('Save')}
                     </Button>
                  </ModalActions>
               </GridItem>
            </Grid>
         </form>
      </Modal>
   )
}
