import React, { useContext, useEffect, useState } from 'react'
import { Combobox, ComboboxProps, Option } from '@fluentui/react-components'
import { Avatar } from '../avatar/Avatar'
import { UserAccount } from '../../../api/schemas/schema'
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { Field } from './Field'
import { DataContext } from '../../../context/DataContext'
import { t } from '../../../i18n/i18n'
import { getFullName } from '../../../helpers/accountHelper'

type AccountPickerProps = {
   label: string
   name: any
   control: Control<any>
   watch: UseFormWatch<any>
   setValue: UseFormSetValue<any>
   required?: boolean
}
export const ControlledAccountPicker = ({ label, name, control, watch, setValue, required }: AccountPickerProps) => {
   const dataContext = useContext(DataContext)
   const { userAccounts } = dataContext.state
   const [matchingOptions, setMatchingOptions] = useState<UserAccount[]>(userAccounts)
   const [isValid, setIsValid] = useState<boolean>(false)
   const [input, setInput] = useState<string>('')
   const [currentSelectedAccount, setCurrentSelectedAccount] = useState<UserAccount>()

   const onInput: ComboboxProps['onChange'] = (event) => {
      setIsValid(false)

      const value = event.target.value.trim()
      setInput(value)
      let matches = userAccounts
      if (value.length > 0) {
         matches = userAccounts.filter((userAccount) => getFullName(userAccount).toLowerCase().indexOf(value.toLowerCase()) === 0)
      }
      if (matches.length === 0) {
         matches = userAccounts
      }
      setMatchingOptions(matches)
   }

   useEffect(() => {
      if (!input) {
         const defaultSelectedAccount: UserAccount = watch(name)
         setCurrentSelectedAccount(defaultSelectedAccount)
         setInput(getFullName(defaultSelectedAccount) ?? '')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [watch(name)])

   return (
      <Controller
         name={name}
         control={control}
         rules={{
            validate: (value) => {
               if (required && (!value || value === '')) {
                  return t('AnAccountMustBeSelected')
               }
               return true
            },
         }}
         render={({ field: { value }, fieldState: { error }, formState: { disabled } }) => (
            <Field required={required ?? false} label={label} validationMessage={error ? t('AnAccountMustBeSelected') : undefined}>
               <Combobox
                  value={input}
                  selectedOptions={value ? [value.id] : []}
                  required={required ?? false}
                  freeform
                  disabled={disabled ?? false}
                  onChange={onInput}
                  onBlur={(e) => {
                     if (!isValid) {
                        setInput(getFullName(currentSelectedAccount) ?? '')
                     }
                  }}
                  onOptionSelect={(e, data) => {
                     const chosenAccount = userAccounts.find((x) => x.id === data.optionValue)
                     if (chosenAccount) {
                        setValue(name, chosenAccount)
                        setValue(`${name}Id`, data.optionValue)
                        setInput(chosenAccount ? getFullName(chosenAccount) : '')
                        setIsValid(true)
                     }
                  }}
               >
                  {matchingOptions &&
                     matchingOptions.map((account) => (
                        <Option key={account.id} value={account.id} text={getFullName(account)}>
                           <Avatar color="colorful" name={getFullName(account)} />
                        </Option>
                     ))}
               </Combobox>
            </Field>
         )}
      />
   )
}
