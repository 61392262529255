import { Button, Dialog, DialogContent, DialogTitle } from '@fluentui/react-components'
import React, { useContext } from 'react'
import { ModalActions, DialogSurface, DialogBody } from '../common/modal/Modal'
import { t } from '../../i18n/i18n'
import { LockClosed24Regular } from '@fluentui/react-icons'
import { Text } from '../common/text/Text'
import { RiskAnalysis } from '../../api/schemas/schema'
import { IRiskAnalysisState } from '../../interfaces/IRiskAnalysisState'
import { DataContext } from '../../context/DataContext'
import { api } from '../../helpers/apiHelper'
import { handleMessage } from '../../helpers/stateHelper'

type ModalDetermineRiskAnalysisProps = {
   analysis: RiskAnalysis
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>

   open: boolean
   close: () => void
}

export const ModalDetermineRiskAnalysis = ({ analysis, setRiskAnalysisState, open, close }: ModalDetermineRiskAnalysisProps) => {
   const dataContext = useContext(DataContext)

   const determineRiskAnalysis = async () => {
      const { accessToken, userAccount, organisation, branch } = dataContext.state
      const { setRootState } = dataContext

      try {
         const data = analysis
         data.modifiedById = userAccount.id

         const updatedRiskAnalysis = await api(accessToken).determineRiskAnalysis(organisation.id, { branchId: branch.id }, data)

         setRiskAnalysisState((prev) => ({ ...prev, item: updatedRiskAnalysis.data }))

         close()
         handleMessage(setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('RiskAnalysis') }))
      } catch (error) {
         handleMessage(setRootState, 'error', t('CouldNotUpdateItem', { Item: t('RiskAnalysis') }))
      }
   }

   return (
      <Dialog open={open}>
         <DialogSurface>
            <DialogBody>
               <DialogTitle as="h3">{t('DetermineRiskAnalysis')}</DialogTitle>
               <DialogContent>
                  <Text>{t('DetermineRiskAnalysisConfirmationText')}</Text>
                  <ModalActions>
                     <Button onClick={close}>{t('Cancel')}</Button>
                     <Button appearance="primary" icon={<LockClosed24Regular />} onClick={determineRiskAnalysis}>
                        {t('Determine')}
                     </Button>
                  </ModalActions>
               </DialogContent>
            </DialogBody>
         </DialogSurface>
      </Dialog>
   )
}
