import React from 'react'
import { makeStyles, mergeClasses, Table, TableBody, TableCell, TableHeader, TableRow, tokens } from '@fluentui/react-components'
import { ColumnDefinition } from '../../../helpers/swotHelper'
import { PhoneList } from './PhoneList'

const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   pointer: {
      cursor: 'pointer',
   },
   borderBottom: {
      borderBottom: `2px solid ${tokens.colorBrandStroke2}`,
   },
   semibold: { '& > *': { fontWeight: tokens.fontWeightSemibold } },
   padding: {
      paddingTop: tokens.spacingVerticalXS,
      paddingBottom: tokens.spacingVerticalL,
   },
   phonePadding: {
      paddingTop: tokens.spacingVerticalXS,
      paddingBottom: tokens.spacingVerticalS,
   },
   lastPhoneItem: {
      marginBottom: tokens.spacingVerticalS,
   },
})

type SectionedListProps<T extends {}> = {
   columns: ColumnDefinition<T>[]
   items: T[]
   groupKey: keyof T
   sortable?: boolean
   onRowClick?: (item: T) => void
   onCellClick?: (item: T, columnKey: keyof T) => void
   renderPhoneBody?: (item: T) => JSX.Element | JSX.Element[]
   className?: string
}

export const SectionedList = <T extends {}>({
   columns,
   items,
   groupKey,
   sortable,
   onRowClick,
   onCellClick,
   renderPhoneBody,
   className,
}: SectionedListProps<T>) => {
   const classes = useStyles()
   const groupItemByKey = (items: T[], groupKey: keyof T): Record<string, T[]> => {
      return items.reduce((grouped: Record<string, T[]>, current: T) => {
         const key = String(current[groupKey] ?? 'undefined')
         if (!grouped[key]) {
            grouped[key] = []
         }
         grouped[key].push(current)
         return grouped
      }, {})
   }

   const groupedItems = groupItemByKey(items, groupKey)

   return (
      <>
         <Table sortable={sortable ?? false} className={mergeClasses(className ?? '', classes.hideInPhone)}>
            <TableHeader>
               <TableRow className={classes.semibold}>
                  {columns.map((col) => (
                     <TableCell key={String(col.key)}>{col.renderHeaderCell ? col.renderHeaderCell() : String(col.key)}</TableCell>
                  ))}
               </TableRow>
            </TableHeader>

            {Object.entries(groupedItems).map(([key, group], groupIndex) => (
               <>
                  <TableBody key={key} className={Object.entries(groupedItems).length - 1 !== groupIndex ? classes.borderBottom : ''}>
                     {group.map((item, index) => (
                        <TableRow key={index} 
                        className={classes.pointer}>
                           {columns.map((col) => (
                              <TableCell className={classes.padding} key={String(col.key)} onClick={onCellClick ? () => onCellClick(item, col.key) : undefined}>
                                 {col.renderCell ? col.renderCell(item[col.key], item) : item[col.key]?.toString() ?? ''}
                              </TableCell>
                           ))}
                        </TableRow>
                     ))}
                  </TableBody>
               </>
            ))}
         </Table>

         {Object.entries(groupedItems).map(([key, group], groupIndex) => (
            <PhoneList
               items={group}
               onRowClick={onRowClick}
               renderBodyItem={renderPhoneBody}
               className={mergeClasses(
                  Object.entries(groupedItems).length - 1 !== groupIndex ? mergeClasses(classes.borderBottom, classes.lastPhoneItem) : '',
                  classes.phonePadding
               )}
            />
         ))}
      </>
   )
}
