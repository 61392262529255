import React from 'react'
import {
   InfoShield20Regular,
   SearchShield20Regular,
   Scales20Regular,
   TaskListSquareLtr20Regular,
   TaskListSquareLtr24Filled,
   SearchShield20Filled,
   Scales20Filled,
   InfoShield20Filled,
} from '@fluentui/react-icons'

import { Tab } from '@fluentui/react-components'
import { SWOTTabs } from '../../../helpers/swotHelper'

type SWOTNavLinksProps = {
   id: string
   active: string
   actionTab: JSX.Element
}
export const SWOTNavLinks = ({ active, id, actionTab }: SWOTNavLinksProps) => {
   const baseLink = `/risk-management/${id}`
   return (
      <>
         <Tab value={`${baseLink}?tab=${SWOTTabs.Info}`} icon={active === SWOTTabs.Info ? <InfoShield20Filled /> : <InfoShield20Regular />} />
         <Tab value={`${baseLink}?tab=${SWOTTabs.Identifying}`} icon={active === SWOTTabs.Identifying ? <SearchShield20Filled /> : <SearchShield20Regular />} />
         <Tab value={`${baseLink}?tab=${SWOTTabs.AnalyseEvaluate}`} icon={active === SWOTTabs.AnalyseEvaluate ? <Scales20Filled /> : <Scales20Regular />} />
         <Tab
            value={`${baseLink}?tab=${SWOTTabs.ActionPlan}`}
            icon={active === SWOTTabs.ActionPlan ? <TaskListSquareLtr24Filled /> : <TaskListSquareLtr20Regular />}
         />
         {actionTab}
      </>
   )
}
