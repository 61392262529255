import React, { useState } from 'react'
import { makeStyles, tokens, Card, CardHeader, Text } from '@fluentui/react-components'
import { t } from '../../../../i18n/i18n'
import { BarChart, CartesianGrid, YAxis, XAxis, Bar, Legend, Tooltip } from 'recharts'
import { ActionPlan, DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { findQuestionParentToActionPlan } from '../../../../helpers/actionPlanHelper'
const useStyles = makeStyles({
   legend: {
      '& * span': {
         color: 'black',
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   chart: {
      left: '-30px',
   },
   flex: {
      display: 'flex',
      gap: tokens.spacingHorizontalS,
   },
})
type ActionPlanComplianceChartProps = {
   actionPlans: ActionPlan[]
   questions: DemandAnalysisQuestion[]
}
export const ActionPlanComplianceChart = ({ actionPlans, questions }: ActionPlanComplianceChartProps) => {
   const classes = useStyles()
   const [activeIndex, setActiveIndex] = useState<number | null>(null)

   const getData = () => {
      let complianceLevels: { name: string; order: number; color: string; number: number; processes: { name: string; number: number }[] }[] = []
      actionPlans.forEach((ap) => {
         const parent = findQuestionParentToActionPlan(questions, ap)
         const answer = parent.answer
         const answerText = parent.answer.text.split(' ')[0]
         const processText = parent.processes[0]
         const level = complianceLevels.find((c) => c.name === answerText)
         if (!level)
            complianceLevels.push({
               name: answerText,
               order: parent.answer.order,
               color: answer.colorHex,
               number: 1,
               processes: [{ name: processText, number: 1 }],
            })
         else {
            level.number++
            const existingProcess = level.processes.find((p) => p.name === processText)
            if (existingProcess) existingProcess.number++
            else level.processes.push({ name: processText, number: 1 })
         }
      })

      return complianceLevels.sort((a, b) => a.order - b.order)
   }
   return (
      <>
         <BarChart className={classes.noneInPhone} width={1300} height={800} data={getData()} margin={{ top: 20, right: 20, left: 20, bottom: 200 }}>
            <CartesianGrid />
            <YAxis />
            <XAxis dataKey="name" dy={10} />
            <Bar
               dataKey="number"
               fill={tokens.colorBrandBackground}
               barSize={300}
               onMouseEnter={(e) => setActiveIndex(e.activeTooltipIndex)}
               onMouseLeave={() => setActiveIndex(null)}
               onMouseMove={(e) => setActiveIndex(e.activeTooltipIndex)}
               label={(props) => {
                  const { x, y, width, height, name, color } = props

                  const pillWidth = 200 // Bredd på pill
                  const pillHeight = 30 // Höjd på pill
                  const pillX = x + (width - pillWidth) / 2 // Centrerar pill horisontellt
                  const pillY = y + height + 10 // Placerar pill ovanför stapeln
                  return (
                     <g>
                        <rect x={pillX} y={pillY} width={pillWidth} height={pillHeight} fill={color} ry={5} />
                        <text
                           textAnchor="middle"
                           width={pillWidth}
                           x={x + width / 2}
                           y={pillY + pillHeight / 2 + 5}
                           fill={tokens.colorNeutralForegroundInverted}
                        >
                           {name}
                        </text>
                     </g>
                  )
               }}
            />

            <Legend
               verticalAlign="top"
               align="right"
               iconType="rect"
               className={classes.legend}
               formatter={(value) => {
                  let text = ''
                  if (value === 'number') text = t('NumberOfActions')

                  return <span style={{ color: 'black' }}>{text}</span>
               }}
            />
            <Tooltip active={activeIndex !== null} cursor={false} content={<CustomTooltip />} />
         </BarChart>
      </>
   )
}
const CustomTooltip = (props: any) => {
   const classes = useStyles()
   const { active, payload, label } = props
   if (active && payload && payload.length) {
      const data = payload[0].payload

      return (
         <Card>
            <CardHeader header={<Text weight="semibold">{label}</Text>} />
            {data.processes.map((p: any) => (
               <span className={classes.flex}>
                  <Text>{p.name}:</Text>
                  <Text>{p.number}</Text>
               </span>
            ))}
         </Card>
      )
   }

   return null
}
