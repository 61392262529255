import React, { useState } from 'react'
import { Card, CardHeader, makeStyles, tokens, Text } from '@fluentui/react-components'
import { BarChart, CartesianGrid, YAxis, XAxis, Bar, Legend, Tooltip } from 'recharts'
import { ActionPlan, DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { findQuestionParentToActionPlan } from '../../../../helpers/actionPlanHelper'
import { t } from '../../../../i18n/i18n'
const useStyles = makeStyles({
   legend: {
      '& * span': {
         color: 'black',
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },

   chart: {
      left: '-30px',
   },
   flex: {
      display: 'flex',
      gap: tokens.spacingHorizontalS,
   },
})
const COLORS = {
   good: { color: 'rgb(19, 161, 14)', label: 'Bra' },
   fundamental: { color: 'rgb(0, 102, 109)', label: 'Grundläggande' },
   some: { color: 'rgb(234, 163, 0)', label: 'Viss' },
   defective: { color: 'rgb(247, 99, 12)', label: 'Bristfällig' },
   bad: { color: 'rgb(197, 15, 31)', label: 'Dålig' },
}
type ActionPlanProcessChartProps = {
   actionPlans: ActionPlan[]
   questions: DemandAnalysisQuestion[]
}
type BarData = { process: string; good: number; fundamental: number; some: number; defective: number; bad: number }
export const ActionPlanProcessChart = ({ actionPlans, questions }: ActionPlanProcessChartProps) => {
   const [activeIndex, setActiveIndex] = useState<number | null>(null)
   const classes = useStyles()

   const getData = () => {
      return actionPlans.reduce((acc: BarData[], ap) => {
         const parent = findQuestionParentToActionPlan(questions, ap)
         const { text } = parent.answer
         const processText = parent.processes[0]
         const compliance = text.split(' ')[0].toLowerCase()

         let bar = acc.find((b) => b.process === processText)

         if (!bar) {
            bar = {
               process: processText,
               good: 0,
               fundamental: 0,
               some: 0,
               defective: 0,
               bad: 0,
            }
            acc.push(bar)
         }

         if (compliance === 'bra') bar.good++
         else if (compliance === 'grundläggande') bar.fundamental++
         else if (compliance === 'viss') bar.some++
         else if (compliance === 'bristfällig') bar.defective++
         else if (compliance === 'dålig') bar.bad++

         return acc
      }, [])
   }
   const data = getData()
   return (
      <>
         <BarChart className={classes.noneInPhone} width={1300} height={800} data={data} margin={{ top: 20, right: 20, left: 20, bottom: 200 }}>
            <CartesianGrid />
            <YAxis />
            <XAxis dataKey="process" dy={10} />
            {Object.entries(COLORS).map(([key, { color }], i) => (
               <Bar
                  key={i}
                  dataKey={key}
                  fill={color}
                  barSize={20}
                  onMouseEnter={(e) => setActiveIndex(e.activeTooltipIndex)}
                  onMouseLeave={() => setActiveIndex(null)}
                  onMouseMove={(e) => setActiveIndex(e.activeTooltipIndex)}
               />
            ))}
            <Legend
               verticalAlign="top"
               align="right"
               iconType="rect"
               className={classes.legend}
               formatter={(value) => {
                  return <span style={{ color: 'black' }}>{COLORS[value as keyof typeof COLORS].label}</span>
               }}
            />
            <Tooltip active={activeIndex !== null} cursor={false} content={<CustomTooltip />} />
         </BarChart>
      </>
   )
}

const CustomTooltip = (props: any) => {
   const { active, payload, label } = props
   if (active && payload && payload.length) {
      const data: BarData = payload[0].payload
      let compliance = ''
      let number = 0
      if (data.good > 0) {
         compliance = COLORS.good.label
         number = data.good
      }
      if (data.fundamental > 0) {
         compliance = COLORS.fundamental.label
         number = data.fundamental
      }
      if (data.some > 0) {
         compliance = COLORS.some.label
         number = data.some
      }
      if (data.defective > 0) {
         compliance = COLORS.defective.label
         number = data.defective
      }
      if (data.bad > 0) {
         compliance = COLORS.bad.label
         number = data.bad
      }
      return (
         <Card>
            <CardHeader
               header={
                  <Text weight="semibold">
                     {label} - {compliance}
                  </Text>
               }
            />
            <Text>
               {t('NumberOfActions')}: {number}
            </Text>
         </Card>
      )
   }

   return null
}
