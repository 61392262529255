import React from 'react'
import { GridItem } from '../../common/grid/GridItem'
import { Text } from '../../common/text/Text'
import { Divider, makeStyles, tokens } from '@fluentui/react-components'
import { SWOT } from '../../../api/schemas/schema'
import { t } from '../../../i18n/i18n'
import { SwotTypeBadge } from './SwotTypeBadge'
const useStyles = makeStyles({
   marginTopXS: {
      marginTop: tokens.spacingVerticalXS,
   },
   marginTopS: {
      marginTop: tokens.spacingVerticalS,
      marginBottom: tokens.spacingVerticalS,
   },
})
type ParentSWOTInformationProps = {
   swot: SWOT
   swots: SWOT[]
}
export const ParentSWOTInformation = ({ swot, swots }: ParentSWOTInformationProps) => {
   const classes = useStyles()
   return (
      <>
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('SWOT')}
            </Text>
            <SwotTypeBadge type={swot.type} className={classes.marginTopS} />
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('Title')}
            </Text>
            <Text className={classes.marginTopS}>{swot.title}</Text>
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('Description')}
            </Text>
            <Text className={classes.marginTopS}>{swot.description}</Text>
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('ProposedAction')}
            </Text>
            <Text className={classes.marginTopS}>{swot.proposedAction}</Text>
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('EstimatedCost')}
            </Text>
            <Text italic={!swot.estimatedCost} className={classes.marginTopS}>
               {swot.estimatedCost ?? t('NoValueSet')}
            </Text>
         </GridItem>
      </>
   )
}
