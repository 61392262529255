import React, { useContext } from 'react'
import { ProgressBar, Button, makeStyles } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import { getDefaultValues } from '../../helpers/demandAssessmentHelper'
import { Modal, ModalActions } from '../common/modal/Modal'
import { Text } from '../common/text/Text'
import { UseFormReturn } from 'react-hook-form'
import { DemandAnalysis } from '../../api/schemas/schema'
import { DataContext } from '../../context/DataContext'
import { IComplianceState } from '../../interfaces/IComplianceState'
import { useNavigate } from 'react-router-dom'
const useStyles = makeStyles({
   btnRight: { justifyContent: 'end' },
})
type ModalLoadingDemandAssessmentProps = {
   demandAssessmentForm: UseFormReturn<DemandAnalysis, any, undefined>
   setComplianceState: React.Dispatch<React.SetStateAction<IComplianceState>>
   complianceState: IComplianceState
   error: string
}
export const ModalLoadingDemandAssessment = ({ demandAssessmentForm, setComplianceState, complianceState, error }: ModalLoadingDemandAssessmentProps) => {
   const dataContext = useContext(DataContext)
   const {
      reset,
      getValues,
      formState: { isSubmitting, isSubmitSuccessful, isValid },
   } = demandAssessmentForm
   const classes = useStyles()
   const navigate = useNavigate()
   const openDemandAssessment = () => {
      setComplianceState((prev) => ({ ...prev, demandAnalyses: [...prev.allDemandAssessments, getValues()] }))
      navigate('/compliance/' + getValues().id)
      reset(getDefaultValues(dataContext, complianceState))
   }

   return (
      <Modal
         modalType="alert"
         title={isSubmitting ? t('CreatingDemandAssessment') : error ? t('CouldNotCreateItem', { Item: t('DemandAssessment') }) : t('DemandAssessmentCreated')}
         open={isValid && (isSubmitting || isSubmitSuccessful)}
      >
         {isSubmitting ? (
            <>
               <ProgressBar />
               <Text>{t('ThisCanTakeAMoment')}</Text>
            </>
         ) : error ? (
            <Text>
               {t('ErrorMessage')}: {error}
            </Text>
         ) : (
            <Text>{t('ClickContinueToGoToAssessment')}</Text>
         )}
         <ModalActions className={classes.btnRight}>
            {error ? (
               <Button onClick={() => reset(getDefaultValues(dataContext, complianceState))}>{t('Close')}</Button>
            ) : isSubmitSuccessful ? (
               <Button appearance="primary" onClick={openDemandAssessment}>
                  {t('Continue')}
               </Button>
            ) : null}
         </ModalActions>
      </Modal>
   )
}
