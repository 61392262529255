import { t } from 'i18next'
import React from 'react'
import { GridItem } from '../common/grid/GridItem'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { UseFormReturn } from 'react-hook-form'
import { Organisation } from '../../api/schemas/schema'

type InvoiceDetailsFormProps = {
   organisationForm: UseFormReturn<Organisation, any, undefined>
}

export const InvoiceDetailsForm = ({ organisationForm }: InvoiceDetailsFormProps) => {
   const { control } = organisationForm
   return (
      <>
         <GridItem size="12/12">
            <ControlledTextInput required control={control} type="text" name={'invoiceReference'} label={t('InvoiceReference')} />
         </GridItem>
         <GridItem size="12/12">
            <ControlledTextInput required control={control} type="text" name={'invoiceAddress'} label={t('InvoiceAddress')} />
         </GridItem>
         <GridItem size="6/12">
            <ControlledTextInput required control={control} type="text" name={'postalCode'} label={t('PostalCode')} />
         </GridItem>
         <GridItem size="6/12">
            <ControlledTextInput required control={control} type="text" name={'county'} label={t('County')} />
         </GridItem>
         <GridItem size="6/12">
            <ControlledTextInput required control={control} type="number" name={'phoneNumber'} label={t('PhoneNumber')} />
         </GridItem>
         <GridItem size="6/12">
            <ControlledTextInput required control={control} type="email" name={'email'} label={t('Email')} />
         </GridItem>
      </>
   )
}
