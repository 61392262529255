import React, { useContext, useState } from 'react'
import { Menu, MenuTrigger, Avatar, mergeClasses, MenuPopover, MenuList, MenuItem, makeStyles } from '@fluentui/react-components'
import { t } from '../../../i18n/i18n'
import { msalInstance } from '../../../Root'
import { SignOut24Regular } from '@fluentui/react-icons'
import { DataContext } from '../../../context/DataContext'
import { ModalAccountInformation } from '../../userAccount/ModalAccountInformation'
import { getFullName } from '../../../helpers/accountHelper'
const useStyles = makeStyles({
   pointer: {
      cursor: 'pointer',
   },
   avatar: {
      marginRight: '20px',
      '@media screen and (max-width: 1007px)': { marginRight: 0 },
   },
})
export const UserAccountMenu = () => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { userAccount } = dataContext.state
   const [open, setOpen] = useState<boolean>(false)
   return (
      <>
         <Menu>
            <MenuTrigger>
               <Avatar className={mergeClasses(classes.pointer, classes.avatar)} size={24} name={getFullName(userAccount)} />
            </MenuTrigger>
            <MenuPopover>
               <MenuList>
                  <MenuItem onClick={() => setOpen(true)}>{t('AccountInformation')}</MenuItem>
                  <MenuItem icon={<SignOut24Regular />} onClick={() => msalInstance.logoutRedirect()}>
                     {t('SignOut')}
                  </MenuItem>
               </MenuList>
            </MenuPopover>
         </Menu>
         <ModalAccountInformation open={open} close={() => setOpen(false)} />
      </>
   )
}
