import React from 'react'
import { Checkbox, TableColumnDefinition, Text, tokens } from '@fluentui/react-components'
import { t } from '../i18n/i18n'
import { SwotTypeBadge } from '../components/riskAnalyses/SWOT/SwotTypeBadge'
import { ActionPlan, ActionPlanStatus, RiskAnalysis, SWOT, SWOTType } from '../api/schemas/schema'
import { IGroupedTableColumnDefintion } from '../interfaces/IGroupedTableColumnDefintion'
import { IGroup } from '../interfaces/IGroup'
import { enumValuesToTranslate } from './enumHelper'
import { BadgeActionPlanStatus } from '../components/demandAnalysis/BadgeActionPlanStatus'
import { formatToSwedishCrowns } from './stringHelper'
import { ISummaryField } from '../interfaces/ISummaryField'
import { GroupHeader } from '../components/common/list/GroupHeader'
import { api } from './apiHelper'
import { IRiskAnalysisState } from '../interfaces/IRiskAnalysisState'
import { IDataContext } from '../interfaces/IDataContext'
import { handleMessage } from './stateHelper'

export enum SWOTTabs {
   Info = 'info',
   Identifying = 'identifying',
   AnalyseEvaluate = 'analyse/evaluate',
   ActionPlan = 'action-plan',
}

export enum SWOTColors {
   Strength = '#9FD89F',
   Weakness = '#EDC978',
   Opportunity = '#9BD9DB',
   Threat = '#EEACB2',
}

export const getSWOTIdentifyColumns = (ellipsisClassname: string): TableColumnDefinition<SWOT>[] => [
   {
      columnId: 'type',
      renderHeaderCell: () => t('Type'),
      renderCell: (item) => <SwotTypeBadge type={item.type} />,
      compare: (a, b) => a.type - b.type,
   },
   {
      columnId: 'title',
      renderHeaderCell: () => t('Title'),
      renderCell: (item) => item.title,
      compare: (a, b) => a.title.localeCompare(b.title),
   },
   {
      columnId: 'description',
      renderHeaderCell: () => t('Description'),
      renderCell: (item) => <Text>{item.description}</Text>,
      compare: (a, b) => a.description.localeCompare(b.description),
   },
]

export const getSWOTTypeColor = (type: SWOTType): string => {
   switch (type) {
      case SWOTType.Strength:
         return SWOTColors.Strength
      case SWOTType.Weakness:
         return SWOTColors.Weakness
      case SWOTType.Opportunity:
         return SWOTColors.Opportunity
      case SWOTType.Threat:
         return SWOTColors.Threat
      default:
         throw new Error(`Unsupported SWOTType: ${type}`)
   }
}

export const getBadgeStyle = (type: SWOTType) => ({
   backgroundColor: getSWOTTypeColor(type),
   color: tokens.colorBrandBackground,
})

export const getTypeLanguageString = (type: string): string => {
   switch (type) {
      case 'Strength':
         return t('Strengths')
      case 'Weakness':
         return t('Weaknesses')
      case 'Opportunity':
         return t('Opportunities')
      case 'Threat':
         return t('Threats')
   }
}

export type ColumnDefinition<T> = {
   key: keyof T
   header?: string
   renderHeaderCell?: () => React.ReactNode
   renderCell?: (value: T[keyof T], item: T) => React.ReactNode
   render?: (value: T[keyof T], item: T) => React.ReactNode
   compare?: (a: T, b: T) => number
   onCellClick?: (value: T[keyof T], item: T) => React.ReactNode
}

export const getSWOTAnalyseEvaluateColumns = (classes: any): ColumnDefinition<SWOT>[] => [
   {
      key: 'type',
      renderHeaderCell: () => t('Type'),
      renderCell: (item) => <SwotTypeBadge type={item as number} />,
      compare: (a, b) => a.type - b.type,
   },
   {
      key: 'order',
      renderHeaderCell: () => t('Order'),
      renderCell: (item) => item.toString() ?? null ?? '',
      compare: (a, b) => a.order - b.order,
   },
   {
      key: 'title',
      renderHeaderCell: () => t('Title'),
      renderCell: (item) => item.toString(),
      compare: (a, b) => a.title.localeCompare(b.title),
   },
   {
      key: 'description',
      renderHeaderCell: () => t('Description'),
      renderCell: (item) => <div className={classes ? classes.ellipsis : ''}>{item.toString()}</div>,
      compare: (a, b) => a.description.localeCompare(b.description),
   },
   {
      key: 'actionRequired',
      renderHeaderCell: () => t('ActionProposal'),
      renderCell: (item) => <Checkbox checked={item as boolean} />,
   },
]
export const getSWOTActionPlanColumns = (swots: SWOT[]): IGroupedTableColumnDefintion<ActionPlan>[] => [
   {
      columnId: 'title',
      colWidth: 'auto',
      renderHeaderCell: () => t('Title'),
      renderGroupHeaderCell: (group) => {
         return <GroupHeader title={t('SWOT')} badge={<SwotTypeBadge type={Number(group.key)} />} />
      },
      renderCell: (item) => {
         const swot = findSWOTParentToActionPlan(swots, item)
         return swot.title
      },
      compare: () => -1,
   },
   {
      columnId: 'estimatedCost',
      colWidth: '400px',
      renderHeaderCell: () => t('EstimatedCostShort'),
      renderCell: (item) => {
         const swot = findSWOTParentToActionPlan(swots, item)
         return formatToSwedishCrowns(swot.estimatedCost) + ' kr'
      },
      renderGroupHeaderCell: (group) => {
         let estimatedCostTotal = 0
         group.items.map((x) => (estimatedCostTotal += findSWOTParentToActionPlan(swots, x).estimatedCost))
         return formatToSwedishCrowns(estimatedCostTotal) + ' kr'
      },
      compare: () => -1,
   },
   {
      columnId: 'status',
      colWidth: '300px',
      renderHeaderCell: () => t('DecisionStatus'),
      renderCell: (item) => <BadgeActionPlanStatus status={item.status} />,
      renderGroupHeaderCell: (group) => {
         const totalAnswered = group.items.filter((item) => item.status !== ActionPlanStatus.NotDecided).length
         const totalItems = group.items.length
         // group.items.map((x) => x.status === ActionPlanStatus.Ongoing)
         return `${totalAnswered} / ${totalItems}`
      },
      compare: () => -1,
   },
]

export const findSWOTParentToActionPlan = (swots: SWOT[], actionPlan: ActionPlan) => swots.find((q) => q.id === actionPlan.swotId)

export const getGroupedSWOTActionPlans = (swots: SWOT[], actionPlans: ActionPlan[]): IGroup<ActionPlan>[] => {
   const groups: IGroup<ActionPlan>[] = []
   actionPlans.forEach((plan) => {
      const swot = swots.find((s) => s.id === plan.swotId)
      const key = swot.type.toString()
      const label = enumValuesToTranslate(SWOTType, swot.type)
      const group = groups.find((g) => g.key === key)
      if (!group) {
         groups.push({ label, key, order: swot.type, hex: '', expanded: true, items: [] })
      }
      groups.find((g) => g.key === key).items.push(plan)
   })
   return groups.sort((a, b) => b.order - a.order)
}

export const getSWOTActionPlanSummaryFields = (actionPlans: ActionPlan[], swots: SWOT[]): ISummaryField[] => {
   let estimatedCost = 0
   actionPlans.forEach((plan) => {
      const swot = swots.find((s) => s.id === plan.swotId)
      estimatedCost += swot.estimatedCost ?? 0
   })
   const totalAnswered = actionPlans.filter((plan) => plan.status !== ActionPlanStatus.NotDecided).length
   const totalItems = actionPlans.length
   return [
      { label: t('EstimatedCostShort'), text: `${formatToSwedishCrowns(estimatedCost)} kr` },
      { label: t('DecidedMulti'), text: `${totalAnswered} / ${totalItems}` },
   ]
}

export const saveSWOTActionPlan = async (
   data: ActionPlan,
   accessToken: string,
   parent: RiskAnalysis,
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>
) => {
   try {
      const updatedRiskAnalysis = (await api(accessToken).updateActionPlanOnRiskAnalysis(parent.id, data)).data
      setRiskAnalysisState((prev) => ({ ...prev, item: updatedRiskAnalysis }))
      return true
   } catch (error: any) {
      return false
   }
}

export const getFilteredSWOTs = (swots: SWOT[], selectedType: string): SWOT[] => {
   let filteredSWOTs = swots

   if (Object.entries(SWOTType).find(([key, value]) => selectedType === key.toString())) {
      filteredSWOTs = filteredSWOTs.filter((s) => s.type.toString() === selectedType)
   }
   return filteredSWOTs
}

export const groupSWOTsByType = (items: SWOT[]) =>
   items.reduce(
      (groups, item) => {
         const groupKey = SWOTType[item.type]
         if (!groups[groupKey]) {
            groups[groupKey] = []
         }
         groups[groupKey].push(item)
         return groups
      },
      {} as Record<string, SWOT[]>
   )

export const changeOrderOfSwots = async (
   dataContext: IDataContext,
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>,
   analysis: RiskAnalysis,
   reorderedSwots: SWOT[]
) => {
   const { accessToken } = dataContext.state
   const { setRootState } = dataContext

   const updatedAnalysis = {
      ...analysis,
      swoTs: reorderedSwots,
   }
   try {
      const response = await api(accessToken).updateRiskAnalysis(updatedAnalysis)

      setRiskAnalysisState((prev) => ({
         ...prev,
         item: response.data,
      }))

      handleMessage(setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('RiskAnalysis') }))
   } catch (error) {
      handleMessage(setRootState, 'error', t('CouldNotUpdateItem', { Item: t('RiskAnalysis') }))
   }
}
