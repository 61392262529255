import React from 'react'
import { Divider, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { Title } from '../text/Title'

const useStyles = makeStyles({
   card: {
      borderRadius: tokens.borderRadiusLarge,
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingHorizontalM,
   },
   beige: {
      boxShadow: tokens.shadow8,
      backgroundColor: '#FFFDF5',
      padding: `${tokens.spacingVerticalXXL} ${tokens.spacingHorizontalXXXL}`,
   },
   transparentInPhone: {
      padding: `${(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXXL)}`,
      boxShadow: tokens.shadow8,
      backgroundColor: '#FFFDF5',
      '@media screen and (max-width: 1007px)': {
         boxShadow: 'none',
         padding: 0,
         backgroundColor: 'transparent',
      },
   },
   showOnlyLarge: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
   },
})
type CardProps = {
   title: string
   titleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
   children: JSX.Element | JSX.Element[] | string | string[]
   className?: string
   contentClassName?: string
   headerClassName?: string
   transparentInPhone?: boolean
   action?: JSX.Element
   hideDivider?: boolean
}
export const Card = ({ title, titleAs, children, className, transparentInPhone, contentClassName, action, headerClassName, hideDivider = false  }: CardProps) => {
   const classes = useStyles()
   return (
      <article aria-label="group" className={mergeClasses(transparentInPhone ? classes.transparentInPhone : classes.beige, classes.card, className ?? '')}>
         <header className={mergeClasses(classes.showOnlyLarge, classes.header, headerClassName)}>
            <Title as={titleAs ?? 'h2'}>{title}</Title>
            {action ? action : null}
         </header>
         {!hideDivider &&  <Divider className={classes.showOnlyLarge} appearance="brand" />}
         <main className={contentClassName ?? ''}>{children}</main>
      </article>
   )
}
