import React, { createContext, useEffect, useState } from 'react'
import { initialDataContext, GetInitialRootState } from '../helpers/ctxHelper'
import { handleMessage } from '../helpers/stateHelper'
import { IDataContext } from '../interfaces/IDataContext'
import { IRootState } from '../interfaces/IRootState'
import { Branch, Organisation, ProtectedAsset, UserAccount } from '../api/schemas/schema'
import { api } from '../helpers/apiHelper'
import { t } from '../i18n/i18n'
import { msalInstance } from '../Root'
import { useMsal } from '@azure/msal-react'

export const DataContext = createContext(initialDataContext)

type DataContextProviderType = {
   children: JSX.Element | JSX.Element[]
}
export const DataContextProvider = ({ children }: DataContextProviderType) => {
   const [state, setRootState] = useState<IRootState>(GetInitialRootState())

   const dataContextValue: IDataContext = {
      state,
      setRootState,
      handleMessage,
   }
   const { accounts } = useMsal()
   useEffect(() => {
      ;(async () => {
         try {
            const msalResponse = await msalInstance.acquireTokenSilent({
               scopes: ['offline_access', 'openid', 'profile', 'api://739ad14b-969e-49af-9d6f-6f68a6edb67b/api.scope'],
               account: accounts[0],
            })
            setRootState((prev) => ({ ...prev, accessToken: msalResponse.accessToken }))
         } catch (error: any) {
            handleMessage(dataContextValue.setRootState, 'error', t('SomethingWentWrong'), error.message)
         }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [accounts])

   useEffect(() => {
      if (state.accessToken) {
         ;(async () => {
            try {
               dataContextValue.setRootState((prev) => ({ ...prev, initialLoading: true }))

               const userAccount: UserAccount = state.userAccount ?? (await api(state.accessToken).getUserAccountByUserId(accounts[0].localAccountId)).data
               let organisation: Organisation = state.organisation ?? null
               let branch: Branch = null
               let protectedAssets: ProtectedAsset[] = []
               let userAccounts: UserAccount[] = []
               if (userAccount && userAccount.organisationId) {
                  organisation = (await api(state.accessToken).getOrganisationById(userAccount.organisationId)).data
                  branch = (await api(state.accessToken).getBranchesByOrganisationId(userAccount.organisationId)).data[0]
                  protectedAssets = (await api(state.accessToken).getProtectedAssetsByOrganisationId(userAccount.organisationId)).data
                  userAccounts = (await api(state.accessToken).getUserAccountsByOrganisationId(userAccount.organisationId)).data
                  setRootState((prev) => ({
                     ...prev,
                     userAccount,
                     organisation,
                     branch,
                     protectedAssets,
                     userAccounts,
                  }))
               } else if (userAccount) {
                  setRootState((prev) => ({ ...prev, userAccount }))
               } else {
                  const newUserAccount: UserAccount = {
                     email: accounts[0].username,
                     user: { guid: accounts[0].localAccountId },
                     createdBy: accounts[0].name,
                     modifiedBy: accounts[0].name,
                  }
                  const createdUserAccount: UserAccount = (await api(state.accessToken).addUserAccount(newUserAccount)).data

                  setRootState((prev) => ({
                     ...prev,
                     userAccount: createdUserAccount,
                  }))
               }
            } catch (error: any) {
               handleMessage(dataContextValue.setRootState, 'error', t('SomethingWentWrong'), error.message)
            } finally {
               dataContextValue.setRootState((prev) => ({ ...prev, initialLoading: false }))
            }
         })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [state.accessToken, state.userAccount])

   return <DataContext.Provider value={dataContextValue}>{children}</DataContext.Provider>
}
