/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { ActionPlan } from '../../../api/schemas/schema'
import { useSearchParams } from 'react-router-dom'
import { Text } from '../../common/text/Text'
import {
   filterDemandActionPlansByProcess,
   findQuestionParentToActionPlan,
   getActionPlanColumns,
   getActionPlanSummaryFields,
   groupActionPlans,
   submitDemandActionPlan,
} from '../../../helpers/actionPlanHelper'
import { makeStyles, MessageBar, MessageBarBody, MessageBarTitle, tokens } from '@fluentui/react-components'
import { ModalActionPlan } from './ModalActionPlan'
import { BadgeActionPlanStatus } from '../BadgeActionPlanStatus'
import { t } from '../../../i18n/i18n'
import { IAnalysisState } from '../../../interfaces/IAnalysisState'
import { RTFOutput } from '../../common/outputs/RTFOutput'
import { GroupedList } from '../../common/list/GroupedList'
import { ActionPlanHeader } from './ActionPlanHeader'
import { ParentQuestionInformation } from './modalContent/ParentQuestionInformation'
import { DataContext } from '../../../context/DataContext'

const useStyles = makeStyles({
   phoneListWrapper: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'flex',
         flexDirection: 'column',
         gap: tokens.spacingVerticalXXL,
      },
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   flex: {
      display: 'flex',
      gap: tokens.spacingHorizontalL,
      alignItems: 'center',
   },
   phoneListItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalS,
      '& p': {
         margin: '0 auto',
      },
   },
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   row: {
      '& >': {
         ':nth-child(1)': {
            width: '5%',
            minWidth: '10px',
         },
         ':nth-child(2)': {
            width: '20%',
            minWidth: '220px',
         },
         ':nth-child(3)': {
            width: '100%',
         },
         ':nth-child(4)': {
            width: '20%',
            minWidth: '180px',
         },
         ':nth-child(5)': {
            width: '10%',
            minWidth: '100px',
         },
      },
   },
})
type ActionPlansProps = {
   setAnalysisState: React.Dispatch<React.SetStateAction<IAnalysisState>>
   analysisState: IAnalysisState
}
export const ActionPlans = ({ setAnalysisState, analysisState }: ActionPlansProps) => {
   const { item: analysis } = analysisState
   const classes = useStyles()
   const processes: string[] = [t('AllProcesses')]
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedProcess = searchParams.get('process')
   const [filteredItems, setFilteredItems] = useState<ActionPlan[]>(filterDemandActionPlansByProcess(analysis.actionPlans, analysis.questions, selectedProcess))
   const [selectedItem, setSelectedItem] = useState<ActionPlan>(undefined)
   const dataContext = useContext(DataContext)

   const setProcess = (process: string) => {
      searchParams.set('process', process)
      setSearchParams(searchParams)
   }
   useEffect(() => {
      setFilteredItems(filterDemandActionPlansByProcess(analysis.actionPlans, analysis.questions, selectedProcess))
   }, [selectedProcess, JSON.stringify(analysis.actionPlans)])
   useEffect(() => {
      if (searchParams.get('process') === null || !processes.find((x) => x === searchParams.get('process'))) {
         setProcess(processes[0])
      }
   }, [window.location.search])

   analysis.actionPlans.forEach((x) => {
      analysis.questions
         .find((q) => q.id === x.demandAnalysisQuestionId)
         .processes.forEach((p) => {
            if (!processes.some((c) => c === p)) {
               processes.push(p)
            }
         })
   })
   const onRowClick = (item: ActionPlan) => {
      setSelectedItem(item)
   }
   const onSubmit = async (data: ActionPlan) => {
      const parent = findQuestionParentToActionPlan(analysis.questions, data)
      return await submitDemandActionPlan(data, dataContext, setAnalysisState, parent)
   }
   return (
      <article>
         {analysis.actionPlans.length > 0 ? (
            <>
               <ActionPlanHeader
                  analysis={analysis}
                  filteredActionPlans={filteredItems}
                  setProcess={setProcess}
                  processes={processes}
                  selectedProcess={selectedProcess}
               />
               <GroupedList
                  columns={getActionPlanColumns(analysis.questions)}
                  groups={groupActionPlans(filteredItems, analysis.questions)}
                  onRowClick={onRowClick}
                  summaryFields={getActionPlanSummaryFields(filteredItems, analysis.questions)}
                  renderPhoneRow={(item) => {
                     const parent = findQuestionParentToActionPlan(analysis.questions, item)
                     return (
                        <div key={item.id} className={classes.phoneListItem} onClick={() => onRowClick(item)}>
                           <div className={classes.spaceBtw}>
                              <span className={classes.flex}>
                                 <Text weight="semibold">{parent.number}</Text>
                                 <Text>{parent.processes}</Text>
                              </span>
                              <span className={classes.flex}>
                                 <BadgeActionPlanStatus status={item.status} />
                              </span>
                           </div>
                           <RTFOutput text={parent.text} ellipsis />
                        </div>
                     )
                  }}
               />
            </>
         ) : (
            <MessageBar intent={'info'}>
               <MessageBarBody>
                  <MessageBarTitle>
                     {analysis.questions.every((x) => x.answer && x.answer.actionRequired === false)
                        ? t('NoActionPlansRequiredForThisItem', { Item: t('DemandAssessment').toLowerCase() })
                        : t('NoActionPlansCreated')}
                  </MessageBarTitle>
                  {analysis.questions.every((x) => x.answer && x.answer.actionRequired === false) && t('DemandAssessmentCanBeCompleted')}
               </MessageBarBody>
            </MessageBar>
         )}

         <ModalActionPlan
            parentInformation={
               <ParentQuestionInformation parent={selectedItem && analysis.questions.find((x) => x.id === selectedItem.demandAnalysisQuestionId)} />
            }
            onSubmit={onSubmit}
            selectedItem={selectedItem}
            close={() => {
               setSelectedItem(undefined)
            }}
            analysisDisabled={analysisState.disabled}
         />
      </article>
   )
}
