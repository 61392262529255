import React from 'react'
import { DialogContent, DialogTitle } from '@fluentui/react-components'
import { Organisation } from '../../api/schemas/schema'
import { UseFormReturn } from 'react-hook-form'
import { GridItem } from '../common/grid/GridItem'
import { Grid } from '../common/grid/Grid'
import { DialogActionButtons, DialogBody } from '../common/modal/Modal'
import { t } from '../../i18n/i18n'
import { Title } from '../common/text/Title'
import { InvoiceDetailsForm } from '../organisation/InoiceDetailsForm'

type InvocingStepProps = {
   prevStep?: () => void
   dialogTitleClass: string
   dialogContentClass: string
   organisationForm: UseFormReturn<Organisation, any, undefined>
   onOrganisationSubmit?: (data: Organisation) => Promise<void>
}
export const InvoicingStep = ({
   prevStep,
   onOrganisationSubmit,
   dialogTitleClass,
   dialogContentClass,
   organisationForm,
}: InvocingStepProps) => {
   const { handleSubmit, formState:{isSubmitting} } = organisationForm

   return (
      <form onSubmit={handleSubmit(onOrganisationSubmit)}>
         <DialogBody>
            <DialogTitle action="" as="h3" className={dialogTitleClass}>
               {t('Invocing')}
            </DialogTitle>
            <DialogContent className={dialogContentClass}>
               <Grid gap="L">
                  <GridItem size="12/12">
                     <Title as="h4">{t('BillingData')}</Title>
                  </GridItem>
                  <InvoiceDetailsForm organisationForm={organisationForm} />
               </Grid>
            </DialogContent>
            <DialogActionButtons primaryButtonText={t('Next')} secondaryButtonText={t('Back')} secondaryClick={prevStep} isSubmitting={isSubmitting}/>
         </DialogBody>
      </form>
   )
}
