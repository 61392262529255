import { Api } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { getFullName } from './accountHelper'
import { handleMessage } from './stateHelper'

export const api = (accessToken: string) => {
   return new Api({ baseUrl: process.env.REACT_APP_API_URL, baseApiParams: { headers: { Authorization: `Bearer ${accessToken}` } } }).api
}

export const skipStartModal = async (dataContext: IDataContext) => {
   try {
      const { accessToken, userAccount } = dataContext.state
      const { setRootState } = dataContext
      const organisationSizes = (await api(accessToken).getAllOrganisationSizes()).data
      const organisationTypes = (await api(accessToken).getAllOrganisationTypes()).data
      const processes = (await api(accessToken).getAllProcesss()).data
      const snis = (await api(accessToken).getAllSnis()).data

      const organisation = (
         await api(accessToken).addOrganisation({
            country: 'Sverige',
            county: 'Stockholm',
            email: 'jane@marabou.se',
            invoiceAddress: 'Stockholmsgatan 123',
            invoiceReference: 'Jane Doe',
            name: 'Marabou',
            organisationNumber: '112233-4455',
            organisationSize: organisationSizes[0],
            organisationSizeId: organisationSizes[0].id,
            organisationType: organisationTypes[0],
            organisationTypeId: organisationTypes[0].id,
            phoneNumber: '0700000000',
            postalCode: 12312,
            snIs: snis.filter((x) => x.department.charAt(0) === 'B'),
            createdBy: getFullName(userAccount),
            modifiedBy: getFullName(userAccount),
         })
      ).data

      const branch = (
         await api(accessToken).addBranch(organisation.id,{
            name: 'Hela verksamheten',

            createdBy: getFullName(userAccount),
            modifiedBy: getFullName(userAccount),
            organisationSize: organisation.organisationSize,
            organisationType: organisation.organisationType,
            snIs: organisation.snIs.map((x) => ({ branchId: undefined, id: x.id, sni: x.sni, sniId: x.sniId })),
            organisationId: organisation.id,
            organisationSizeId: organisation.organisationSizeId,
            organisationTypeId: organisation.organisationTypeId,
            address: 'Stockholmsgatan 123',
            county: 'Stockholm',
            description: 'Hela verksamheten och alla företagets anställda',
            postalCode: 12312,
            responsible: 'Jane Doe',
         })
      ).data
      const protectedAssets = (
         await api(accessToken).addProtectedAsset({
            branchId: branch.id,
            createdBy: getFullName(userAccount),
            modifiedBy: getFullName(userAccount),
            organisationId: organisation.id,
            description: 'Chokladfontänen i marabous huvudkvarter',
            motivation: 'Om fontänen försvinner försvinner Marabous kärna',
            name: 'Chokladfontänen',
            processes: processes,
         })
      ).data
      userAccount.organisationId = organisation.id
      const updatedAccount = (await api(accessToken).updateUserAccount(userAccount)).data
      setRootState((prev) => ({
         ...prev,
         userAccount: updatedAccount,
         organisation,
         branch,
         protectedAssets: [protectedAssets],
      }))
      handleMessage(dataContext.setRootState, 'success', 'Data seedad', 'Organisation, verksamhet och skyddsvärda tillgångar är skapade åt dig')
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', 'Något gick fel', 'Det gick inte att seeda data åt dig')
   }
}
