import React from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { IDropdownOption } from '../../../interfaces/IDropdownOptions'
import { Dropdown, Option } from '@fluentui/react-components'
import { t } from 'i18next'
import { Organisation } from '../../../api/schemas/schema'
import { Field } from './Field'

type ControlledDropdownProps = {
   name: any
   control: Control<any>
   label: string
   options: IDropdownOption[]
   disabled?: boolean
   required?: boolean
   defaultValue?: string
   setValue: UseFormSetValue<Organisation>
}
export const ControlledOrgDetailsDropDown = ({ name, control, label, options, disabled, required, defaultValue, setValue }: ControlledDropdownProps) => {
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { value, onChange }, fieldState: { error } }) => {
            const selectedOption = typeof value === 'object' ? value : options.find((x) => x.key === value)
            return (
               <Field
                  label={label}
                  required={required ?? false}
                  validationMessage={error ? error.message : null}
                  validationState={error ? 'error' : null}
               >
                  <Dropdown
                     value={selectedOption?.text || defaultValue || ''}
                     onOptionSelect={(e, d) => {
                        const selectedObject = options.find((x) => String(x.key) === d.optionValue)
                        if (selectedObject) {
                           onChange(selectedObject)
                           setValue(`${name}Id` as keyof Organisation, selectedObject.key)
                        }
                     }}
                     selectedOptions={selectedOption ? [String(selectedOption.key)] : []}
                     disabled={disabled ?? false}
                  >
                     {options.map((x) => (
                        <Option key={x.key} value={x.key.toString()} disabled={x.disabled || false}>
                           {x.text}
                        </Option>
                     ))}
                  </Dropdown>
               </Field>
            )
         }}
      />
   )
}
