import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ActionPlanStatus, DemandAnalysisQuestionStatus, DemandAnalysisStatus } from '../api/schemas/schema'
import { Layout } from './Layout'
import { Loader } from '../components/common/spinner/Loader'
import { Button, SelectTabData, Tab, TabList, makeStyles, mergeClasses } from '@fluentui/react-components'
import { t } from '../i18n/i18n'
import {
   BookInformationRegular,
   BookInformationFilled,
   TaskListSquareLtrFilled,
   TaskListSquareLtrRegular,
   ClipboardSearchFilled,
   ClipboardSearchRegular,
} from '@fluentui/react-icons'
import { Information } from '../components/demandAnalysis/information/Information'
import { handleMessage } from '../helpers/stateHelper'
import { LockClosed24Regular } from '@fluentui/react-icons'
import { DemandAssessmentTabs, getDemandAnalysisTitle } from '../helpers/demandAssessmentHelper'
import { api } from '../helpers/apiHelper'
import { CurrentDemandAnalysis } from '../components/demandAnalysis/demandAnalysis/DemandAnalysis'
import { ActionPlans } from '../components/demandAnalysis/actionPlan/ActionPlans'
import { Navigation } from '../components/common/navigation/Navigation'
import { BaseNavLinks } from '../components/common/navigation/BaseNavLinks'
import { DemandAssessmentNavLinks } from '../components/common/navigation/DemandAssessmentNavLinks'
import { DataContext } from '../context/DataContext'
import { IAnalysisState } from '../interfaces/IAnalysisState'
import { ModalCompleteDemandAssessment } from '../components/demandAnalysis/ModalCompleteDemandAssessment'

const useStyles = makeStyles({
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
   },
   flexOnlyLarge: {
      '@media screen and (max-width: 1007px)': { display: 'none' },
   },
})
export const SelectedDemandAssessment = () => {
   const [analysisState, setAnalysisState] = useState<IAnalysisState>({ disabled: false, loading: true, error: null, item: null })
   const { loading, error, item } = analysisState
   const dataContext = useContext(DataContext)
   const { accessToken } = dataContext.state
   const { id } = useParams()

   const prevId = useRef({})
   const navigate = useNavigate()
   const classes = useStyles()
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')
   const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false)

   useEffect(() => {
      const fetchData = async () => {
         try {
            const demandAnalysis = (await api(accessToken).getDemandAnalysisById(id, dataContext.state.organisation.id)).data
            setAnalysisState((prev) => ({ ...prev, loading: false, item: demandAnalysis, disabled: demandAnalysis.status === DemandAnalysisStatus.Completed }))
         } catch (error: any) {
            setAnalysisState((prev) => ({ ...prev, loading: false, error: { message: error.error.message, name: error.status } }))
            navigate('/compliance')
            handleMessage(dataContext.setRootState, 'error', t('FetchFailed'), t('CouldNotFetchItem', { Item: t('DemandAssessment').toLowerCase() }))
         }
      }
      if (prevId.current !== id) {
         prevId.current = id
         fetchData()
      }

      if (analysisState.item && analysisState.item.organisationId !== dataContext.state.organisation.id) {
         navigate('/compliance')
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id, dataContext.state.userAccount])
   useEffect(() => {
      if (searchParams.get('tab') === null) {
         setSearchParams((prev) => ({ ...prev, tab: DemandAssessmentTabs.Info }))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchParams])
   const completeBtnDisabled =
      analysisState.loading ||
      !analysisState.item.questions.every((x) => x.status === DemandAnalysisQuestionStatus.Determined) ||
      !analysisState.item.actionPlans.every((x) => x.status === ActionPlanStatus.Decided || x.status === ActionPlanStatus.NotToBeAdressed) ||
      analysisState.disabled
   return (
      <Layout
         title={getDemandAnalysisTitle(selectedTab)}
         breadCrumbItems={[
            { path: '/compliance', active: false, title: t('ComplianceOfDemands') },
            { path: `/compliance/${item?.id}`, active: true, title: item?.name },
         ]}
      >
         <Navigation
            desktopNavLinks={<BaseNavLinks active="/compliance" />}
            phoneNavLinks={
               <DemandAssessmentNavLinks
                  id={analysisState?.item?.id ?? ''}
                  active={selectedTab}
                  completeDemandAnalysisTab={<Tab value={'complete'} icon={<LockClosed24Regular />} disabled={completeBtnDisabled} />}
               />
            }
            activeDesktopTab="/compliance"
            activePhoneTab={`/compliance/${analysisState?.item?.id}?tab=${selectedTab}`}
            customOnTabSelect={(e, data: SelectTabData) => {
               if (data.value === 'complete') setCompleteModalOpen(true)
               else navigate(data.value)
            }}
         />
         {loading ? (
            <Loader />
         ) : error ? (
            <>{t('CouldNotFetchItem', { Item: t('DemandAssessment').toLowerCase() })}</>
         ) : (
            <>
               <header className={mergeClasses(classes.header, classes.flexOnlyLarge)}>
                  <TabList
                     defaultSelectedValue={'info'}
                     selectedValue={selectedTab}
                     onTabSelect={(e, data: SelectTabData) => setSearchParams({ tab: data.value as string })}
                  >
                     <Tab
                        value={DemandAssessmentTabs.Info}
                        icon={selectedTab === DemandAssessmentTabs.Info ? <BookInformationFilled /> : <BookInformationRegular />}
                     >
                        {t('Information')}
                     </Tab>
                     <Tab
                        value={DemandAssessmentTabs.DemandAnalysis}
                        icon={selectedTab === DemandAssessmentTabs.DemandAnalysis ? <ClipboardSearchFilled /> : <ClipboardSearchRegular />}
                     >
                        {t('DemandAnalysis')}
                     </Tab>
                     <Tab
                        value={DemandAssessmentTabs.ActionPlan}
                        icon={selectedTab === DemandAssessmentTabs.ActionPlan ? <TaskListSquareLtrFilled /> : <TaskListSquareLtrRegular />}
                     >
                        {t('ActionPlan')}
                     </Tab>
                  </TabList>
                  {item.status === DemandAnalysisStatus.Ongoing && (
                     <Button appearance="transparent" icon={<LockClosed24Regular />} onClick={() => setCompleteModalOpen(true)} disabled={completeBtnDisabled}>
                        {t('CompleteDemandAssessment')}
                     </Button>
                  )}
               </header>
               {selectedTab === DemandAssessmentTabs.Info && <Information analysis={item} setAnalysisState={setAnalysisState} />}
               {selectedTab === DemandAssessmentTabs.DemandAnalysis && (
                  <CurrentDemandAnalysis analysisState={analysisState} setAnalysisState={setAnalysisState} />
               )}
               {selectedTab === DemandAssessmentTabs.ActionPlan && <ActionPlans analysisState={analysisState} setAnalysisState={setAnalysisState} />}
               <ModalCompleteDemandAssessment
                  setAnalysisState={setAnalysisState}
                  analysisState={analysisState}
                  close={() => setCompleteModalOpen(false)}
                  open={completeModalOpen}
               />
            </>
         )}
      </Layout>
   )
}
