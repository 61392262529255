import { tokens } from '@fluentui/react-components'
import React from 'react'
type RTFOutputProps = {
   text: string
   fontSize?: 300 | 400
   noMargin?: boolean
   ellipsis?: boolean
}
export const RTFOutput = ({ text, fontSize, noMargin, ellipsis }: RTFOutputProps) => {
   const textStyle = `"line-height:${fontSize === 300 ? tokens.lineHeightBase300 : tokens.lineHeightBase400};font-size:${
      fontSize === 300 ? tokens.fontSizeBase300 : tokens.fontSizeBase400
   };font-weight:${tokens.fontWeightRegular};${noMargin ? 'margin:0;' : ''}"`

   text = `<p style=${textStyle}>${text}</p>`
   text = text.replace(/<p>/g, `<p style=${textStyle}>`)
   text = text.replace(/<li>/g, `<li style=${textStyle}>`)
   text = text.replace(/<a /g, `<a style="color:${tokens.colorBrandForegroundLink};"`)

   return (
      <div
         style={
            ellipsis
               ? {
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal',
                 }
               : {}
         }
         dangerouslySetInnerHTML={{ __html: text }}
      />
   )
}
