import React from 'react'
import { t } from '../i18n/i18n'
import { ActionPlanStatus, DemandAnalysisQuestionStatus, RiskAnalysisType } from '../api/schemas/schema'
import { Option } from '@fluentui/react-components'
import { IDropdownOption } from '../interfaces/IDropdownOptions'
import { ILanguage } from '../interfaces/ILanguage'
import { TOptions } from 'i18next'

export const demandAnalysisQuestionStausString = (status: DemandAnalysisQuestionStatus) => {
   switch (status) {
      case DemandAnalysisQuestionStatus.NotAnswered:
         return t('NotAnswered')
      case DemandAnalysisQuestionStatus.Answered:
         return t('Answered')
      case DemandAnalysisQuestionStatus.Determined:
         return t('Determined')
   }
}

export const actionPlanStatusString = (status: ActionPlanStatus) => {
   switch (status) {
      case ActionPlanStatus.NotDecided:
         return t('NotDecided')
      case ActionPlanStatus.NotToBeAdressed:
         return t('NotToBeAdressed')
      case ActionPlanStatus.Decided:
         return t('Decided')
      // case ActionPlanStatus.Completed:
      //    return t('Completed')
      // case ActionPlanStatus.NotStarted:
      //    return t('NotStarted')
      // case ActionPlanStatus.Ongoing:
      //    return t('Ongoing')
      // case ActionPlanStatus.Determined:
      //    return t('Determined')
   }
}
export const getRiskAnalysisTypeString = (value: RiskAnalysisType): string => {
   switch (value) {
      // case RiskAnalysisType.FRA:
      //    return t('SimplifiedRiskAnalysis')
      case RiskAnalysisType.SWOT:
         return t('SWOTAnalysis')
   }
}
export const riskAnalysisTypeToOptions = () => {
   const options: JSX.Element[] = []
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   for (const [key, value] of Object.entries(RiskAnalysisType)
      .filter(([key, value]) => typeof value === 'number')
      .sort(([aKey, aValue], [bKey, bValue]) =>
         getRiskAnalysisTypeString(aValue as RiskAnalysisType).localeCompare(getRiskAnalysisTypeString(bValue as RiskAnalysisType))
      )) {
      options.push(<Option value={value.toString()}>{getRiskAnalysisTypeString(value as RiskAnalysisType)}</Option>)
   }

   return options
}

export const enumValuesToOptions = <T extends {}>(enumObj: T): IDropdownOption[] => {
   const options = []
   for (const [key, value] of Object.entries(enumObj)) {
      if (isNaN(Number(value))) {
         options.push({ key: Number(key), text: `${t(value as keyof ILanguage)}` })
      }
   }
   return options
}

export const enumValuesToTranslate = <T extends {}>(enumObj: T, key: number, tOptions?: TOptions): string => {
   const value = Object.entries(enumObj).find((x) => x[1] === key)?.[0]
   return value ? t(value as keyof ILanguage) : ''
}
