import { Checkbox, CheckboxOnChangeData } from '@fluentui/react-components'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

type ControlledCheckboxProps = {
   name: any
   control: Control<any>
   label: string
   onChange?: (e: React.ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => void
   checked?: boolean
   labelPosition?: 'before' | 'after'
   disabled?: boolean
}
export const ControlledCheckbox = ({ name, control, label, onChange: onCheckChange, checked, labelPosition, disabled}: ControlledCheckboxProps) => {
   return (
      <Controller
         control={control}
         name={name}
         render={({ field: { value, onChange }, formState: { disabled: formDisabled } }) => (
            <Checkbox
               labelPosition={labelPosition ?? 'after'}
               size="large"
               label={label}
               checked={checked ?? value}
               disabled={(disabled || formDisabled) ?? false}
               onChange={(e, data) => {
                  if (onCheckChange) {
                     onCheckChange(e, data)
                  } else onChange(data.checked)
               }}
            />
         )}
      />
   )
}
