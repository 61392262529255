import React from 'react'
import { List } from '../../common/list/List'
import { t } from '../../../i18n/i18n'
import { getDemandAssessmentStatusColumns } from '../../../helpers/demandAssessmentHelper'
import { IAnalysisStatusData } from '../../../interfaces/IAnalysisStatusData'
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { Text } from '../../common/text/Text'
import { LockClosed20Regular, Subtract20Regular, ArrowClockwise20Regular } from '@fluentui/react-icons'
import { Avatar } from '../../common/avatar/Avatar'
import { Badge } from '../../common/badge/Badge'

type QuestionStatusListProps = {
   analysisStatusData: IAnalysisStatusData[]
}
const useStyles = makeStyles({
   noneInPhone: {
      '@media screen and (max-width: 1007px)': { display: 'none' },
   },
   onlyInPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': { display: 'block' },
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   column: {
      display: 'flex',
      flexDirection: 'column',

      ...shorthands.gap(tokens.spacingVerticalS),
   },
   mobileContentWrapper: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
         flexDirection: 'column',
         ...shorthands.gap(tokens.spacingVerticalXXL),
      },
   },
   contentEnd: {
      alignItems: 'end',
   },
})
export const QuestionStatusList = ({ analysisStatusData }: QuestionStatusListProps) => {
   const classes = useStyles()
   return (
      <>
         <article className={mergeClasses(classes.mobileContentWrapper)}>
            {analysisStatusData.map((x) => (
               <div key={x.process} className={classes.spaceBtw}>
                  <span className={classes.column}>
                     <Text style={{ margin: '0' }} weight="bold">
                        {x.process}
                     </Text>
                     <Avatar name={x.processOwner} />
                  </span>
                  <span className={mergeClasses(classes.column, classes.contentEnd)}>
                     <Badge
                        appearance="tint"
                        style={{ minWidth: '40px' }}
                        icon={
                           x.status === t('Completed') ? (
                              <LockClosed20Regular />
                           ) : x.status === t('Started') ? (
                              <ArrowClockwise20Regular />
                           ) : (
                              <Subtract20Regular />
                           )
                        }
                        color={x.status === t('Completed') ? 'informative' : x.status === t('Started') ? 'warning' : 'danger'}
                     />
                     <Text style={{ margin: '0' }}>{`${x.numberOfAnsweredQuestions}/${x.numberOfTotalQuestions}`}</Text>
                  </span>
               </div>
            ))}
         </article>

         <List items={analysisStatusData} columns={getDemandAssessmentStatusColumns()} className={classes.noneInPhone} />
      </>
   )
}
