import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, DialogTrigger, Divider, makeStyles, Tab, TabList, tokens } from '@fluentui/react-components'
import { DataUsage24Filled, DataUsage24Regular, Dismiss24Regular, List24Filled, List24Regular, Status24Regular } from '@fluentui/react-icons'
import { DialogSurface, DialogBody } from '../../common/modal/Modal'
import { t } from '../../../i18n/i18n'
import { QuestionStatusList } from './QuestionStatusList'
import { QuestionStatusChart } from './QuestionStatusChart'
import { DemandAnalysisQuestion, DemandAnalysisQuestionStatus, ProcessOwner } from '../../../api/schemas/schema'
import { Text } from '../../common/text/Text'
import { IAnalysisStatusData } from '../../../interfaces/IAnalysisStatusData'
import { getFullName } from '../../../helpers/accountHelper'
const enum ReportTabs {
   CHART,
   LIST,
}

const useStyles = makeStyles({
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media screen and (max-width: 1007px)': {
         position: 'sticky',
         left: 0,
         flexDirection: 'column',
         alignItems: 'start',
      },
   },
   dialogWidth: {
      width: 'auto',
      maxWidth: '1400px',
   },
   flex: {
      display: 'flex',
      gap: tokens.spacingHorizontalM,
   },
   showOnPhone: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'block',
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
})
type DemandAnalysisReportProps = {
   questions: DemandAnalysisQuestion[]
   processOwners: ProcessOwner[]
}
export const ModalAnalysisReport = ({ questions, processOwners }: DemandAnalysisReportProps) => {
   const classes = useStyles()
   const [selectedTab, setSelectedTab] = useState<ReportTabs>(ReportTabs.CHART)
   const [analysisStatusData, setAnalysisStatusData] = useState<IAnalysisStatusData[]>([])

   useEffect(() => {
      const data: IAnalysisStatusData[] = []
      const allProcesses: string[] = []
      questions.forEach((x) => {
         if (!allProcesses.find((z) => z === x.processes[0])) {
            allProcesses.push(x.processes[0])
         }
      })

      allProcesses.forEach((x) => {
         const processOwner = processOwners.find((po) => po.process.text === x)
         const relevantEntries = questions.filter((question) => question.processes.includes(x)) ?? []
         if (relevantEntries.length > 0) {
            let numberOfAnsweredQuestions = 0
            relevantEntries.forEach((question) => {
               if (question.status !== DemandAnalysisQuestionStatus.NotAnswered) numberOfAnsweredQuestions += 1
            })
            const status: string = relevantEntries.every((x) => x.status === DemandAnalysisQuestionStatus.Determined)
               ? t('Completed')
               : relevantEntries.some((x) => x.status === DemandAnalysisQuestionStatus.Answered)
               ? t('Started')
               : t('NotStarted')
            data.push({
               process: x,
               modified: relevantEntries.sort((a, b) => {
                  if (!a.modified && !b.modified) {
                     return 0
                  }
                  if (!a.modified) {
                     return 1
                  }
                  if (!b.modified) {
                     return -1
                  }
                  return new Date(b.modified).getTime() - new Date(a.modified).getTime()
               })[0].modified,
               numberOfAnsweredQuestions: numberOfAnsweredQuestions,
               numberOfTotalQuestions: relevantEntries.length,
               processOwner: processOwner.userAccount ? getFullName(processOwner.userAccount) : processOwner.ownerFreeText,
               status,
            })
         }
         setAnalysisStatusData(data.sort((a, b) => a.process.localeCompare(b.process)))
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [questions])
   return (
      <Dialog>
         <DialogTrigger>
            <span>
               <Button icon={<Status24Regular />} appearance="primary" className={classes.noneInPhone}>
                  {t('Report')}
               </Button>
               <Button icon={<Status24Regular />} appearance="transparent" className={classes.showOnPhone} />
            </span>
         </DialogTrigger>
         <DialogSurface className={classes.dialogWidth}>
            <DialogBody>
               <DialogTitle
                  action={
                     <DialogTrigger action="close">
                        <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
                     </DialogTrigger>
                  }
               >
                  {t('AnsweredQuestionReport')}
               </DialogTitle>
               <DialogContent>
                  <Divider appearance="brand" className={classes.noneInPhone} />
                  <header className={classes.header}>
                     <TabList selectedValue={selectedTab} onTabSelect={(e, d) => setSelectedTab(d.value as number)}>
                        <Tab icon={selectedTab === ReportTabs.CHART ? <DataUsage24Filled /> : <DataUsage24Regular />} value={ReportTabs.CHART}>
                           {t('Chart')}
                        </Tab>
                        <Tab icon={selectedTab === ReportTabs.LIST ? <List24Filled /> : <List24Regular />} value={ReportTabs.LIST}>
                           {t('List')}
                        </Tab>
                     </TabList>
                     <span className={classes.flex}>
                        <Text weight="bold">{t('AllAnswered')}</Text>
                        <Text>{`${questions.filter((x) => x.status !== DemandAnalysisQuestionStatus.NotAnswered).length} / ${questions.length}`}</Text>
                     </span>
                  </header>
                  {selectedTab === ReportTabs.CHART && <QuestionStatusChart analysisStatusData={analysisStatusData} />}
                  {selectedTab === ReportTabs.LIST && <QuestionStatusList analysisStatusData={analysisStatusData} />}
               </DialogContent>
            </DialogBody>
         </DialogSurface>
      </Dialog>
   )
}
