import React, { useContext, useState } from 'react'
import { ActionPlan, RiskAnalysis, RiskAnalysisStatus } from '../../../api/schemas/schema'
import {
   findSWOTParentToActionPlan,
   getGroupedSWOTActionPlans,
   getSWOTActionPlanColumns,
   getSWOTActionPlanSummaryFields,
   saveSWOTActionPlan,
} from '../../../helpers/swotHelper'
import { Card } from '../../common/card/Card'
import { t } from '../../../i18n/i18n'
import { Button, makeStyles, tokens, Text, MessageBar, MessageBarBody, MessageBarTitle } from '@fluentui/react-components'
import { PhoneHeader } from '../PhoneHeader'
import { ArrowExport20Regular } from '@fluentui/react-icons'
import { BadgeActionPlanStatus } from '../../demandAnalysis/BadgeActionPlanStatus'
import { formatToSwedishCrowns } from '../../../helpers/stringHelper'
import { GroupedList } from '../../common/list/GroupedList'
import { ModalActionPlan } from '../../demandAnalysis/actionPlan/ModalActionPlan'
import { ParentSWOTInformation } from './ParentSWOTInformation'
import { DataContext } from '../../../context/DataContext'
import { IRiskAnalysisState } from '../../../interfaces/IRiskAnalysisState'
import { exportSWOTActionPlansToExcel } from '../../../helpers/excelHelper'
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
   },
   ellipsis: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      WebkitLineClamp: 3,
   },
   phoneItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalM,
   },
   exportBtn: {
      float: 'right',
   },
})
type ActionPlansProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>
   analysis: RiskAnalysis
}
export const ActionPlans = ({ setRiskAnalysisState, analysis }: ActionPlansProps) => {
   const dataContext = useContext(DataContext)
   const { accessToken } = dataContext.state
   const classes = useStyles()
   const title = t('RiskManagementPlan')
   const [selectedItem, setSelectedItem] = useState<ActionPlan>(null)
   const saveActionPlan = async (data: ActionPlan) => {
      return await saveSWOTActionPlan(data, accessToken, analysis, setRiskAnalysisState)
   }
   return (
      <>
         <PhoneHeader title={title} />
         <Card className={classes.hideInPhone} transparentInPhone title={title}>
            <Button icon={<ArrowExport20Regular />} className={classes.exportBtn} onClick={() => exportSWOTActionPlansToExcel(analysis, analysis.actionPlans)}>
               {t('ExportToExcel')}
            </Button>
         </Card>
         {analysis.actionPlans.length > 0 ? (
            <GroupedList
               columns={getSWOTActionPlanColumns(analysis.swoTs)}
               groups={getGroupedSWOTActionPlans(analysis.swoTs, analysis.actionPlans)}
               onRowClick={(item) => setSelectedItem(item)}
               summaryFields={getSWOTActionPlanSummaryFields(analysis.actionPlans, analysis.swoTs)}
               renderPhoneRow={(item) => {
                  const swot = findSWOTParentToActionPlan(analysis.swoTs, item)
                  return (
                     <div className={classes.phoneItem}>
                        <span className={classes.spaceBtw}>
                           <Text size={400}>{swot.title}</Text>
                           <BadgeActionPlanStatus status={item.status} />
                        </span>
                        <Text size={300}>{swot.estimatedCost ? formatToSwedishCrowns(swot.estimatedCost) : null}</Text>
                        <Text size={300} className={classes.ellipsis}>
                           {swot.description}
                        </Text>
                     </div>
                  )
               }}
            />
         ) : (
            <MessageBar intent={'info'}>
               <MessageBarBody>
                  <MessageBarTitle>
                     {analysis.swoTs.every((x) => !x.actionRequired)
                        ? t('NoActionPlansRequiredForThisItem', { Item: t('RiskAnalysis').toLowerCase() })
                        : t('NoActionPlansCreated')}
                  </MessageBarTitle>
                  {analysis.swoTs.every((x) => !x.actionRequired) && t('RiskAssessmentCanBeCompleted')}
               </MessageBarBody>
            </MessageBar>
         )}
         <ModalActionPlan
            parentInformation={selectedItem && <ParentSWOTInformation swot={analysis.swoTs.find((x) => x.id === selectedItem.swotId)} swots={analysis.swoTs} />}
            selectedItem={selectedItem}
            close={() => {
               setSelectedItem(undefined)
            }}
            analysisDisabled={analysis.status === RiskAnalysisStatus.Completed}
            onSubmit={saveActionPlan}
         />
      </>
   )
}
