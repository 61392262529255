import React, { useState } from 'react'
import { Organisation, OrganisationSize, OrganisationType, SNI } from '../../api/schemas/schema'
import { DataContext } from '../../context/DataContext'
import { useContext, useEffect } from 'react'
import { Button, makeStyles, tokens, Text, Divider } from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { Modal, ModalActions } from '../common/modal/Modal'
import { t } from '../../i18n/i18n'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { useForm } from 'react-hook-form'
import { api } from '../../helpers/apiHelper'
import { IDropdownOption } from '../../interfaces/IDropdownOptions'
import { IOpenCloseModalProps } from '../../interfaces/IOpenCloseModalProps'
import { ControlledOrgDetailsDropDown } from '../common/inputs/ControlledOrgDetailsDropDown'
import { SniDropdown } from '../common/inputs/SniDropdown'
import { handleMessage } from '../../helpers/stateHelper'
import { OrganisationDetailsForm } from './OrganisationDetailsForm'

const useStyles = makeStyles({
   modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: tokens.spacingVerticalM,
   },
})

export const OrganisationDetails = ({ open, closeModal }: IOpenCloseModalProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { setRootState } = dataContext
   const { accessToken, organisation } = dataContext.state
   const organisationForm = useForm<Organisation>({})
   const { control, reset, setValue, handleSubmit } = organisationForm
   const [orgSizes, setOrgSizes] = useState<IDropdownOption[]>([])
   const [orgTypes, setOrgTypes] = useState<IDropdownOption[]>([])
   const [snis, setSnis] = useState<SNI[]>([])

   useEffect(() => {
      const fetchData = async () => {
         try {
            const orgSizeResponse = await api(accessToken).getAllOrganisationSizes()
            const orgTypesResponse = await api(accessToken).getAllOrganisationTypes()
            const allSnisResponse = await api(accessToken).getAllSnis()

            const sortedOrgSizes = orgSizeResponse.data.sort((a, b) => {
               const valueA = a.text.includes('+') ? Number(a.text.split('+')[0]) : Number(a.text.split('-')[0])
               const valueB = b.text.includes('+') ? Number(b.text.split('+')[0]) : Number(b.text.split('-')[0])
               return valueA - valueB
            })

            const mappedOrgSizes: IDropdownOption[] = sortedOrgSizes.map((size: OrganisationType) => ({
               key: size.id,
               text: size.text,
            }))

            const mappedOrgTypes: IDropdownOption[] = orgTypesResponse.data.map((type: OrganisationSize) => ({
               key: type.id,
               text: type.text,
            }))

            setOrgSizes(mappedOrgSizes)
            setOrgTypes(mappedOrgTypes)
            setSnis(allSnisResponse.data)
         } catch (error) {
            handleMessage(setRootState, 'error', t('SomethingWentWrong'), t('FetchFailed'))
         }
      }

      fetchData()
   }, [accessToken, organisation, setRootState])

   useEffect(() => {
      if (organisation) {
         reset(organisation)
      }
   }, [organisation, reset])

   const handleSaveClick = handleSubmit(async (data: Organisation) => {
      try {
         const updatedOrganisation = await api(accessToken).updateOrganisation(data)
         setRootState((prev) => ({
            ...prev,
            organisation: updatedOrganisation.data,
         }))

         closeModal()
         handleMessage(setRootState, 'success', t('ItemSuccessfullySaved', { Item: t('OrganisationDetails') }))
      } catch (error) {
         handleMessage(setRootState, 'error', t('SomethingWentWrong'), t('CouldNotUpdateItem', { item: t('OrganisationDetails') }))
      }
   })

   const closeAndReset = () => {
      closeModal()
      reset(organisation)
   }
   return (
      <>
         <Modal open={open} title={null}>
            <div className={classes.modalHeader}>
               <Text role="heading" size={500} weight="semibold">
                  {t('OrganisationDetails')}
               </Text>
               <Button aria-label="Close" appearance="transparent" icon={<Dismiss24Regular />} onClick={closeAndReset} />
            </div>
            <form>
               <Grid gap="L">
                  <GridItem size={'12/12'}>
                     <Divider appearance="brand" />
                  </GridItem>
                  <OrganisationDetailsForm organisationForm={organisationForm} orgSizes={orgSizes} orgTypes={orgTypes} snis={snis} />
                  <GridItem size={'12/12'}>
                     <ModalActions>
                        <Button appearance="secondary" onClick={closeAndReset}>
                           {t('Cancel')}
                        </Button>
                        <Button appearance="primary" type="submit" onClick={handleSaveClick}>
                           {t('Save')}
                        </Button>
                     </ModalActions>
                  </GridItem>
               </Grid>
            </form>
         </Modal>
      </>
   )
}
