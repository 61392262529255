import React, { useContext, useEffect } from 'react'
import { DataContext } from '../../context/DataContext'
import { IOpenCloseModalProps } from '../../interfaces/IOpenCloseModalProps'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { useForm } from 'react-hook-form'
import { Organisation } from '../../api/schemas/schema'
import { t } from '../../i18n/i18n'
import { Modal, ModalActions } from '../common/modal/Modal'
import { Button, Divider, makeStyles, Text, tokens } from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { handleMessage } from '../../helpers/stateHelper'
import { api } from '../../helpers/apiHelper'
import { InvoiceDetailsForm } from './InoiceDetailsForm'

const useStyles = makeStyles({
   modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: tokens.spacingVerticalM,
   },
})

export const InvoiceDetails = ({ open, closeModal }: IOpenCloseModalProps) => {
   const dataContext = useContext(DataContext)
   const { setRootState } = dataContext
   const { accessToken, organisation } = dataContext.state
   const organisationForm = useForm<Organisation>({})
   const { reset, handleSubmit } = organisationForm
   const classes = useStyles()

   useEffect(() => {
      if (organisation) {
         reset(organisation)
      }
   }, [organisation, reset])

   const handleSaveClick = handleSubmit(async (data: Organisation) => {
      try {
         const updatedOrganisation = await api(accessToken).updateOrganisation(data)

         setRootState((prev) => ({
            ...prev,
            organisation: updatedOrganisation.data,
         }))

         closeModal()
         handleMessage(setRootState, 'success', t('ItemSuccessfullySaved', { Item: t('InvoiceDetails') }))
      } catch (error) {
         handleMessage(setRootState, 'error', t('SomethingWentWrong'), t('CouldNotUpdateItem', { item: t('InvoiceDetails') }))
      }
   })

   const closeAndReset = () => {
      closeModal()
      reset(organisation)
   }

   return (
      <Modal open={open} title={null}>
         <div className={classes.modalHeader}>
            <Text role="heading" size={500} weight="semibold">
               {t('InvoiceDetails')}
            </Text>
            <Button aria-label="Close" appearance="transparent" icon={<Dismiss24Regular />} onClick={closeAndReset} />
         </div>
         <form>
            <Grid gap="L">
               <GridItem size={'12/12'}>
                  <Divider appearance="brand" />
               </GridItem>
               <InvoiceDetailsForm organisationForm={organisationForm} />
               <GridItem size={'12/12'}>
                  <ModalActions>
                     <Button appearance="secondary" onClick={closeAndReset}>
                        {t('Cancel')}
                     </Button>
                     <Button appearance="primary" type="submit" onClick={handleSaveClick}>
                        {t('Save')}
                     </Button>
                  </ModalActions>
               </GridItem>
            </Grid>
         </form>
      </Modal>
   )
}
