import React from 'react'
import { Badge } from '../common/badge/Badge'
import { actionPlanStatusString } from '../../helpers/enumHelper'
import { ActionPlanStatus } from '../../api/schemas/schema'
import { makeStyles } from '@fluentui/react-components'
import { Subtract20Regular, Checkmark20Regular, DismissCircle20Regular } from '@fluentui/react-icons'

type BadgeActionPlanStatusProps = {
   status: ActionPlanStatus
}
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   showOnlyPhone: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'block',
      },
   },
})
export const BadgeActionPlanStatus = ({ status }: BadgeActionPlanStatusProps) => {
   const classes = useStyles()

   const getBgColor = (): 'brand' | 'danger' | 'important' | 'informative' | 'severe' | 'subtle' | 'success' | 'warning' => {
      switch (status) {
         case ActionPlanStatus.NotDecided:
            return 'danger'
         // case ActionPlanStatus.NotStarted:
         //    return 'warning'
         // case ActionPlanStatus.Ongoing:
         //    return 'warning'
         case ActionPlanStatus.Decided:
            return 'success'
         // case ActionPlanStatus.Determined:
         //    return 'informative'
         case ActionPlanStatus.NotToBeAdressed:
            return 'subtle'
      }
   }
   const getIcon = (): JSX.Element => {
      switch (status) {
         case ActionPlanStatus.NotDecided:
            return <Subtract20Regular />
         // case ActionPlanStatus.NotStarted:
         //    return <ArrowClockwiseDashes20Regular />
         // case ActionPlanStatus.Ongoing:
         //    return <ArrowClockwise20Regular />
         case ActionPlanStatus.Decided:
            return <Checkmark20Regular />
         // case ActionPlanStatus.Determined:
         //    return <LockClosed20Regular />
         case ActionPlanStatus.NotToBeAdressed:
            return <DismissCircle20Regular />
      }
   }
   return (
      <>
         <Badge className={classes.showOnlyPhone} appearance="tint" color={getBgColor()} icon={getIcon()} />
         <Badge
            className={classes.hideInPhone}
            color={getBgColor()}
            style={{
               backgroundColor: getBgColor(),
            }}
         >
            {actionPlanStatusString(status)}
         </Badge>
      </>
   )
}
