import { t } from 'i18next'
import React, { useContext } from 'react'
import { GridItem } from '../common/grid/GridItem'
import { ControlledOrgDetailsDropDown } from '../common/inputs/ControlledOrgDetailsDropDown'
import { SniDropdown } from '../common/inputs/SniDropdown'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { UseFormReturn } from 'react-hook-form'
import { Organisation, SNI } from '../../api/schemas/schema'
import { IDropdownOption } from '../../interfaces/IDropdownOptions'
import { DataContext } from '../../context/DataContext'

type OrganisationDetailsFormProps = {
   organisationForm: UseFormReturn<Organisation, any, undefined>
   orgSizes: IDropdownOption[]
   orgTypes: IDropdownOption[]
   snis: SNI[]
}

export const OrganisationDetailsForm = ({ organisationForm, orgSizes, orgTypes, snis }: OrganisationDetailsFormProps) => {
   const { control, setValue } = organisationForm
   // const dataContext = useContext(DataContext)
   // const {organisation} = dataContext.state

   return (
      <>
         <GridItem size={'6/12'}>
            <ControlledTextInput required control={control} name={'organisationNumber'} label={t('OrganisationNumber')} />
         </GridItem>
         <GridItem size={'6/12'}>
            <ControlledTextInput required control={control} name={'name'} label={t('CompanyName')} />
         </GridItem>
         <GridItem size={'6/12'}>
            <ControlledOrgDetailsDropDown
               required
               control={control}
               name={'organisationSize'}
               label={t('OrganisationSize')}
               options={orgSizes}
               // defaultValue={organisation.organisationSize.text}
               setValue={setValue}
            />
         </GridItem>
         <GridItem size={'6/12'}>
            <ControlledOrgDetailsDropDown
               required
               control={control}
               name={'organisationType'}
               label={t('OrganisationType')}
               options={orgTypes}
               // // defaultValue={organisation.organisationType.text}
               setValue={setValue}
            />
         </GridItem>
         <GridItem size={'12/12'}>
            <SniDropdown required organisationForm={organisationForm} label={t('SNI')} items={snis ? snis : []} />
         </GridItem>
      </>
   )
}
