import React from 'react'
import { Divider, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { Text } from '../text/Text'

const useStyles = makeStyles({
   fieldWrapper: {
      display: 'flex',
      gap: tokens.spacingHorizontalL,
      alignItems: 'center',
      '@media screen and (max-width: 640px)': {
         width: '100%',
         justifyContent: 'space-between',
         '& p': {
            margin: 0,
         },
      },
   },
   end: {
      justifyContent: 'end',
      gap: `calc(${tokens.spacingHorizontalXXXL} * 5)`,
   },
   summaryRow: {
      backgroundColor: tokens.colorNeutralBackground6,
      display: 'flex',
      gap: tokens.spacingHorizontalL,
      alignItems: 'center',
      padding: `0 ${tokens.spacingHorizontalXL}`,
      '@media screen and (max-width: 640px)': {
         flexDirection: 'column',
         padding: `${tokens.spacingHorizontalXL} ${tokens.spacingHorizontalXXXL}`,
         border: `1px solid ${tokens.colorNeutralStroke1}`,
      },
   },
   summaryRowWrapper: {
      margin: `${tokens.spacingHorizontalM} 0`,
   },
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
})
type SummaryRowProps = {
   summaryFields: { label: string; text: string | number }[]
   className?: string
}
export const SummaryRow = ({ summaryFields, className }: SummaryRowProps) => {
   const classes = useStyles()
   return (
      <div className={mergeClasses(className ?? '', classes.summaryRowWrapper)}>
         <Divider className={classes.hideInPhone} />
         <div className={mergeClasses(classes.end, classes.summaryRow)}>
            {summaryFields.map((summary) => (
               <div key={summary.label} className={mergeClasses(classes.fieldWrapper)}>
                  <Text weight="bold">{summary.label}</Text>
                  <Text>{summary.text}</Text>
               </div>
            ))}
         </div>
         <Divider appearance="strong" className={classes.hideInPhone} />
      </div>
   )
}
