import { t } from 'i18next'
import React, { useContext } from 'react'
import { GridItem } from '../common/grid/GridItem'
import { ControlledTextInput, TextInput } from '../common/inputs/TextInput'
import { UserAccount } from '../../api/schemas/schema'
import { UseFormReturn } from 'react-hook-form'
import { DataContext } from '../../context/DataContext'

type ModalAccountInformationProps = {
   userAccountForm: UseFormReturn<UserAccount, any, undefined>
}

export const ModalAccountInformationForm = ({ userAccountForm }: ModalAccountInformationProps) => {
   const dataContext = useContext(DataContext)
   const { organisation } = dataContext.state
   const { control, watch } = userAccountForm
   return (
      <>
         <GridItem size={'6/12'}>
            <ControlledTextInput required name={'firstName'} label={t('FirstName')} control={control} />
         </GridItem>
         <GridItem size={'6/12'}>
            <ControlledTextInput required name={'lastName'} label={t('LastName')} control={control} />
         </GridItem>
         <GridItem size={'6/12'}>
            <ControlledTextInput required name={'email'} label={t('Email')} control={control} type="email" />
         </GridItem>
         <GridItem size={'6/12'}>
            <ControlledTextInput name={'phoneNumber'} label={t('PhoneNumber')} control={control} type="tel" required />
         </GridItem>
         <GridItem size={'12/12'}>
            <TextInput disabled value={organisation ? organisation.name : ''} label={t('OrganizationalAffiliation')} />
         </GridItem>
         <GridItem size={'6/12'}>
            <ControlledTextInput name={'jobTitle'} label={t('JobTitle')} control={control} />
         </GridItem>
         <GridItem size={'6/12'}>
            <TextInput label={t('UserType')} value={watch('userType') ? watch('userType').name : ''} disabled />
         </GridItem>
         <GridItem size={'12/12'}>
            <TextInput
               label={t('Roles')}
               value={
                  watch('roles') && watch('roles').length > 0
                     ? watch('roles')
                          .map((r) => r.role.name)
                          .join(', ')
                     : ''
               }
               disabled
            />
         </GridItem>
      </>
   )
}
