import { ILanguage } from '../../interfaces/ILanguage'

export const sv: ILanguage = {
   '365Robust': '365Robust',
   Of: 'av',

   // Authentication
   WelcomeTo: 'Välkommen till',
   SignIn: 'Logga in',
   SignOut: 'Logga ut',
   Dashboard: 'Dashboard',
   NotAuthorized: 'Ej behörig',

   // Organisation
   OrganisationalData: 'Organisationsuppgifter',
   OrganisationNumber: 'Organisationsnummer',
   CompanyName: 'Företagsnamn',
   OrganisationSize: 'Organisationsstorlek',
   OrganisationType: 'Organisationsform',
   SNI: 'SNI',
   All: 'Alla',
   OrganisationDetails: 'Organisationsuppgifter',
   InvoiceDetails: 'Fakturauppgifter',
   Invocing: 'Fakturering',

   // User
   AccountInformation: 'Användaruppgifter',
   Account: 'Konto',
   FirstName: 'Förnamn',
   LastName: 'Efternamn',
   OrganizationalAffiliation: 'Organisationstillhörighet',
   JobTitle: 'Jobbtitel',
   UserType: 'Användartyp',
   Roles: 'Roller',

   // Button
   Next: 'Nästa',
   Add: 'Lägg till',
   Finish: 'Avsluta',
   Cancel: 'Avbryt',
   Continue: 'Fortsätt',
   Save: 'Spara',
   ReadMore: 'Läs mer',
   Close: 'Stäng',
   Complete: 'Fastställ',
   Determine: 'Fastställ',
   Back: 'Tillbaka',
   ExportToExcel: 'Exportera till excel',

   // Roles
   ProcessOwner: 'Processägare',
   Owner: 'Ägare',
   RiskOwner: 'Riskägare',
   AnalysisLeader: 'Analysledare',

   // Billing
   BillingData: 'Fakturauppgifter',
   InvoiceReference: 'Fakturareferens',
   InvoiceAddress: 'Fakturaadress',
   PostalCode: 'Postnummer',
   County: 'Postort',
   PhoneNumber: 'Telefonnummer',
   Email: 'E-post',

   // Branch
   Branch: 'Verksamhet',
   BranchName: 'Namn på verksamhet',
   PropertyDesignation: 'Fastighetsbeteckning',
   OrganisationForm: 'Organisationsform',
   SNICodes: 'SNI-koder',
   BranchDescription: 'Verksamhetsbeskrivning',
   BranchValuation: 'Värdering verksamhet',
   DateForValuation: 'Datum för värdering',
   BranchResponsible: 'Verksamhetsansvarig',
   VisitingAdress: 'Besöksaddress',
   KeyFigures: 'Nyckeltal',

   // ProtectedAssets
   ProtectedAssets: 'Skyddsvärda tillgångar',
   ProtectedAsset: 'Skyddsvärd tillgång',
   BranchAffiliation: 'Verksamhetstillhörighet',
   DescriptionOfAsset: 'Beskrivning av tillgång',
   Description: 'Beskrivning',
   ProtectedAssetValuation: 'Värdering av tillgång',
   AddedAssets: 'Tillagda tillgångar',
   NewDemandAssessment: 'Ny kravbedömning',
   OngoingDemandAssessments: 'Pågående kravbedömningar',
   ConcludedDemandAssessments: 'Avslutade kravbedömningar',
   AnalysisName: 'Analysnamn',
   AccessAffectsFollowingProcesses: 'Tillgång påverkar följande processer',
   MotivationAndStand: 'Motivering och ställningstagande',

   // StartUpWizard
   CheckAndVerifyOrganisationData: 'Kontrollera och komplettera organisationsuppgifter',
   CheckAndVerifyUserData: 'Kontrollera och komplettera användaruppgifterna',
   User: 'Användare',
   OrganisationAffiliation: 'Organisationstillhörighet',

   // ComplianceOfDemands
   ComplianceOfDemands: 'Kravefterlevnad',
   LeadershipPerspective: 'Ledningsperspektiv',
   Subject: 'Ämne',
   DataClassification: 'Informationsklassning',
   StartOfAssessment: 'Start av bedömnning',
   EndOfAssessment: 'Avslut av bedömning',
   Comment: 'Kommentar',
   CreateAssessment: 'Skapa bedömning',
   Process: 'Process',
   CreatingDemandAssessment: 'Skapar upp kravbedömning...',
   DemandAssessmentCreated: 'Din kravbedömning är nu skapad',
   ThisCanTakeAMoment: 'Detta kan ta en stund. Håll enheten öppen',
   ClickContinueToGoToAssessment: "Klicka på 'Fortsätt' för att öppna din bedömning",
   Information: 'Information',
   DemandAnalysis: 'Kravanalys',
   ActionPlan: 'Åtgärdsplan',
   DemandAssessment: 'Kravbedömning',
   BranchScope: 'Verksamhetsomfattning',
   CompleteDemandAssessment: 'Avsluta kravbedömning',
   CompleteDemandAssessmentConfirmationMessage: 'Är du säker på att du vill avsluta kravbedömningen? Det går inte att ångra sig när den har blivit avslutad.',
   AllAnswered: 'Besvarade',
   NoConcludedDemandAssessments: 'Det finns inga avslutade kravbedömningar',
   TimeToCreateYourFirstDemandAssessment: 'Dags att skapa din första kravbedömning',
   AllProcesses: 'Alla processer',
   'No.': 'Nr',
   Question: 'Fråga',
   CompleteDemandAnalysis: 'Fastställ kravanalys',
   CompleteDemandAnalysisConfirmationText:
      'Är du säker på att du vill fastställa kravanalysen för {{process}}? Svaret på frågorna kan ej ändras och åtgärdsplaner skapas upp.',
   EditDemandAssessment: 'Redigera kravbedömning',
   ProposedAction: 'Förslag till åtgärd',
   BackgroundInformationForTheQuestion: 'Bakgrundsfakta till frågan',
   BackgroundInformation: 'Bakgrundsfakta',
   TipFrom365Robust: '365Robust tipsar',
   OriginDemandForTheQuestion: 'Ursprungskrav till frågan',
   OriginDemand: 'Ursprungskrav',
   Source: 'Källa',
   DemandAssessmentCompleted: 'Kravbedömning avslutad',
   CouldNotCompleteDemandAssessment: 'Kunde ej avsluta kravbedömningen',
   CouldNotUpdateDemandAssessment: 'Kunde inte uppdatera kravbedömnignen',
   AssessmentName: 'Namn på bedömning',
   IncludeExtendedLevel: 'Inkludera utökad nivå',
   NoAvailableInformation: 'Det finns ingen tillgänglig information för tillfället',
   EstimatedCost: 'Uppskattad kostnad för åtgärd',
   EstimatedCostShort: 'Uppskattad kostnad',
   ProcessOwnersForDemandAssessment: 'Processägare för kravanalysen',
   ProcessOwnerForDemandAssessmentText:
      'Välj befintlig användare eller skriv in fritt namn. Aktiva användare kommer kunna besvara frågor. Namn utan användare kommer endast administratörer kunna besvara.',
   ExtendedLevel: 'Utökad nivå',
   NotIncluded: 'Ej inkluderat',
   Included: 'Inkluderat',
   ProcessWithProcessowner: 'Process med processägare',

   // Report
   Report: 'Rapport',
   AnsweredQuestionReport: 'Besvarade frågor - Rapport',
   AnsweredQuestions: 'Besvarade frågor',
   TotalNumber: 'Totalt antal',
   NumberOfAnswered: 'Antal besvarade',
   Chart: 'Diagram',
   List: 'Lista',

   // Actionplans
   NoActionPlansCreated: 'Kravanalysen per process måste fastställas innan en åtgärdsplan skapas för processen',
   NoActionPlansRequiredForThisItem: 'Inga åtgärder behöver vidtas i denna {{Item}}.',
   DemandAssessmentCanBeCompleted: 'Kravbedömningen kan nu avslutas',
   RiskAssessmentCanBeCompleted: 'Riskbedömningen kan nu avslutas',
   ComplianceLevel: 'Efterlevnadsnivå',
   DecisionOnAction: 'Beslut av åtgärd',
   NoActionShallBeTaken: 'Inga åtgärder ska vidtas',
   ResponsibleOfAction: 'Ansvarig för åtgärd',
   Priority: 'Prioritet',
   ID: 'ID',
   AnsweredBy: 'Besvarad av',
   StartAction: 'Påbörja åtgärd',
   CompleteAction: 'Avsluta åtgärd',
   ActionDecidedBy: 'Åtgärd beslutad av',
   NoActionsTaken: 'Inga åtgärder genomfördes',
   ImplementedActions: 'Genomförda åtgärder',
   ComplianceLevelAfterCompletedAction: 'Kravefterlevnad efter utförd åtgärd',
   SpecifyCompliance: 'Ange efterlevnad',
   CalculatedOutcome: 'Beräknat utfall',
   DecisionStatus: 'Beslutsstatus',
   DecidedMulti: 'Beslutade',
   TotalNumberOfActions: 'Totalt antal åtgärder',
   Deadline: 'Klart senast',
   PerProcessAndComplianceLevel: 'Per process & efterlevnadsnivå',
   PerComplianceLevel: 'Per efterlevnadsnivå',
   ActionPlanReport: 'Åtgärdsplan - Rapport',
   NumberOfActions: 'Antal åtgärder',

   // Status
   RequiredField: 'Vänligen, fyll i detta fält',
   SomethingWentWrong: 'Något gick fel',
   NoMatchingQuestonsFound: 'Inga matchande frågor hittades',
   FetchFailed: 'Hämtning misslyckades',
   ModifiedBy: 'Senast ändrad av',
   Modified: 'Senast ändrad',
   CreatedBy: 'Skapad av',
   CreatedDate: 'Skapad',
   ItemSuccessfullySaved: '{{Item}} sparades korrekt',
   ItemSuccessfullyUpdated: '{{Item}} uppdaterades korrekt',
   CouldNotFetchItem: '{{Item}} kunde inte hämtas',
   CouldNotCreateItem: '{{Item}} kunde inte skapas',
   CouldNotUpdateItem: '{{Item}} kunde inte uppdateras',
   Status: 'Status',
   NoDateSet: 'Inget datum satt',
   NoValueSet: 'Inget värde satt',
   ValidationFailed: 'Validering misslyckades',
   DemandAnalysisCompleted: 'Kravanalysen är fastställd',
   CouldNotCompleteDemandAnalysis: 'Något gick fel när kravanalysen skulle fastställas',
   NotAnswered: 'Ej besvarad',
   Answered: 'Besvarad',
   Determined: 'Fastställd',
   NotDecided: 'Ej beslutad',
   Decided: 'Beslutad',
   NotStarted: 'Ej påbörjad',
   Started: 'Påbörjad',
   Completed: 'Avslutad',
   NotToBeAdressed: 'Åtgärdas ej',
   ErrorMessage: 'Felmeddelande',
   NoDataCreatedYet: 'Ingen data skapad ännu.',
   Ongoing: 'Pågående',
   Concluded: 'Avslutade',
   EndDateMustBeAfterStartDate: 'Slutdatum måste vara efter startdatum.',
   AnAccountMustBeSelected: 'Ett konto måste väljas',

   // Riskmanagement:
   CreateAnalysis: 'Skapa analys',
   RiskAnalysis: 'Riskanalays',
   RiskManagement: 'Riskhantering',
   NewRiskAnalysis: 'Ny riskanalys',
   TypeOfRiskAnalysis: 'Typ av riskanalys',
   NameOfAnalysis: 'Namn på analys',
   'Purpose/Scope': 'Syfte/Omfattning',
   Limitations: 'Avgränsningar',
   StartOfAnalysis: 'Start av analys',
   EndOfAnalysis: 'Avslut av analys',
   SimplifiedRiskAnalysis: 'Förenklad riskanalys (FRA)',
   SimplifiedConsequenseProbabilityAnalysis: 'Förenklad konsekvens- och sannolikhetsanalys (F-KSA)',
   SWOTAnalysis: 'SWOT-analys',
   CompleteSWOTAnalysis: 'Fastställ analys',
   Type: 'Typ',
   NoConcludedRiskAnalyses: 'Det finns inga avslutade riskanalyser',
   TimeToCreateYourFirstRiskAnalysis: 'Dags att skapa din första riskanalys',
   OngoingRiskAnalyses: 'Pågående riskanalyser',
   ConcludedRiskAnalyses: 'Avslutade riskanalyser',
   CreatingRiskAnalysis: 'Skapar riskanalys',
   RiskAnalysisCreated: 'Din riskanalys är nu skapad',
   RiskIdentifying: 'Riskidentifiering',
   'RiskAnalysis/assessment': 'Riskanalys/värdering',
   AnalyseEvaluate: 'Analysera & värdera',
   Identify: 'Identifiera',
   RiskManagementPlan: 'Riskhanteringsplan',
   NewRisk: 'Ny risk',
   RiskScenario: 'Riskscenario',
   RiskSource: 'Riskkälla',
   IdentifyRisk: 'Identifiera risk',
   CurrentProtectionAndOrPotentialWeaknesses: 'Nuvarande skydd och/eller eventuella svagheter',
   UnderlyingMotivationAndStand: 'Underliggande motivering och ställningstagande',
   Consequense: 'Konsekvens',
   NoRisksIdentifiedYet: 'Inga risker har ännu identifierats',
   Risk: 'Risk',
   Title: 'Rubrik',
   CompleteRiskAssessment: 'Avsluta riskbedömning',
   CompleteRiskAssessmentConfirmationMessage: 'Är du säker på att du vill avsluta riskbedömningen? Det går inte att ångra sig när den har blivit avslutad.',
   RiskAssessmentCompleted: 'Riskbedömningen avslutad',
   CouldNotCompleteRiskAssessment: 'Kunde ej avsluta riskbedömningen',
   DetermineRiskAnalysisConfirmationText:
      'Är du säker på att du vill fastställa riskanalysen? Värdering och åtgärder kan ej ändras och åtgärdsplaner skapas upp.',
   DetermineRiskAnalysis: 'Fastställ riskanalys',

   // SWOT
   Strengths: 'Styrkor',
   Weaknesses: 'Svagheter',
   Opportunities: 'Möjligheter',
   Threats: 'Hot',
   Strength: 'Styrka',
   Weakness: 'Svaghet',
   Opportunity: 'Möjlighet',
   Threat: 'Hot',
   SWOT: 'SWOT',
   TimeToAddYourFirstSWOT: 'Dags att lägga till din första SWOT',
   Order: 'Ordning',
   ChangeOrder: 'Ändra ordning',
   ActionProposal: 'Åtgärdsförslag',
   CreateProposedAction: 'Skapa förslag till åtgärd',
   of: 'av',
   ValueInOrderOfImportance: 'Värdera i viktighetsgrad',
   NoSWOTMatchingSelectedFilter: 'Det finns inga SWOTs som matchar vald filtrering',
   Graph: 'Grafisk',
   NoTypeIdentified: 'Inga {{type}} är identifierade',

   // Settings
   Settings: 'Inställningar',

   // Date picker strings
   January: 'Januari',
   February: 'Februari',
   March: 'Mars',
   April: 'April',
   May: 'Maj',
   June: 'Juni',
   July: 'Juli',
   August: 'Augusti',
   September: 'September',
   October: 'Oktober',
   November: 'November',
   December: 'December',

   Jan: 'Jan',
   Feb: 'Feb',
   Mar: 'Mar',
   Apr: 'Apr',
   MayShort: 'Maj',
   Jun: 'Jun',
   Jul: 'Jul',
   Aug: 'Aug',
   Sep: 'Sep',
   Oct: 'Okt',
   Nov: 'Nov',
   Dec: 'Dec',

   Monday: 'Måndag',
   Tuesday: 'Tisdag',
   Wednesday: 'Onsdag',
   Thursday: 'Torsdag',
   Friday: 'Fredag',
   Saturday: 'Lördag',
   Sunday: 'Söndag',

   MondayShort: 'M',
   TuesdayShort: 'T',
   WednesdayShort: 'O',
   ThursdayShort: 'T',
   FridayShort: 'F',
   SaturdayShort: 'L',
   SundayShort: 'S',

   GoToToday: 'Gå till idag',

   // Data classification
   OpenInformation: 'Öppen information',
   InternalInformation: 'Intern information',
   SecretInformation: 'Hemlig information',
   QualifiedSecretInformation: 'Kvalificerad hemlig information',

   // Switch organisation
   SwitchOrganisation: 'Byt organisation',
   SelectOrganisation: 'Välj organisation',
   CouldNotSwitchOrganisation: 'Kunde inte byta organisation',
}
