import React from 'react'
import { SWOTType } from '../../../api/schemas/schema'
import { Badge, makeStyles, mergeClasses } from '@fluentui/react-components'
import { enumValuesToTranslate } from '../../../helpers/enumHelper'
import { getBadgeStyle } from '../../../helpers/swotHelper'

const useStyles = makeStyles({
   swotBadge: {
      minWidth: '70px',
   },
})

type SwotTypeBadgeProps = {
   type: SWOTType
   className?: string
}

export const SwotTypeBadge = ({ type, className }: SwotTypeBadgeProps) => {
   const classes = useStyles()

   return (
      <Badge size="large" appearance="filled" shape="rounded" className={mergeClasses(classes.swotBadge, className ?? '')} style={getBadgeStyle(type)}>
         {enumValuesToTranslate(SWOTType, type)}
      </Badge>
   )
}
