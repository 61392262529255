import React from 'react'
import { ConnectedDemandPopover } from './popovers/ConnectedDemandPopover'
import { Divider, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { BackgroundInfoPopover } from './popovers/BackgroundInfoPopover'
import { TipFrom365RobustPopover } from './popovers/TipFrom365RobustPopover'
import { DemandAnalysisQuestion } from '../../../api/schemas/schema'
import { QuestionPopover } from './popovers/QuestionPopover'

const useStyles = makeStyles({
   wrapper: {
      display: 'flex',
      paddingTop: tokens.spacingVerticalL,
      paddingBottom: tokens.spacingVerticalL,
   },
})
type InformationPopoversProps = {
   item: DemandAnalysisQuestion
   className: string
   includeQuestion?: boolean
}
export const InformationPopovers = ({ item, className, includeQuestion }: InformationPopoversProps) => {
   const classes = useStyles()
   return (
      <>
         <Divider appearance="brand" />
         <div className={mergeClasses(classes.wrapper, className)}>
            {includeQuestion && <QuestionPopover item={item} />}
            <BackgroundInfoPopover item={item} />
            <ConnectedDemandPopover item={item} />
            <TipFrom365RobustPopover item={item} />
         </div>
         <Divider appearance="brand" />
      </>
   )
}
