import React from 'react'
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
   phoneListWrapper: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'flex',
         flexDirection: 'column',
         gap: tokens.spacingVerticalXXL,
      },
   },
})
type PhoneListProps<T> = {
   items: T[]
   onRowClick: (item: T) => void
   renderBodyItem: (item: T) => JSX.Element | JSX.Element[]
   className?: string
}
export const PhoneList = <T extends {}>({ items, onRowClick, renderBodyItem, className }: PhoneListProps<T>) => {
   const classes = useStyles()
   return (
      <table className={mergeClasses(classes.phoneListWrapper, className ?? '')}>
         {items.map((x, i) => {
            return (
               <tr key={i} onClick={() => onRowClick(x)}>
                  {renderBodyItem(x)}
               </tr>
            )
         })}
      </table>
   )
}
