import React, { useContext, useEffect } from 'react'
import { t } from '../../i18n/i18n'
import { Divider, Button } from '@fluentui/react-components'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { ControlledTextInput, TextInput } from '../common/inputs/TextInput'
import { Modal, ModalActions } from '../common/modal/Modal'
import { useForm } from 'react-hook-form'
import { UserAccount } from '../../api/schemas/schema'
import { DataContext } from '../../context/DataContext'
import { api } from '../../helpers/apiHelper'
import { handleMessage } from '../../helpers/stateHelper'
import { ModalAccountInformationForm } from './ModalAccountInformationForm'
type ModalAccountInformationProps = {
   open: boolean
   close: () => void
}
export const ModalAccountInformation = ({ open, close }: ModalAccountInformationProps) => {
   const dataContext = useContext(DataContext)
   const { userAccount, accessToken, organisation } = dataContext.state
   const userAccountForm = useForm<UserAccount>()
   const {  handleSubmit, reset } = userAccountForm
   const closeAndReset = () => {
      reset()
      close()
   }
   const onSubmit = async (data: UserAccount) => {
      try {
         const updatedAccount = (await api(accessToken).updateUserAccount(data)).data
         const userAccounts = (await api(accessToken).getUserAccountsByOrganisationId(organisation.id)).data
         dataContext.setRootState((prev) => ({
            ...prev,
            userAccount: updatedAccount,
            userAccounts,
         }))
         handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('Account') }))
         closeAndReset()
      } catch (error) {
         handleMessage(dataContext.setRootState, 'error', t('CouldNotUpdateItem', { Item: t('Account') }))
      }
   }
   useEffect(() => {
      if (open) reset(userAccount)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [open])
   return (
      <Modal open={open} title={t('AccountInformation')}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid gap="L">
               <GridItem size={'12/12'}>
                  <Divider appearance="brand" />
               </GridItem>
              <ModalAccountInformationForm userAccountForm={userAccountForm}/>
               <GridItem size={'12/12'}>
                  <ModalActions>
                     <Button appearance="secondary" onClick={closeAndReset}>
                        {t('Cancel')}
                     </Button>
                     <Button appearance="primary" type="submit">
                        {t('Save')}
                     </Button>
                  </ModalActions>
               </GridItem>
            </Grid>
         </form>
      </Modal>
   )
}
