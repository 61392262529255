import React, { useContext, useEffect, useState } from 'react'
import { DialogContent, DialogTitle } from '@fluentui/react-components'
import { Organisation, OrganisationSize, OrganisationType, SNI } from '../../api/schemas/schema'
import { UseFormReturn } from 'react-hook-form'
import { DataContext } from '../../context/DataContext'
import { handleMessage } from '../../helpers/stateHelper'
import { api } from '../../helpers/apiHelper'
import { GridItem } from '../common/grid/GridItem'
import { Grid } from '../common/grid/Grid'
import { Title } from '../common/text/Title'
import { t } from '../../i18n/i18n'
import { DialogActionButtons, DialogBody } from '../common/modal/Modal'
import { OrganisationDetailsForm } from '../organisation/OrganisationDetailsForm'
import { IDropdownOption } from '../../interfaces/IDropdownOptions'

type OrganisationStepProps = {
   nextStep: () => void
   dialogTitleClass: string
   dialogContentClass: string
   organisationForm: UseFormReturn<Organisation, any, undefined>
}
export const OrganisationStep = ({ nextStep, dialogTitleClass, dialogContentClass, organisationForm }: OrganisationStepProps) => {
   const dataContext = useContext(DataContext)
   const { userAccount, accessToken } = dataContext.state
   const { setRootState } = dataContext
   const { reset } = organisationForm
   const [snis, setSnis] = useState<SNI[]>()
   const [orgTypes, setOrgTypes] = useState<IDropdownOption[]>([])
   const [orgSizes, setOrgSizes] = useState<IDropdownOption[]>([])

   useEffect(() => {
      ;(async () => {
         try {
            if (userAccount.organisationId) reset((await api(accessToken).getOrganisationById(userAccount.organisationId)).data)
            Promise.all([
               await api(accessToken).getAllSnis(),
               await api(accessToken).getAllOrganisationTypes(),
               await api(accessToken).getAllOrganisationSizes(),
            ]).then(([sni, organisationTypes, organisationSizes]) => {
               setSnis(sni.data)
               const sortedOrgSizes = organisationSizes.data.sort((a, b) => {
                  const valueA = a.text.includes('+') ? Number(a.text.split('+')[0]) : Number(a.text.split('-')[0])
                  const valueB = b.text.includes('+') ? Number(b.text.split('+')[0]) : Number(b.text.split('-')[0])
                  return valueA - valueB
               })

               const mappedOrgSizes: IDropdownOption[] = sortedOrgSizes.map((size: OrganisationType) => ({
                  key: size.id,
                  text: size.text,
               }))

               const mappedOrgTypes: IDropdownOption[] = organisationTypes.data.map((type: OrganisationSize) => ({
                  key: type.id,
                  text: type.text,
               }))

               setOrgSizes(mappedOrgSizes)
               setOrgTypes(mappedOrgTypes)
            })
         } catch (error: any) {
            handleMessage(setRootState, 'error', t('FetchFailed'), error.message)
         }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <form onSubmit={nextStep}>
         <DialogBody>
            <DialogTitle action="" as="h3" className={dialogTitleClass}>
               {t('WelcomeTo') + ' 365Robust!'}
            </DialogTitle>
            <DialogContent className={dialogContentClass}>
               <Grid gap="L">
                  <GridItem size="12/12">
                     <Title as="h4">{t('CheckAndVerifyOrganisationData')}</Title>
                  </GridItem>
                  <OrganisationDetailsForm organisationForm={organisationForm} orgSizes={orgSizes} orgTypes={orgTypes} snis={snis} />
               </Grid>
            </DialogContent>
            <DialogActionButtons primaryButtonText={t('Next')} />
         </DialogBody>
      </form>
   )
}
