import React from 'react'
import { makeStyles, tokens } from '@fluentui/react-components'
import { DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { GridItem } from '../../../common/grid/GridItem'
import { BadgeComplianceLevel } from '../../BadgeComplianceLevel'
import { Text } from '../../../common/text/Text'
import { Avatar } from '../../../common/avatar/Avatar'
import { t } from '../../../../i18n/i18n'
import { InformationPopovers } from '../../demandAnalysis/DemandInformationPopovers'
import { getFullName } from '../../../../helpers/accountHelper'

type ParentQuestionInformationProps = {
   parent: DemandAnalysisQuestion
}
const useStyles = makeStyles({
   marginTopXS: {
      marginTop: tokens.spacingVerticalXS,
   },
   marginTopS: {
      marginTop: tokens.spacingVerticalS,
      marginBottom: tokens.spacingVerticalS,
   },
   popoverWrapper: {
      justifyContent: 'space-between',
   },
})
export const ParentQuestionInformation = ({ parent }: ParentQuestionInformationProps) => {
   const classes = useStyles()
   return (
      <>
         <GridItem size="12/12">
            <InformationPopovers item={parent} className={classes.popoverWrapper} includeQuestion />
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('ProposedAction')}
            </Text>
            <Text className={classes.marginTopS}>{parent.proposedAction}</Text>
         </GridItem>
         <GridItem size="6/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('EstimatedCost')}
            </Text>
            <Text className={classes.marginTopS}>{parent.estimatedCost}</Text>
         </GridItem>
         <GridItem size="6/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('ComplianceLevel')}
            </Text>
            <BadgeComplianceLevel className={classes.marginTopXS} text={parent.answer.text} bgHexColor={parent.answer.colorHex} />
         </GridItem>
         <GridItem size="6/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('Process')}
            </Text>
            <Text className={classes.marginTopS}>{parent.processes.join(', ')}</Text>
         </GridItem>
         <GridItem size="6/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('AnsweredBy')}
            </Text>
            <Avatar className={classes.marginTopXS} name={getFullName(parent.answeredBy)}></Avatar>
         </GridItem>
      </>
   )
}
