import { Button, Checkbox, Divider, makeStyles } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import React, { useContext } from 'react'
import { DataClassification, DemandAnalysis } from '../../api/schemas/schema'
import { getDefaultValues } from '../../helpers/demandAssessmentHelper'
import { enumValuesToOptions } from '../../helpers/enumHelper'
import { GridItem } from '../common/grid/GridItem'
import { ControlledDropDown } from '../common/inputs/ControlledDropdown'
import { ControlledSimpleDropdown } from '../common/inputs/ControlledSimpleDropdown'
import { ControlledDatePicker } from '../common/inputs/DatePicker'
import { ControlledTextInput, TextInput } from '../common/inputs/TextInput'
import { useMsal } from '@azure/msal-react'
import { UseFormReturn } from 'react-hook-form'
import { DataContext } from '../../context/DataContext'
import { IComplianceState } from '../../interfaces/IComplianceState'
import { Grid } from '../common/grid/Grid'
import { ModalActions } from '../common/modal/Modal'
const useStyles = makeStyles({
   chekboxText: { display: 'flex', alignItems: 'center' },
})
type DemandAssessmentFormProps = {
   demandAssessmentForm: UseFormReturn<DemandAnalysis, any, undefined>
   setComplianceState: React.Dispatch<React.SetStateAction<IComplianceState>>
   complianceState: IComplianceState
   setIncludeExtendedLevel: React.Dispatch<React.SetStateAction<boolean>>
   includeExtendedLevel: boolean
   nextStep: () => void
}
export const DemandAssessmentForm = ({
   demandAssessmentForm,
   setComplianceState,
   complianceState,
   setIncludeExtendedLevel,
   includeExtendedLevel,
   nextStep,
}: DemandAssessmentFormProps) => {
   const dataContext = useContext(DataContext)
   const { branch } = dataContext.state
   const { accounts } = useMsal()
   const classes = useStyles()
   const { control, reset, getValues } = demandAssessmentForm
   const { processes } = complianceState

   const handleCheckboxChange = () => {
      setIncludeExtendedLevel(!includeExtendedLevel)
   }
   return (
      <Grid gap="L">
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="12/12">
            <ControlledTextInput required control={control} name={'name'} label={t('AssessmentName')} />
         </GridItem>
         <GridItem size="12/12">
            <TextInput disabled required value={accounts[0].name} label={t('Owner')} />
         </GridItem>
         <GridItem size="12/12">
            <TextInput disabled={true} required value={branch.name} label={t('Branch')} />
         </GridItem>
         <GridItem size="12/12">
            <ControlledSimpleDropdown required name={'processes'} control={control} label={t('Process')} options={processes.map((x) => x.text)} />
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.chekboxText}>
               <Checkbox label={t('IncludeExtendedLevel')} onChange={handleCheckboxChange} checked={includeExtendedLevel} />
            </div>
         </GridItem>
         <GridItem size="12/12">
            <ControlledDropDown
               required
               control={control}
               name={'dataClassification'}
               label={t('DataClassification')}
               options={enumValuesToOptions(DataClassification)}
            />
         </GridItem>
         <GridItem size="6/12">
            <ControlledDatePicker required control={control} name={'analysisStart'} label={t('StartOfAssessment')} />
         </GridItem>
         <GridItem size="6/12">
            <ControlledDatePicker
               required
               control={control}
               name={'analysisEnd'}
               label={t('EndOfAssessment')}
               getValues={getValues}
               compareWith={'analysisStart'}
            />
         </GridItem>
         <GridItem size="12/12">
            <ControlledTextInput multiline control={control} name={'comment'} label={t('Comment')} />
         </GridItem>
         <GridItem size="12/12">
            <ModalActions>
               <Button
                  onClick={() => {
                     setComplianceState((prev) => ({ ...prev, open: false }))
                     reset(getDefaultValues(dataContext, complianceState))
                     setIncludeExtendedLevel(false)
                  }}
                  appearance="secondary"
               >
                  {t('Cancel')}
               </Button>
               <Button appearance="primary" onClick={nextStep}>
                  {t('Next')}
               </Button>
            </ModalActions>
         </GridItem>
      </Grid>
   )
}
