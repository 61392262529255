import React from 'react'
import { Dropdown, Option, Divider } from '@fluentui/react-components'
import { t } from '../../../../i18n/i18n'
import { Controller, UseFormReturn } from 'react-hook-form'
import { GridItem } from '../../../common/grid/GridItem'
import { ControlledAccountPicker } from '../../../common/inputs/ControlledAccountPicker'
import { ControlledTextInput } from '../../../common/inputs/TextInput'
import { ActionPlan } from '../../../../api/schemas/schema'
import { Field } from '../../../common/inputs/Field'
import { ControlledCheckbox } from '../../../common/inputs/ControlledCheckbox'
import { ControlledDatePicker } from '../../../common/inputs/DatePicker'

type ActionDecisionProps = {
   actionPlanForm: UseFormReturn<ActionPlan, any, undefined>
}
export const ActionDecision = ({ actionPlanForm }: ActionDecisionProps) => {
   const { control, setValue, watch } = actionPlanForm

   return (
      <>
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="12/12">
            <ControlledCheckbox
               control={control}
               name="actionNeeded"
               checked={watch('actionNeeded') === false}
               label={t('NoActionShallBeTaken')}
               onChange={(e, data) => {
                  const actionNeeded = !watch('actionNeeded')
                  if (!actionNeeded) {
                     setValue('decidedAction', null)
                     setValue('responsible', null)
                     setValue('responsibleId', null)
                     setValue('priority', null)
                     setValue('deadline', null)
                  }
                  setValue('actionNeeded', actionNeeded)
               }}
            />
         </GridItem>
         {watch('actionNeeded') ? (
            <>
               <GridItem size="12/12">
                  <ControlledTextInput control={control} name={'decidedAction'} required label={t('DecisionOnAction')} multiline />
               </GridItem>
               <GridItem size="12/12">
                  <ControlledAccountPicker label={t('ResponsibleOfAction')} control={control} name={'responsible'} watch={watch} setValue={setValue} required />
               </GridItem>
               <GridItem size="6/12">
                  <ControlledDatePicker control={control} name="deadline" label={t('Deadline')} />
               </GridItem>
               <GridItem size="6/12">
                  <Controller
                     control={control}
                     name="priority"
                     render={({ field: { value, onChange }, formState: { disabled } }) => (
                        <Field label={t('Priority')} required>
                           <Dropdown
                              disabled={disabled ?? false}
                              value={value ? value.toString() : ''}
                              onOptionSelect={(e, d) => onChange(Number(d.optionValue))}
                           >
                              <Option>1</Option>
                              <Option>2</Option>
                              <Option>3</Option>
                              <Option>4</Option>
                           </Dropdown>
                        </Field>
                     )}
                  />
               </GridItem>
            </>
         ) : null}
         <GridItem size="12/12">
            <ControlledTextInput control={control} name={'decidedActionComment'} label={t('Comment')} multiline />
         </GridItem>
      </>
   )
}
