import React from 'react'
import { Text } from '../text/Text'
import { makeStyles, tokens } from '@fluentui/react-components'

type GroupHeaderProps = {
   title: string
   badge: JSX.Element
}
const useStyles = makeStyles({
   flex: {
      display: 'flex',
      gap: tokens.spacingHorizontalXL,
      alignItems: 'baseline',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
})
export const GroupHeader = ({ badge, title }: GroupHeaderProps) => {
   const classes = useStyles()
   return (
      <div className={classes.flex}>
         <Text className={classes.hideInPhone} weight="bold" block={false} size={300}>
            {title}
         </Text>
         {badge}
      </div>
   )
}
