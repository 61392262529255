/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActionPlan {
   /** @format uuid */
   id?: string
   /** @format int32 */
   index?: number | null
   /** @format uuid */
   organisationId?: string
   /** @format uuid */
   branchId?: string
   /** @format uuid */
   demandAnalysisQuestionId?: string | null
   /** @format uuid */
   swotId?: string | null
   /** @format uuid */
   responsibleId?: string | null
   responsible?: UserAccount
   /** @format int32 */
   priority?: number | null
   actionNeeded?: boolean
   /** @format date-time */
   deadline?: string | null
   decidedAction?: string | null
   /** @format uuid */
   decidedById?: string | null
   decidedBy?: UserAccount
   decidedActionComment?: string | null
   status?: ActionPlanStatus
   /** @format uuid */
   implementedById?: string | null
   implementedBy?: UserAccount
   actionTaken?: boolean | null
   implementedAction?: string | null
   implementedActionComment?: string | null
   /** @format date-time */
   modified?: string
   /** @format uuid */
   modifiedById?: string
   modifiedBy?: UserAccount
   /** @format date-time */
   created?: string
   /** @format uuid */
   createdById?: string
   createdBy?: UserAccount
}

/** @format int32 */
export enum ActionPlanStatus {
   NotDecided = 0,
   NotToBeAdressed = 1,
   Decided = 3,
}

export interface AdditionalModule {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface AdditionalModuleOrganisationConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   organisationId?: string
   /** @format uuid */
   additionalModuleId?: string
   additionalModule?: AdditionalModule
}

export interface AnswerOptionAnalysisQuestion {
   /** @format uuid */
   id?: string
   /** @format uuid */
   demandAnalysisQuestionId?: string
   /** @format uuid */
   demandId?: string | null
   text?: string | null
   /** @format int32 */
   value?: number
   infoLabel?: string | null
   /** @format int32 */
   order?: number
   demandConnection?: boolean
   actionRequired?: boolean
   colorHex?: string | null
}

export interface Branch {
   /** @format uuid */
   id?: string
   name?: string | null
   address?: string | null
   /** @format int32 */
   postalCode?: number
   county?: string | null
   propertyDesignation?: string | null
   /** @format uuid */
   organisationTypeId?: string
   organisationType?: OrganisationType
   /** @format uuid */
   organisationSizeId?: string
   organisationSize?: OrganisationSize
   snIs?: SNIBranchConnection[] | null
   description?: string | null
   /** @format date-time */
   valuationDate?: string | null
   keyFigures?: string | null
   comment?: string | null
   responsible?: string | null
   /** @format uuid */
   organisationId?: string
   /** @format int64 */
   valuation?: number | null
   /** @format date-time */
   modified?: string
   modifiedBy?: string | null
   /** @format date-time */
   created?: string
   createdBy?: string | null
}

export interface CompleteCurrentAnalysisResponse {
   updatedQuestions?: DemandAnalysisQuestion[] | null
   actionPlans?: ActionPlan[] | null
}

/** @format int32 */
export enum DataClassification {
   OpenInformation = 0,
   InternalInformation = 1,
   SecretInformation = 2,
   QualifiedSecretInformation = 3,
}

export interface DemandAnalysis {
   /** @format uuid */
   id?: string
   /**
    * @minLength 0
    * @maxLength 300
    */
   name: string
   /** @format int32 */
   order?: number
   /** @format uuid */
   analysisOwnerId: string
   analysisOwner?: UserAccount
   /** @format date-time */
   analysisStart: string
   /** @format date-time */
   analysisEnd: string
   additionalModules?: string[] | null
   /** @format uuid */
   branchId?: string
   branch?: Branch
   /** @format uuid */
   organisationId?: string
   leadershipPerspectives: string[]
   processes: string[]
   subjects: string[]
   /**
    * @minLength 0
    * @maxLength 2000
    */
   comment?: string | null
   status?: DemandAnalysisStatus
   dataClassification: DataClassification
   questions?: DemandAnalysisQuestion[] | null
   actionPlans?: ActionPlan[] | null
   /** @format date-time */
   modified?: string
   /** @format uuid */
   modifiedById?: string
   modifiedBy?: UserAccount
   /** @format date-time */
   created?: string
   /** @format uuid */
   createdById?: string
   createdBy?: UserAccount
   processOwners?: ProcessOwner[] | null
}

export interface DemandAnalysisQuestion {
   /** @format uuid */
   id?: string
   /** @format uuid */
   demandAnalysisId?: string
   /** @format uuid */
   answeredById?: string | null
   answeredBy?: UserAccount
   /** @format uuid */
   answerId?: string | null
   answer?: AnswerOptionAnalysisQuestion
   proposedAction?: string | null
   /** @format int32 */
   estimatedCost?: number | null
   status?: DemandAnalysisQuestionStatus
   text?: string | null
   backgroundInformation?: string | null
   proposedActionTip?: string | null
   answerOptions?: AnswerOptionAnalysisQuestion[] | null
   processes?: string[] | null
   attachment?: FileBlob
   demandText?: string | null
   demandSource?: string | null
   demandUrl?: string | null
   demandUrlText?: string | null
   /** @format int32 */
   number?: number
   comment?: string | null
   /** @format uuid */
   updatedAnswerId?: string | null
   updatedAnswer?: AnswerOptionAnalysisQuestion
   /** @format uuid */
   answerUpdatedById?: string | null
   answerUpdatedBy?: UserAccount
   /** @format date-time */
   modified?: string
   /** @format uuid */
   modifiedById?: string
   modifiedBy?: UserAccount
}

/** @format int32 */
export enum DemandAnalysisQuestionStatus {
   NotAnswered = 0,
   Answered = 1,
   Determined = 2,
}

/** @format int32 */
export enum DemandAnalysisStatus {
   Draft = 0,
   Ongoing = 1,
   Completed = 2,
}

export interface FileBlob {
   /** @format int32 */
   id?: number
   name?: string | null
   /** @format uuid */
   attachmentId?: string
   /** @format byte */
   bytes?: string | null
   /** @format uuid */
   questionId?: string
}

export interface LeadershipPerspective {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface Organisation {
   /** @format uuid */
   id?: string
   name?: string | null
   organisationNumber?: string | null
   invoiceAddress?: string | null
   /** @format int32 */
   postalCode?: number
   county?: string | null
   country?: string | null
   invoiceReference?: string | null
   /** @format tel */
   phoneNumber?: string | null
   /** @format email */
   email?: string | null
   snIs?: SNIOrganisationConnection[] | null
   /** @format uuid */
   organisationSizeId?: string
   organisationSize?: OrganisationSize
   /** @format uuid */
   organisationTypeId?: string
   organisationType?: OrganisationType
   /** @format uuid */
   subscriptionId?: string
   subscription?: Subscription
   additionalModules?: AdditionalModuleOrganisationConnection[] | null
   partners?: Organisation[] | null
   /** @format uuid */
   parentOrganisationId?: string | null
   /** @format date-time */
   modified?: string
   modifiedBy?: string | null
   /** @format date-time */
   created?: string
   createdBy?: string | null
}

export interface OrganisationSize {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface OrganisationType {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface Process {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface ProcessOwner {
   /** @format uuid */
   id?: string
   /** @format uuid */
   processId?: string
   process?: Process
   /** @format uuid */
   userAccountId?: string | null
   userAccount?: UserAccount
   ownerFreeText?: string | null
}

export interface ProcessProtectedAssetConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   protectedAssetId?: string
   /** @format uuid */
   processId?: string
   process?: Process
}

export interface ProtectedAsset {
   /** @format uuid */
   id?: string
   name?: string | null
   description?: string | null
   /** @format int64 */
   valuation?: number | null
   /** @format date-time */
   valuationDate?: string | null
   /** @format uuid */
   branchId?: string
   processes?: ProcessProtectedAssetConnection[] | null
   /** @format uuid */
   organisationId?: string
   motivation?: string | null
   /** @format date-time */
   modified?: string
   modifiedBy?: string | null
   /** @format date-time */
   created?: string
   createdBy?: string | null
}

export interface Risk {
   /** @format uuid */
   id?: string
   /** @format uuid */
   riskAnalysisId?: string
   /** @format uuid */
   protectedAssetId?: string
   protectedAsset?: ProtectedAsset
   scenario?: string | null
   source?: string | null
   currentState?: string | null
   consequense?: string | null
   status?: RiskStatus
   underlyingMotivation?: string | null
   /** @format date-time */
   modified?: string
   /** @format uuid */
   modifiedById?: string
   modifiedBy?: UserAccount
   /** @format date-time */
   created?: string
   /** @format uuid */
   createdById?: string
   createdBy?: UserAccount
}

export interface RiskAnalysis {
   /** @format uuid */
   id?: string
   name?: string | null
   purposeScope?: string | null
   type?: RiskAnalysisType
   limitation?: string | null
   /** @format uuid */
   analysisOwnerId?: string
   analysisOwner?: UserAccount
   /** @format uuid */
   analysisLeaderId?: string | null
   analysisLeader?: UserAccount
   dataClassification?: DataClassification
   /** @format date-time */
   startDate?: string | null
   /** @format date-time */
   endDate?: string | null
   comment?: string | null
   status?: RiskAnalysisStatus
   /** @format uuid */
   organisationId?: string
   risks?: Risk[] | null
   swoTs?: SWOT[] | null
   actionPlans?: ActionPlan[] | null
   /** @format date-time */
   modified?: string
   /** @format uuid */
   modifiedById?: string
   modifiedBy?: UserAccount
   /** @format date-time */
   created?: string
   /** @format uuid */
   createdById?: string
   createdBy?: UserAccount
}

/** @format int32 */
export enum RiskAnalysisStatus {
   Ongoing = 0,
   Completed = 1,
   Determined = 2,
}

/** @format int32 */
export enum RiskAnalysisType {
   SWOT = 0,
}

/** @format int32 */
export enum RiskStatus {
   Ongoing = 0,
}

export interface Role {
   /** @format uuid */
   id?: string
   name?: string | null
}

export interface SNI {
   /** @format uuid */
   id?: string
   department?: string | null
   category?: string | null
   /** @format int32 */
   code?: number
   comment?: string | null
   /** @format int32 */
   version?: number
}

export interface SNIBranchConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   branchId?: string
   /** @format uuid */
   sniId?: string
   sni?: SNI
}

export interface SNIOrganisationConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   organisationId?: string
   /** @format uuid */
   sniId?: string
   sni?: SNI
}

export interface SWOT {
   /** @format uuid */
   id?: string
   type?: SWOTType
   title?: string | null
   description?: string | null
   /** @format int32 */
   order?: number
   actionRequired?: boolean
   proposedAction?: string | null
   /** @format int32 */
   estimatedCost?: number | null
   comment?: string | null
   determined?: boolean
   /** @format date-time */
   modified?: string
   /** @format uuid */
   modifiedById?: string
   modifiedBy?: UserAccount
   /** @format date-time */
   created?: string
   /** @format uuid */
   createdById?: string
   createdBy?: UserAccount
}

/** @format int32 */
export enum SWOTType {
   Strength = 0,
   Weakness = 1,
   Opportunity = 2,
   Threat = 3,
}

export interface Subject {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface Subscription {
   /** @format uuid */
   id?: string
   text?: string | null
}

export interface User {
   /** @format uuid */
   id?: string
   /** @format uuid */
   guid?: string
   /** @format date-time */
   created?: string
}

export interface UserAccount {
   /** @format uuid */
   id?: string
   user?: User
   /** @format uuid */
   organisationId?: string | null
   organisation?: Organisation
   firstName?: string | null
   lastName?: string | null
   /** @format email */
   email?: string | null
   jobTitle?: string | null
   /** @format tel */
   phoneNumber?: string | null
   userType?: UserType
   roles?: UserRoleConnection[] | null
   /** @format date-time */
   modified?: string
   modifiedBy?: string | null
   /** @format date-time */
   created?: string
   createdBy?: string | null
}

export interface UserRoleConnection {
   /** @format uuid */
   id?: string
   /** @format uuid */
   roleId?: string
   role?: Role
   processes?: Process[] | null
}

export interface UserType {
   /** @format uuid */
   id?: string
   name?: string | null
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
   /** set parameter to `true` for call `securityWorker` for this request */
   secure?: boolean
   /** request path */
   path: string
   /** content type of request body */
   type?: ContentType
   /** query params */
   query?: QueryParamsType
   /** format of response (i.e. response.json() -> format: "json") */
   format?: ResponseFormat
   /** request body */
   body?: unknown
   /** base url */
   baseUrl?: string
   /** request cancellation token */
   cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
   baseUrl?: string
   baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
   securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
   customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
   data: D
   error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
   Json = 'application/json',
   FormData = 'multipart/form-data',
   UrlEncoded = 'application/x-www-form-urlencoded',
   Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
   public baseUrl: string = ''
   private securityData: SecurityDataType | null = null
   private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
   private abortControllers = new Map<CancelToken, AbortController>()
   private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

   private baseApiParams: RequestParams = {
      credentials: 'same-origin',
      headers: {},
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
   }

   constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
      Object.assign(this, apiConfig)
   }

   public setSecurityData = (data: SecurityDataType | null) => {
      this.securityData = data
   }

   protected encodeQueryParam(key: string, value: any) {
      const encodedKey = encodeURIComponent(key)
      return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
   }

   protected addQueryParam(query: QueryParamsType, key: string) {
      return this.encodeQueryParam(key, query[key])
   }

   protected addArrayQueryParam(query: QueryParamsType, key: string) {
      const value = query[key]
      return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
   }

   protected toQueryString(rawQuery?: QueryParamsType): string {
      const query = rawQuery || {}
      const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
      return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join('&')
   }

   protected addQueryParams(rawQuery?: QueryParamsType): string {
      const queryString = this.toQueryString(rawQuery)
      return queryString ? `?${queryString}` : ''
   }

   private contentFormatters: Record<ContentType, (input: any) => any> = {
      [ContentType.Json]: (input: any) => (input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input),
      [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
      [ContentType.FormData]: (input: any) =>
         Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key]
            formData.append(
               key,
               property instanceof Blob ? property : typeof property === 'object' && property !== null ? JSON.stringify(property) : `${property}`
            )
            return formData
         }, new FormData()),
      [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
   }

   protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
      return {
         ...this.baseApiParams,
         ...params1,
         ...(params2 || {}),
         headers: {
            ...(this.baseApiParams.headers || {}),
            ...(params1.headers || {}),
            ...((params2 && params2.headers) || {}),
         },
      }
   }

   protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
      if (this.abortControllers.has(cancelToken)) {
         const abortController = this.abortControllers.get(cancelToken)
         if (abortController) {
            return abortController.signal
         }
         return void 0
      }

      const abortController = new AbortController()
      this.abortControllers.set(cancelToken, abortController)
      return abortController.signal
   }

   public abortRequest = (cancelToken: CancelToken) => {
      const abortController = this.abortControllers.get(cancelToken)

      if (abortController) {
         abortController.abort()
         this.abortControllers.delete(cancelToken)
      }
   }

   public request = async <T = any, E = any>({
      body,
      secure,
      path,
      type,
      query,
      format,
      baseUrl,
      cancelToken,
      ...params
   }: FullRequestParams): Promise<HttpResponse<T, E>> => {
      const secureParams =
         ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {}
      const requestParams = this.mergeRequestParams(params, secureParams)
      const queryString = query && this.toQueryString(query)
      const payloadFormatter = this.contentFormatters[type || ContentType.Json]
      const responseFormat = format || requestParams.format

      return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
         ...requestParams,
         headers: {
            ...(requestParams.headers || {}),
            ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
         },
         signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
         body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      }).then(async (response) => {
         const r = response as HttpResponse<T, E>
         r.data = null as unknown as T
         r.error = null as unknown as E

         const data = !responseFormat
            ? r
            : await response[responseFormat]()
                 .then((data) => {
                    if (r.ok) {
                       r.data = data
                    } else {
                       r.error = data
                    }
                    return r
                 })
                 .catch((e) => {
                    r.error = e
                    return r
                 })

         if (cancelToken) {
            this.abortControllers.delete(cancelToken)
         }

         if (!response.ok) throw data
         return data
      })
   }
}

/**
 * @title customerApi
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
   api = {
      /**
       * No description
       *
       * @tags ActionPlanEndpoints
       * @name UpdateActionPlanOnDemandAnalysis
       * @request PUT:/api/ActionPlan
       */
      updateActionPlanOnDemandAnalysis: (
         query: {
            /** @format uuid */
            demandAnalysisId: string
         },
         data: ActionPlan,
         params: RequestParams = {}
      ) =>
         this.request<ActionPlan, void>({
            path: `/api/ActionPlan`,
            method: 'PUT',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags BranchEndpoints
       * @name GetBranchById
       * @request GET:/api/Branch/{id}
       */
      getBranchById: (id: string, params: RequestParams = {}) =>
         this.request<Branch, void>({
            path: `/api/Branch/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags BranchEndpoints
       * @name DeleteBranchById
       * @request DELETE:/api/Branch/{id}
       */
      deleteBranchById: (id: string, params: RequestParams = {}) =>
         this.request<void, void>({
            path: `/api/Branch/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags BranchEndpoints
       * @name AddBranch
       * @request POST:/api/Branch/{organisationId}
       */
      addBranch: (organisationId: string, data: Branch, params: RequestParams = {}) =>
         this.request<Branch, void>({
            path: `/api/Branch/${organisationId}`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags BranchEndpoints
       * @name UpdateBranch
       * @request PUT:/api/Branch/{organisationId}
       */
      updateBranch: (organisationId: string, data: Branch, params: RequestParams = {}) =>
         this.request<Branch, void>({
            path: `/api/Branch/${organisationId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags BranchEndpoints
       * @name GetBranchesByOrganisationId
       * @request GET:/api/Branch/byorganisation/{organisationId}
       */
      getBranchesByOrganisationId: (organisationId: string, params: RequestParams = {}) =>
         this.request<Branch[], void>({
            path: `/api/Branch/byorganisation/${organisationId}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags DemandAnalysisEndpoints
       * @name GetDemandAnalysisById
       * @request GET:/api/DemandAnalysis/{analysisId}/{organisationId}
       */
      getDemandAnalysisById: (analysisId: string, organisationId: string, params: RequestParams = {}) =>
         this.request<DemandAnalysis, void>({
            path: `/api/DemandAnalysis/${analysisId}/${organisationId}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags DemandAnalysisEndpoints
       * @name AddDemandAnalysis
       * @request POST:/api/DemandAnalysis
       */
      addDemandAnalysis: (data: DemandAnalysis, params: RequestParams = {}) =>
         this.request<DemandAnalysis, void>({
            path: `/api/DemandAnalysis`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags DemandAnalysisEndpoints
       * @name UpdateDemandAnalysis
       * @request PUT:/api/DemandAnalysis
       */
      updateDemandAnalysis: (data: DemandAnalysis, params: RequestParams = {}) =>
         this.request<DemandAnalysis, void>({
            path: `/api/DemandAnalysis`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags DemandAnalysisEndpoints
       * @name DeleteDemandAnalysisById
       * @request DELETE:/api/DemandAnalysis/{id}
       */
      deleteDemandAnalysisById: (id: string, params: RequestParams = {}) =>
         this.request<void, void>({
            path: `/api/DemandAnalysis/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags DemandAnalysisEndpoints
       * @name GetDemandAnalysisByOrganisationId
       * @request GET:/api/DemandAnalysis/byorganisation{organisationId}
       */
      getDemandAnalysisByOrganisationId: (organisationId: string, params: RequestParams = {}) =>
         this.request<DemandAnalysis[], void>({
            path: `/api/DemandAnalysis/byorganisation${organisationId}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags DemandAnalysisQuestionEndpoints
       * @name UpdateDemandAnalysisQuestion
       * @request PUT:/api/DemandAnalysisQuestion
       */
      updateDemandAnalysisQuestion: (data: DemandAnalysisQuestion, params: RequestParams = {}) =>
         this.request<DemandAnalysisQuestion, void>({
            path: `/api/DemandAnalysisQuestion`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags DemandAnalysisQuestionEndpoints
       * @name ConcludeCurrentAnalysis
       * @request POST:/api/DemandAnalysisQuestion/concludeCurrentAnalysis/{organisationId}
       */
      concludeCurrentAnalysis: (
         organisationId: string,
         query: {
            /** @format uuid */
            branchId: string
         },
         data: DemandAnalysisQuestion[],
         params: RequestParams = {}
      ) =>
         this.request<CompleteCurrentAnalysisResponse, void>({
            path: `/api/DemandAnalysisQuestion/concludeCurrentAnalysis/${organisationId}`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags LeadershipPerspectiveEndpoints
       * @name GetAllLeadershipPerspectives
       * @request GET:/api/LeadershipPerspective
       */
      getAllLeadershipPerspectives: (params: RequestParams = {}) =>
         this.request<LeadershipPerspective[], void>({
            path: `/api/LeadershipPerspective`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationEndpoints
       * @name GetOrganisationById
       * @request GET:/api/Organisation/{id}
       */
      getOrganisationById: (id: string, params: RequestParams = {}) =>
         this.request<Organisation, void>({
            path: `/api/Organisation/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationEndpoints
       * @name DeleteOrganisationById
       * @request DELETE:/api/Organisation/{id}
       */
      deleteOrganisationById: (id: string, params: RequestParams = {}) =>
         this.request<void, void>({
            path: `/api/Organisation/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationEndpoints
       * @name GetOrganisationsByUserId
       * @request GET:/api/Organisation/byUserId/{userId}
       */
      getOrganisationsByUserId: (userId: string, params: RequestParams = {}) =>
         this.request<Organisation, void>({
            path: `/api/Organisation/byUserId/${userId}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationEndpoints
       * @name AddOrganisation
       * @request POST:/api/Organisation
       */
      addOrganisation: (data: Organisation, params: RequestParams = {}) =>
         this.request<Organisation, void>({
            path: `/api/Organisation`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationEndpoints
       * @name UpdateOrganisation
       * @request PUT:/api/Organisation
       */
      updateOrganisation: (data: Organisation, params: RequestParams = {}) =>
         this.request<Organisation, void>({
            path: `/api/Organisation`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationSizeEndpoints
       * @name GetAllOrganisationSizes
       * @request GET:/api/OrganisationSize
       */
      getAllOrganisationSizes: (params: RequestParams = {}) =>
         this.request<OrganisationSize[], void>({
            path: `/api/OrganisationSize`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags OrganisationTypeEndpoints
       * @name GetAllOrganisationTypes
       * @request GET:/api/OrganisationType
       */
      getAllOrganisationTypes: (params: RequestParams = {}) =>
         this.request<OrganisationType[], void>({
            path: `/api/OrganisationType`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags ProcessEndpoints
       * @name GetAllProcesss
       * @request GET:/api/Process
       */
      getAllProcesss: (params: RequestParams = {}) =>
         this.request<Process[], void>({
            path: `/api/Process`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags ProtectedAssetEndpoints
       * @name GetProtectedAssetById
       * @request GET:/api/ProtectedAsset/{id}
       */
      getProtectedAssetById: (id: string, params: RequestParams = {}) =>
         this.request<ProtectedAsset, void>({
            path: `/api/ProtectedAsset/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags ProtectedAssetEndpoints
       * @name DeleteProtectedAssetById
       * @request DELETE:/api/ProtectedAsset/{id}
       */
      deleteProtectedAssetById: (id: string, params: RequestParams = {}) =>
         this.request<void, void>({
            path: `/api/ProtectedAsset/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags ProtectedAssetEndpoints
       * @name AddProtectedAsset
       * @request POST:/api/ProtectedAsset
       */
      addProtectedAsset: (data: ProtectedAsset, params: RequestParams = {}) =>
         this.request<ProtectedAsset, void>({
            path: `/api/ProtectedAsset`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags ProtectedAssetEndpoints
       * @name UpdateProtectedAsset
       * @request PUT:/api/ProtectedAsset
       */
      updateProtectedAsset: (data: ProtectedAsset, params: RequestParams = {}) =>
         this.request<ProtectedAsset, void>({
            path: `/api/ProtectedAsset`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags ProtectedAssetEndpoints
       * @name GetProtectedAssetsByOrganisationId
       * @request GET:/api/ProtectedAsset/byorganisation/{organisationId}
       */
      getProtectedAssetsByOrganisationId: (organisationId: string, params: RequestParams = {}) =>
         this.request<ProtectedAsset[], void>({
            path: `/api/ProtectedAsset/byorganisation/${organisationId}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags ProtectedAssetEndpoints
       * @name AddProtectedAssetsAsync
       * @request POST:/api/ProtectedAsset/batch
       */
      addProtectedAssetsAsync: (data: ProtectedAsset[], params: RequestParams = {}) =>
         this.request<ProtectedAsset[], void>({
            path: `/api/ProtectedAsset/batch`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags RiskAnalysisEndpoints
       * @name AddRiskAnalysis
       * @request POST:/api/RiskAnalysis
       */
      addRiskAnalysis: (data: RiskAnalysis, params: RequestParams = {}) =>
         this.request<RiskAnalysis, void>({
            path: `/api/RiskAnalysis`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags RiskAnalysisEndpoints
       * @name UpdateRiskAnalysis
       * @request PUT:/api/RiskAnalysis
       */
      updateRiskAnalysis: (data: RiskAnalysis, params: RequestParams = {}) =>
         this.request<RiskAnalysis, void>({
            path: `/api/RiskAnalysis`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags RiskAnalysisEndpoints
       * @name AddSwotToRiskAnalysis
       * @request POST:/api/RiskAnalysis/{analysisId}
       */
      addSwotToRiskAnalysis: (analysisId: string, data: SWOT, params: RequestParams = {}) =>
         this.request<RiskAnalysis, void>({
            path: `/api/RiskAnalysis/${analysisId}`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags RiskAnalysisEndpoints
       * @name UpdateSwotForRiskAnalysis
       * @request PUT:/api/RiskAnalysis/{analysisId}
       */
      updateSwotForRiskAnalysis: (analysisId: string, data: SWOT, params: RequestParams = {}) =>
         this.request<RiskAnalysis, void>({
            path: `/api/RiskAnalysis/${analysisId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags RiskAnalysisEndpoints
       * @name DetermineRiskAnalysis
       * @request POST:/api/RiskAnalysis/DetermineRiskAnalysis/{organisationId}
       */
      determineRiskAnalysis: (
         organisationId: string,
         query: {
            /** @format uuid */
            branchId: string
         },
         data: RiskAnalysis,
         params: RequestParams = {}
      ) =>
         this.request<RiskAnalysis, void>({
            path: `/api/RiskAnalysis/DetermineRiskAnalysis/${organisationId}`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags RiskAnalysisEndpoints
       * @name GetRiskAnalysesByOrganisationId
       * @request GET:/api/RiskAnalysis/byorganisation/{organisationId}
       */
      getRiskAnalysesByOrganisationId: (organisationId: string, params: RequestParams = {}) =>
         this.request<RiskAnalysis[], void>({
            path: `/api/RiskAnalysis/byorganisation/${organisationId}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags RiskAnalysisEndpoints
       * @name GetRiskAnalysisById
       * @request GET:/api/RiskAnalysis/{analysisId}/{organisationId}
       */
      getRiskAnalysisById: (analysisId: string, organisationId: string, params: RequestParams = {}) =>
         this.request<RiskAnalysis, void>({
            path: `/api/RiskAnalysis/${analysisId}/${organisationId}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags RiskAnalysisEndpoints
       * @name UpdateActionPlanOnRiskAnalysis
       * @request PUT:/api/RiskAnalysis/actionPlan/{analysisId}
       */
      updateActionPlanOnRiskAnalysis: (analysisId: string, data: ActionPlan, params: RequestParams = {}) =>
         this.request<RiskAnalysis, void>({
            path: `/api/RiskAnalysis/actionPlan/${analysisId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags RiskEndpoints
       * @name AddRisk
       * @request POST:/api/Risk
       */
      addRisk: (data: Risk, params: RequestParams = {}) =>
         this.request<Risk, void>({
            path: `/api/Risk`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags SNIEndpoints
       * @name GetAllSnis
       * @request GET:/api/SNI
       */
      getAllSnis: (params: RequestParams = {}) =>
         this.request<SNI[], void>({
            path: `/api/SNI`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags SubjectEndpoints
       * @name GetAllSubjects
       * @request GET:/api/Subject
       */
      getAllSubjects: (params: RequestParams = {}) =>
         this.request<Subject[], void>({
            path: `/api/Subject`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags SWOTEndpoints
       * @name AddSwot
       * @request POST:/api/SWOT
       */
      addSwot: (data: SWOT, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/SWOT`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * No description
       *
       * @tags SWOTEndpoints
       * @name UpdateSwot
       * @request PUT:/api/SWOT/updateSWOT/{swotId}
       */
      updateSwot: (swotId: string, data: SWOT, params: RequestParams = {}) =>
         this.request<void, any>({
            path: `/api/SWOT/updateSWOT/${swotId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * No description
       *
       * @tags UserAccountEndpoints
       * @name GetUserAccountByUserId
       * @request GET:/api/UserAccount/{id}
       */
      getUserAccountByUserId: (id: string, params: RequestParams = {}) =>
         this.request<UserAccount, void>({
            path: `/api/UserAccount/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags UserAccountEndpoints
       * @name DeleteUserAccountById
       * @request DELETE:/api/UserAccount/{id}
       */
      deleteUserAccountById: (id: string, params: RequestParams = {}) =>
         this.request<void, void>({
            path: `/api/UserAccount/${id}`,
            method: 'DELETE',
            ...params,
         }),

      /**
       * No description
       *
       * @tags UserAccountEndpoints
       * @name AddUserAccount
       * @request POST:/api/UserAccount
       */
      addUserAccount: (data: UserAccount, params: RequestParams = {}) =>
         this.request<UserAccount, void>({
            path: `/api/UserAccount`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags UserAccountEndpoints
       * @name UpdateUserAccount
       * @request PUT:/api/UserAccount
       */
      updateUserAccount: (data: UserAccount, params: RequestParams = {}) =>
         this.request<UserAccount, void>({
            path: `/api/UserAccount`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags UserAccountEndpoints
       * @name GetUserAccountsByOrganisationId
       * @request GET:/api/UserAccount/byorganisation/{organisationId}
       */
      getUserAccountsByOrganisationId: (organisationId: string, params: RequestParams = {}) =>
         this.request<UserAccount[], void>({
            path: `/api/UserAccount/byorganisation/${organisationId}`,
            method: 'GET',
            format: 'json',
            ...params,
         }),
   }
}
