import { ILanguage } from '../../interfaces/ILanguage'

export const en: ILanguage = {
   '365Robust': '365Robust',
   Of: 'of',

   // Authentication
   WelcomeTo: 'Welcome to',
   SignIn: 'Sign in',
   SignOut: 'Sign out',
   Dashboard: 'Dashboard',
   NotAuthorized: 'Not authorized',

   // Organisation
   OrganisationalData: 'Organisational data',
   OrganisationNumber: 'Organisation number',
   CompanyName: 'Company name',
   OrganisationSize: 'Organisation size',
   OrganisationType: 'Organisation type',
   SNI: 'SNI',
   All: 'All',
   OrganisationDetails: 'Organisation details',
   InvoiceDetails: 'Invoice details',
   Invocing: 'Invoicing',

   // User
   AccountInformation: 'Accout information',
   Account: 'Account',
   FirstName: 'First name',
   LastName: 'Last name',
   Email: 'E-mail',
   OrganizationalAffiliation: 'Organizational affiliation',
   JobTitle: 'Job title',
   UserType: 'User type',
   Roles: 'Roles',

   // Button
   Next: 'Next',
   Add: 'Add',
   Finish: 'Finish',
   Cancel: 'Cancel',
   Continue: 'Continue',
   Save: 'Save',
   ReadMore: 'Read more',
   Close: 'Close',
   Complete: 'Complete',
   Determine: 'Determine',
   Back: 'Back',
   ExportToExcel: 'Export to excel',

   // Roles
   ProcessOwner: 'Process owner',
   Owner: 'Owner',
   RiskOwner: 'Risk owner',
   AnalysisLeader: 'Analysis leader',

   // Billing
   BillingData: 'Billing data',
   InvoiceReference: 'Invoice reference',
   InvoiceAddress: 'Invoice address',
   PostalCode: 'Postal code',
   County: 'County',
   PhoneNumber: 'Phone number',

   // Branch
   Branch: 'Branch',
   BranchName: 'Name of branch',
   PropertyDesignation: 'Property designation',
   OrganisationForm: 'Organisation type',
   SNICodes: 'SNI codes',
   BranchDescription: 'Description of branch',
   BranchValuation: 'Branch valuation',
   DateForValuation: 'Date for valuation',
   BranchResponsible: 'Responsible of branch',
   VisitingAdress: 'Visiting address',
   KeyFigures: 'Key figures',

   // StartUpWizard
   CheckAndVerifyOrganisationData: 'Check and verify organisation details',
   CheckAndVerifyUserData: 'Kontrollera och verify user details',
   User: 'User',
   OrganisationAffiliation: 'Organisationaffiliation',

   // ProtectedAssets
   ProtectedAssets: 'Protected assets',
   ProtectedAsset: 'Protected asset',
   BranchAffiliation: 'Branch affiliation',
   DescriptionOfAsset: 'Description of asset',
   Description: 'Description',
   ProtectedAssetValuation: 'Asset valuation',
   AddedAssets: 'Added assets',
   NewDemandAssessment: 'New demand assessment',
   OngoingDemandAssessments: 'Ongoing demand assessments',
   ConcludedDemandAssessments: 'Concluded demand assessments',
   AnalysisName: 'Name of analysis',
   AccessAffectsFollowingProcesses: 'Access affects the following processes',
   MotivationAndStand: 'Motivation and stand',

   // ComplianceOfDemands
   ComplianceOfDemands: 'Compliance of demands',
   LeadershipPerspective: 'Leadership perspective',
   Subject: 'Subject',
   DataClassification: 'Data classification',
   StartOfAssessment: 'Start of assessment',
   EndOfAssessment: 'End of assessment',
   Comment: 'Comment',
   CreateAssessment: 'Create assessment',
   Process: 'Process',
   CreatingDemandAssessment: 'Creating demand assessment...',
   DemandAssessmentCreated: 'The demand assessment is created',
   ThisCanTakeAMoment: 'This can take a moment. Please wait and keep the device on.',
   ClickContinueToGoToAssessment: "Click 'Continue' to open your assessment",
   Information: 'Information',
   DemandAnalysis: 'Demand analysis',
   ActionPlan: 'Action plan',
   DemandAssessment: 'Demand assessment',
   BranchScope: 'Branch scope',
   CompleteDemandAssessment: 'Complete demand assessment',
   CompleteDemandAssessmentConfirmationMessage:
      'Are you sure you want to complete the demand assessment? The action cannot be undone when it has been completed.',
   AllAnswered: 'Answered',
   NoConcludedDemandAssessments: 'There are no concluded demand assessments',
   TimeToCreateYourFirstDemandAssessment: 'Time to create your first demand assessment',
   AllProcesses: 'All processes',
   'No.': 'No.',
   Question: 'Question',
   CompleteDemandAnalysis: 'Complete demand analysis',
   CompleteDemandAnalysisConfirmationText:
      'Are you sure you want to complete demand analysis for {{process}}? You will not be able to update the answers to the questions and action plans will be created',
   EditDemandAssessment: 'Edit demand assessment',
   ProposedAction: 'Proposed action',
   BackgroundInformationForTheQuestion: 'Background information for the question',
   BackgroundInformation: 'Background information',
   TipFrom365Robust: '365Robust tips',
   OriginDemandForTheQuestion: 'Origin demand for the question',
   OriginDemand: 'Origin demand',
   Source: 'Source',
   DemandAssessmentCompleted: 'Demand assessment succesfully completed',
   CouldNotCompleteDemandAssessment: 'Could not complete the demand assessment',
   CouldNotUpdateDemandAssessment: 'Could not update demand assessment',
   AssessmentName: 'Name of assessment',
   IncludeExtendedLevel: 'Include extended level',
   NoAvailableInformation: 'There is no information available at this time',
   EstimatedCost: 'Estimated cost for action',
   EstimatedCostShort: 'Estimated cost',
   ProcessOwnersForDemandAssessment: 'Process owners for demand assessment',
   ProcessOwnerForDemandAssessmentText:
      'Select an existing user or enter a custom name. Active users will be able to answer questions. Names without associated users will only be answerable by administrators.',
   ExtendedLevel: 'Extended level',
   NotIncluded: 'Not included',
   Included: 'Included',
   ProcessWithProcessowner: 'Process with processowner',

   // Report
   Report: 'Report',
   AnsweredQuestionReport: 'Answered questions - Report',
   AnsweredQuestions: 'Answered questions',
   TotalNumber: 'Total number',
   NumberOfAnswered: 'Number of answered',
   Chart: 'Chart',
   List: 'List',

   // Actionplans
   NoActionPlansCreated: 'The demand analysis must be determined per process before an action plan is created for the process',
   NoActionPlansRequiredForThisItem: 'No actions required for this {{Item}}.',
   DemandAssessmentCanBeCompleted: 'The demand assessment can be completed',
   RiskAssessmentCanBeCompleted: 'The risk assessment can be completed',
   ComplianceLevel: 'Level of compliance',
   DecisionOnAction: 'Decision on action',
   NoActionShallBeTaken: 'No actions will be taken',
   ResponsibleOfAction: 'Responsible',
   Priority: 'Priority',
   ID: 'ID',
   AnsweredBy: 'Answered by',
   StartAction: 'Start action',
   CompleteAction: 'Complete action',
   ActionDecidedBy: 'Action decided by',
   NoActionsTaken: 'No actions taken',
   ImplementedActions: 'Implemented actions',
   ComplianceLevelAfterCompletedAction: 'Compliance level after implementing action',
   SpecifyCompliance: 'Specify compliance',
   CalculatedOutcome: 'Calculated outcome',
   DecisionStatus: 'Status',
   DecidedMulti: 'Decided',
   TotalNumberOfActions: 'Total number of actions',
   Deadline: 'Deadline',
   PerProcessAndComplianceLevel: 'Per process & compliance level',
   PerComplianceLevel: 'Per compliance level',
   ActionPlanReport: 'Action plan - Report',
   NumberOfActions: 'Number of items',

   // Status
   RequiredField: 'This field is required',
   SomethingWentWrong: 'Something went wrong',
   NoMatchingQuestonsFound: 'No matching questions found',
   FetchFailed: 'Could not retrieve data',
   ModifiedBy: 'Modified by',
   Modified: 'Modified',
   CreatedBy: 'Created by',
   CreatedDate: 'Created',
   ItemSuccessfullySaved: '{{Item}} succesfully saved',
   ItemSuccessfullyUpdated: '{{Item}} succesfully updated',
   CouldNotFetchItem: '{{Item}} could not be fetched',
   CouldNotCreateItem: '{{Item}} could not be created',
   CouldNotUpdateItem: '{{Item}} could not be updated',
   Status: 'Status',
   NoDateSet: 'No date set',
   NoValueSet: 'No value set',
   ValidationFailed: 'Validering misslyckades',
   DemandAnalysisCompleted: 'Demand analysis completed',
   CouldNotCompleteDemandAnalysis: 'Something went wrong, could not complete demand analysis',
   NotAnswered: 'Not answered',
   Answered: 'Answered',
   Determined: 'Determined',
   NotDecided: 'Not decided',
   Decided: 'Decided',
   NotStarted: 'Not started',
   Started: 'Started',
   Completed: 'Completed',
   NotToBeAdressed: 'Not to be adressed',
   ErrorMessage: 'Error message',
   NoDataCreatedYet: 'No data created yet.',
   Ongoing: 'Ongoing',
   Concluded: 'Concluded',
   EndDateMustBeAfterStartDate: 'End date must be after start date.',
   AnAccountMustBeSelected: 'An account must be selected',

   // Riskmanagement:
   CreateAnalysis: 'Create analysis',
   RiskAnalysis: 'Risk analysis',
   RiskManagement: 'Risk management',
   NewRiskAnalysis: 'New risk analysis',
   TypeOfRiskAnalysis: 'Type of risk analysis',
   NameOfAnalysis: 'Name of the analysis',
   'Purpose/Scope': 'Purpose/Scope',
   Limitations: 'Limitations',
   StartOfAnalysis: 'Start of analysis',
   EndOfAnalysis: 'End of analysis',
   SimplifiedRiskAnalysis: 'Simplified risk analysis (FRA)',
   SimplifiedConsequenseProbabilityAnalysis: 'Simplified consequense and probablity analysis (F-KSA)',
   SWOTAnalysis: 'SWOT analysis',
   CompleteSWOTAnalysis: 'Complete SWOT-Analysis',
   Type: 'Type',
   NoConcludedRiskAnalyses: 'There are no completed risk analyses',
   TimeToCreateYourFirstRiskAnalysis: 'Time to create your first risk analysis',
   OngoingRiskAnalyses: 'Ongoing risk analyses',
   ConcludedRiskAnalyses: 'Completed risk analyses',
   CreatingRiskAnalysis: 'Creating risk analysis',
   RiskAnalysisCreated: 'The risk analysis has been created',
   RiskIdentifying: '*Risk identifying',
   'RiskAnalysis/assessment': 'Risk analysis/assessment',
   AnalyseEvaluate: 'Analyse & evaluate',
   Identify: 'Identify',
   RiskManagementPlan: 'Risk management plan',
   NewRisk: 'New risk',
   RiskScenario: 'Risk scenario',
   RiskSource: 'Risk source',
   IdentifyRisk: 'Identify risk',
   CurrentProtectionAndOrPotentialWeaknesses: 'Current protection and/or potential weakness',
   UnderlyingMotivationAndStand: 'Underlying motivation and stand',
   Consequense: 'Consequense',
   NoRisksIdentifiedYet: 'No risks identified yet',
   Risk: 'Risk',
   Title: 'Title',
   CompleteRiskAssessment: 'Complete risk assessment',
   CompleteRiskAssessmentConfirmationMessage: 'Are you sure you want to complete the risk assessment? The action cannot be undone when it has been completed.',
   RiskAssessmentCompleted: 'Risk assessment succesfully completed',
   CouldNotCompleteRiskAssessment: 'Could not complete the risk assessment',
   DetermineRiskAnalysisConfirmationText: 'Are you sure you want to determine the demand assessment? The action cannot be undone when it has been completed.',
   DetermineRiskAnalysis: 'Determine riskanalysis',

   // SWOT
   Strengths: 'Strengths',
   Weaknesses: 'Weaknesses',
   Opportunities: 'Opportunities',
   Threats: 'Threats',
   Strength: 'Strength',
   Weakness: 'Weakness',
   Opportunity: 'Opportunity',
   Threat: 'Threat',
   SWOT: 'SWOT',
   TimeToAddYourFirstSWOT: 'Time to add your first SWOT',
   Order: 'Order',
   ChangeOrder: 'Ändra ordning',
   ActionProposal: 'Action proposal',
   CreateProposedAction: 'Create action proposal',
   of: 'of',
   ValueInOrderOfImportance: 'Value in order of importance',
   NoSWOTMatchingSelectedFilter: 'No SWOTs matching selected filter',
   Graph: 'Graph',
   NoTypeIdentified: 'No {{type}} are identified',

   // Settings
   Settings: 'Settings',

   // Date picker strings
   January: 'January',
   February: 'February',
   March: 'March',
   April: 'April',
   May: 'May',
   June: 'June',
   July: 'July',
   August: 'August',
   September: 'September',
   October: 'October',
   November: 'November',
   December: 'December',

   Jan: 'Jan',
   Feb: 'Feb',
   Mar: 'Mar',
   Apr: 'Apr',
   MayShort: 'May',
   Jun: 'Jun',
   Jul: 'Jul',
   Aug: 'Aug',
   Sep: 'Sep',
   Oct: 'Oct',
   Nov: 'Nov',
   Dec: 'Dec',

   Monday: 'Monday',
   Tuesday: 'Tuesday',
   Wednesday: 'Wednesday',
   Thursday: 'Thursday',
   Friday: 'Friday',
   Saturday: 'Saturday',
   Sunday: 'Sunday',

   MondayShort: 'M',
   TuesdayShort: 'T',
   WednesdayShort: 'W',
   ThursdayShort: 'T',
   FridayShort: 'F',
   SaturdayShort: 'S',
   SundayShort: 'S',
   GoToToday: 'Go to today',

   // Data classification
   OpenInformation: 'Open information',
   InternalInformation: 'Internal information',
   SecretInformation: 'Secret information',
   QualifiedSecretInformation: 'Qualified secret information',

   // Switch organisation
   SwitchOrganisation: 'Switch organisation',
   SelectOrganisation: 'Select organisation',
   CouldNotSwitchOrganisation: 'Could not switch organisation',
}
