import { IDataContext } from '../interfaces/IDataContext'
import { IRootState } from '../interfaces/IRootState'

export const initialDataContext: IDataContext = {
   state: null,
   setRootState: null,
   handleMessage: null,
}
export const GetInitialRootState = (): IRootState => {
   const savedOrganisation = localStorage.getItem('selectedOrganisation');
   const savedUserAccount = localStorage.getItem('selectedUserAccount');

   return {
      navigationExpanded: false,
      isLoading: false,
      message: null,
      initialLoading: true,
      userAccount: savedUserAccount ? JSON.parse(savedUserAccount) : null,
      snis: [],
      organisation: savedOrganisation ? JSON.parse(savedOrganisation) : null,
      branch: null,
      protectedAssets: [],
      accessToken: null,
      userAccounts: [],
   }
}