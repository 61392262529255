import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { IDropdownOption } from '../../../interfaces/IDropdownOptions'
import { Dropdown, Option } from '@fluentui/react-components'
import { t } from 'i18next'
import { Field } from './Field'

type ControlledDropdownProps = {
   name: any
   control: Control<any>
   label: string
   options: IDropdownOption[]
   disabled?: boolean
   required?: boolean
}
export const ControlledDropDown = ({ name, control, label, options, disabled, required }: ControlledDropdownProps) => {
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { value, onChange }, fieldState: { error }, formState: { disabled: formDisabled } }) => (
            <Field label={label} required={required ?? false} validationMessage={error ? error.message : null} validationState={error ? 'error' : null}>
               <Dropdown
                  value={options.find((x) => x.key === (value ? Number(value) : value))?.text ?? ''}
                  onOptionSelect={(e, d) => {
                     onChange(Number(d.optionValue))
                  }}
                  selectedOptions={[(value || value === 0) && value.toString()]}
                  disabled={(disabled || formDisabled) ?? false}
                  style={{ minWidth: 'auto' }}
               >
                  {options.map((x) => (
                     <Option key={x.key} value={x.key.toString()} disabled={x.disabled ? x.disabled : false}>
                        {x.text}
                     </Option>
                  ))}
               </Dropdown>
            </Field>
         )}
      />
   )
}
