import React, { useState } from 'react'
import { t } from '../../../i18n/i18n'
import { DataClassification, DemandAnalysis } from '../../../api/schemas/schema'
import { Grid } from '../../common/grid/Grid'
import { GridItem } from '../../common/grid/GridItem'
import { Text } from '../../common/text/Text'
import { Title } from '../../common/text/Title'
import { Pen24Regular } from '@fluentui/react-icons'
import dayjs from 'dayjs'
import { Persona, makeStyles, tokens, Divider, mergeClasses, Button, Card } from '@fluentui/react-components'
import { enumValuesToTranslate } from '../../../helpers/enumHelper'
import { ModalEditInformation } from './ModalEditInformation'
import { IAnalysisState } from '../../../interfaces/IAnalysisState'
import { getFullName } from '../../../helpers/accountHelper'

type InformationProps = {
   analysis: DemandAnalysis
   setAnalysisState: React.Dispatch<React.SetStateAction<IAnalysisState>>
}
const useStyles = makeStyles({
   infoWrapper: {
      '& > p': {
         marginTop: '0.5em',
         marginBottom: '0.5em',
      },
      '& > :last-child': {
         marginBottom: '1.5em',
         '& > span:last-child': {
            fontSize: tokens.fontSizeBase400,
         },
      },
   },
   processOwnerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   processOwnerTitle: {
      justifySelf: 'center',
      '@media screen and (max-width: 1007px)': {
         justifySelf: 'start',
      },
   },
   processOwnerTextWrapper: {
      width: '48%',
      '& > p': {
         margin: tokens.spacingHorizontalSNudge,
      },
      '@media screen and (max-width: 1007px)': {
         width: 'auto',
      },
   },
   processRightAlign: {
      justifySelf: 'end',
   },
   transparentInPhone: {
      padding: `${(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXXL)}`,
      boxShadow: tokens.shadow8,
      backgroundColor: '#FFFDF5',
      '@media screen and (max-width: 1007px)': {
         boxShadow: 'none',
         padding: 0,
         backgroundColor: 'transparent',
      },
   },
   showOnlyLarge: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
   },
   editButton: {
      display: 'flex',
      alignItems: 'center',
   },
})

export const Information = ({ analysis, setAnalysisState }: InformationProps) => {
   const classes = useStyles()
   const [isModalOpen, setIsModalOpen] = useState(false)

   const handleEditButtonClick = () => {
      setIsModalOpen(true)
   }

   const closeModal = () => {
      setIsModalOpen(false)
   }

   return (
      <>
         <ModalEditInformation analysis={analysis} isOpen={isModalOpen} closeModal={closeModal} setAnalysisState={setAnalysisState} />

         <Card aria-label="group" className={classes.transparentInPhone}>
            <header className={mergeClasses(classes.showOnlyLarge, classes.header)}>
               <Title as={'h3'}>{t('Information')}</Title>
               <Button className={classes.editButton} appearance="transparent" onClick={handleEditButtonClick}>
                  <Pen24Regular />
                  <Text weight="semibold">Redigera</Text>
               </Button>
            </header>
            <Divider className={classes.showOnlyLarge} appearance="brand" />
            <Grid>
               <GridItem size="3/12">
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('StartOfAssessment')}</Text>
                     {analysis.analysisStart ? <Text>{dayjs(analysis.analysisStart).format('YYYY-MM-DD')}</Text> : <Text italic>{t('NoDateSet')}</Text>}
                  </section>
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('EndOfAssessment')}</Text>
                     {analysis.analysisEnd ? <Text>{dayjs(analysis.analysisEnd).format('YYYY-MM-DD')}</Text> : <Text italic>{t('NoDateSet')}</Text>}
                  </section>
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('Owner')}</Text>
                     <Persona textAlignment="center" name={getFullName(analysis.analysisOwner)} primaryText={getFullName(analysis.analysisOwner)} />
                  </section>
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('BranchScope')}</Text>
                     <Text>{analysis.branch.name}</Text>
                  </section>
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('DataClassification')}</Text>
                     {analysis.dataClassification !== undefined ? (
                        <Text>{enumValuesToTranslate(DataClassification, analysis.dataClassification)}</Text>
                     ) : (
                        <Text italic>{t('NoValueSet')}</Text>
                     )}
                  </section>
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('Comment')}</Text>
                     <Text>{analysis.comment}</Text>
                  </section>
               </GridItem>
               <GridItem size="3/12">
                  <section className={classes.infoWrapper}>
                     <Text weight="bold"> {t('ExtendedLevel')}</Text>
                     {analysis.additionalModules && analysis.additionalModules.length > 0 ? <Text>{t('Included')} </Text> : <Text>{t('NotIncluded')} </Text>}
                  </section>
               </GridItem>
               <GridItem size="3/12">
                  <section className={classes.infoWrapper}>
                     <Text className={classes.processOwnerTitle} weight="bold">
                        {t('ProcessWithProcessowner')}
                     </Text>
                     {analysis.processOwners.map((p, index) => (
                        <div key={index} className={classes.processOwnerWrapper}>
                           <div className={classes.processOwnerTextWrapper}>
                              <Text className={classes.processRightAlign}>{p.process.text}</Text>
                           </div>
                           <div className={classes.processOwnerTextWrapper}>
                              <Text>{p.userAccount ? getFullName(p.userAccount) : p.ownerFreeText}</Text>
                           </div>
                        </div>
                     ))}
                  </section>
               </GridItem>
            </Grid>
         </Card>
      </>
   )
}
