import { Button, Dialog, DialogBody, DialogContent, DialogTitle } from '@fluentui/react-components'
import React, { useContext } from 'react'
import { ModalActions, DialogSurface } from '../common/modal/Modal'
import { Text } from '../common/text/Text'
import { api } from '../../helpers/apiHelper'
import { DataContext } from '../../context/DataContext'
import { DemandAnalysisStatus } from '../../api/schemas/schema'
import { handleMessage } from '../../helpers/stateHelper'
import { useNavigate } from 'react-router-dom'
import { t } from '../../i18n/i18n'
import { IAnalysisState } from '../../interfaces/IAnalysisState'

type ModalCompleteDemandAssessmentProps = {
   setAnalysisState: React.Dispatch<React.SetStateAction<IAnalysisState>>
   analysisState: IAnalysisState
   close: () => void
   open: boolean
}
export const ModalCompleteDemandAssessment = ({ setAnalysisState, analysisState, close, open }: ModalCompleteDemandAssessmentProps) => {
   const dataContext = useContext(DataContext)
   const { accessToken, userAccount } = dataContext.state
   const navigate = useNavigate()
   const onComplete = async () => {
      try {
         setAnalysisState((prev) => ({ ...prev, loading: true }))
         const analysis = analysisState.item
         analysis.status = DemandAnalysisStatus.Completed
         analysis.modifiedBy = userAccount
         analysis.modifiedById = userAccount.id
         const updatedAnalysis = (await api(accessToken).updateDemandAnalysis(analysis)).data
         setAnalysisState((prev) => ({ ...prev, loading: false, item: updatedAnalysis }))
         handleMessage(dataContext.setRootState, 'success', t('DemandAssessmentCompleted'))
         close()
         navigate('/compliance?tab=concluded')
      } catch (error: any) {
         setAnalysisState((prev) => ({ ...prev, loading: false, error: error.error }))
         handleMessage(dataContext.setRootState, 'error', t('CouldNotCompleteDemandAssessment'), error.error.message)
         close()
      }
   }
   return (
      <Dialog open={open} modalType={'modal'}>
         <DialogSurface>
            <DialogBody>
               <DialogTitle style={{ textAlign: 'center' }} as="h3">
                  {t('CompleteDemandAssessment')}
               </DialogTitle>
               <DialogContent>
                  <Text align="center">{t('CompleteDemandAssessmentConfirmationMessage')}</Text>
                  <ModalActions>
                     <Button onClick={close}>{t('Cancel')}</Button>
                     <Button appearance="primary" onClick={onComplete}>
                        {t('CompleteDemandAssessment')}
                     </Button>
                  </ModalActions>
               </DialogContent>
            </DialogBody>
         </DialogSurface>
      </Dialog>
   )
}
