import React, { useContext, useEffect } from 'react'
import { Button, DialogActions, DialogContent, DialogTitle, Dropdown } from '@fluentui/react-components'
import { Branch } from '../../api/schemas/schema'
import { useForm } from 'react-hook-form'
import { DataContext } from '../../context/DataContext'
import { handleMessage } from '../../helpers/stateHelper'
import { useMsal } from '@azure/msal-react'
import { GridItem } from '../common/grid/GridItem'
import { Grid } from '../common/grid/Grid'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { ControlledDatePicker } from '../common/inputs/DatePicker'
import { api } from '../../helpers/apiHelper'
import { ControlledMoneyInput } from '../common/inputs/ControlledMoneyInput'
import { ControlledZipInput } from '../common/inputs/ControlledZipInput'
import { DialogActionButtons, DialogBody } from '../common/modal/Modal'
import { t } from '../../i18n/i18n'

type BranchStepProps = {
   nextStep: () => void
   prevStep?: () => void
   dialogTitleClass: string
   dialogContentClass: string
}
export const BranchStep = ({ prevStep, nextStep, dialogTitleClass, dialogContentClass }: BranchStepProps) => {
   const dataContext = useContext(DataContext)
   const { setRootState } = dataContext
   const { organisation, branch, accessToken } = dataContext.state
   const branchForm = useForm<Branch>()
   const { control, handleSubmit, reset, formState:{isSubmitting} } = branchForm
   const { accounts } = useMsal()

   useEffect(() => {
      if (branch) reset(branch)
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [branch])

   const onSubmit = async (data: Branch) => {
      try {
         data.modifiedBy = accounts[0].name
         
         let updatedBranch: Branch
         if (data.id) {
            updatedBranch = (await api(accessToken).updateBranch(organisation.id, data)).data
         } else {
            data.createdBy = accounts[0].name
            updatedBranch = (await api(accessToken).addBranch(organisation.id, data)).data
         }
         dataContext.setRootState((prev) => ({
            ...prev,
            branch: updatedBranch,
         }))
         nextStep()
      } catch (error: any) {
         handleMessage(setRootState, 'error', t('SomethingWentWrong'), error.message)
      }
   }

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <DialogBody>
            <DialogTitle action="" as="h3" className={dialogTitleClass}>
               {t('Branch')}
            </DialogTitle>
            <DialogContent className={dialogContentClass}>
               <Grid gap="L">
                  <GridItem size="12/12">
                     <ControlledTextInput required control={control} name={'description'} label={t('BranchDescription')} multiline />
                  </GridItem>
                  <GridItem size="7/12">
                     <ControlledTextInput required control={control} name={'responsible'} label={t('BranchResponsible')} />
                  </GridItem>
                  <GridItem size="7/12">
                     <ControlledMoneyInput control={control} name={'valuation'} label={t('BranchValuation')} />
                  </GridItem>
                  <GridItem size="5/12">
                     <ControlledDatePicker control={control} name={'valuationDate'} label={t('DateForValuation')} />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput required control={control} name={'address'} label={t('VisitingAdress')} />
                  </GridItem>
                  <GridItem size="5/12">
                     <ControlledZipInput required control={control} name={'postalCode'} label={t('PostalCode')} />
                  </GridItem>
                  <GridItem size="7/12">
                     <ControlledTextInput required control={control} name={'county'} label={t('County')} />
                  </GridItem>
               </Grid>
            </DialogContent>
            <DialogActionButtons primaryButtonText={t('Next')} secondaryButtonText={t('Back')} secondaryClick={prevStep} isSubmitting={isSubmitting} />
         </DialogBody>
      </form>
   )
}
