import React from 'react'
import { Lightbulb20Filled, Lightbulb20Regular } from '@fluentui/react-icons'
import { DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { t } from '../../../../i18n/i18n'
import { InformationPopover } from '../../../common/popover/InformationPopover'

type TipFrom365RobustPopoverProps = {
   item: DemandAnalysisQuestion
}
export const TipFrom365RobustPopover = ({ item }: TipFrom365RobustPopoverProps) => {
   return (
      <InformationPopover
         btnLabel={t('TipFrom365Robust')}
         popoverLabel={t('TipFrom365Robust')}
         text={item.proposedActionTip !== '' ? item.proposedActionTip : t('NoAvailableInformation')}
         filledIcon={<Lightbulb20Filled />}
         regularIcon={<Lightbulb20Regular />}
      />
   )
}
