import React, { useContext, useState } from 'react'
import { Modal, ModalActions } from '../../common/modal/Modal'
import { Reorder } from 'motion/react'
import { RiskAnalysis, SWOT } from '../../../api/schemas/schema'
import { Button, Divider, makeStyles, Text, tokens } from '@fluentui/react-components'
import { SwotTypeBadge } from './SwotTypeBadge'
import { ReOrderDotsVertical20Regular } from '@fluentui/react-icons'
import { t } from '../../../i18n/i18n'
import { changeOrderOfSwots, groupSWOTsByType } from '../../../helpers/swotHelper'
import { DataContext } from '../../../context/DataContext'
import { IRiskAnalysisState } from '../../../interfaces/IRiskAnalysisState'

const useStyles = makeStyles({
   groupContainer: {
      marginBottom: tokens.spacingVerticalXXL,
   },
   groupHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: tokens.spacingVerticalL,
   },
   reorderItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: tokens.spacingHorizontalM,
      border: `1px solid ${tokens.colorBrandStroke2}`,
      borderRadius: tokens.borderRadiusXLarge,
      marginBottom: tokens.spacingVerticalS,
      backgroundColor: tokens.colorBrandBackgroundInverted,
      cursor: 'grab',
   },
   noListStyle: {
      listStyle: 'none',
      padding: 0,
   },
   noPadding: {
      padding: 0,
   },
   divider: {
      marginBottom: tokens.spacingVerticalM,
   },
   textSpacer: {
      display: 'flex',
      gap: tokens.spacingHorizontalXL,
   },
})

type ChangeOrderModalProps = {
   open: boolean
   close: () => void
   analysis: RiskAnalysis
   items: SWOT[]
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IRiskAnalysisState>>
}

export const ChangeOrderModal = ({ open, close, analysis, items, setRiskAnalysisState }: ChangeOrderModalProps) => {
   const dataContext = useContext(DataContext)
   const classes = useStyles()

   const [groupedItems, setGroupedItems] = useState(() => groupSWOTsByType(items))

   const handleReorder = (groupKey: string, newOrder: SWOT[]) => {
      setGroupedItems((prev) => {
         const updatedGroup = newOrder.map((item, index) => ({
            ...item,
            order: index + 1,
         }))

         const updatedGroups = {
            ...prev,
            [groupKey]: updatedGroup,
         }
         return updatedGroups
      })
   }

   const handleSave = () => {
      const updatedItems = Object.values(groupedItems).flat()

      changeOrderOfSwots(dataContext, setRiskAnalysisState, analysis, updatedItems)
      close()
   }

   return (
      <Modal open={open} modalType="modal" title={t('ValueInOrderOfImportance')}>
         <>
            {Object.entries(groupedItems).map(([groupKey, groupItems]) => (
               <div key={groupKey} className={classes.groupContainer}>
                  <Divider className={classes.divider} />
                  <div className={classes.groupHeader}>
                     <SwotTypeBadge type={groupItems[0].type} />
                  </div>

                  <Reorder.Group axis="y" values={groupItems} onReorder={(newOrder) => handleReorder(groupKey, newOrder)} className={classes.noPadding}>
                     {groupItems.map((item) => (
                        <Reorder.Item key={item.id} value={item} className={classes.noListStyle}>
                           <div className={classes.reorderItem}>
                              <div className={classes.textSpacer}>
                                 <Text weight="semibold">{item.order}</Text>
                                 <Text>{item.title}</Text>
                              </div>
                              <ReOrderDotsVertical20Regular />
                           </div>
                        </Reorder.Item>
                     ))}
                  </Reorder.Group>
               </div>
            ))}
            <Divider className={classes.divider} />
            <ModalActions>
               <Button onClick={close}>{t('Cancel')}</Button>
               <Button onClick={handleSave} type="submit" appearance="primary">
                  {t('Save')}
               </Button>
            </ModalActions>
         </>
      </Modal>
   )
}
